import * as Routes from "../Routes";
import {Contact} from "../../models/Contact.model";
import {DataService} from "../data/data.service";
import {GetAllContactResponse} from "../../responses/contact/GetAllContactResponse";
import {GetContactResponse} from "../../responses/contact/GetContactResponse";
import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Response} from "../../responses/Response";

@Injectable({
    providedIn: 'root',
})
export class ConsentService {

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }


    public getOpenConsents(): Observable<Response> {
        return this.http.get<Response>(`${Routes.CONSENT_ROUTE}/open/`, this.dataService.getHttpOptions());
    }

    public updateConsent(id: number | string, consent1: boolean, consent2: boolean): Observable<any> {
        return this.http.put(`${Routes.CONSENT_ROUTE}/${id}/`, {consent1, consent2}, this.dataService.getHttpOptions());
    }
}