export class PostalFileData {
    public osmId: string;
    public ort: string;
    public plz: string;
    public bundesland: string;

    constructor(fileData: string) {
        const data = fileData.split(',');
        this.osmId = data[0];
        this.ort = data[1];
        this.plz = data[2];
        this.bundesland = data[3];
    }
}