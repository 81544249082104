import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {Router} from "@angular/router";
import {Shipment} from "../../models/Shipment.model";
import {ShipmentService} from "../../services/shipments/shipment.service";
import {ToastService} from "../../services/toast/toast.service";
import {TrackingService} from "../../services/tracking/tracking.service";

@Component({
    selector: 'ngx-critical-tracking-dialog',
    templateUrl: './critical-tracking-dialog.component.html',
    styleUrls: ['./critical-tracking-dialog.component.scss'],
})
export class CriticalTrackingDialog implements OnInit {
    @Input() shipments: Shipment[];
    @Input() type: string;

    public shippedCriticalType = TrackingService.TRACKING_TYPE_SHIPPED;
    public serviceCriticalType = TrackingService.TRACKING_TYPE_SERVICE;
    public shopCriticalType = TrackingService.TRACKING_TYPE_SHOP;
    public missingCriticalType = TrackingService.TRACKING_TYPE_MISSING;

    public shipmentTypes = ShipmentService.SHIPMENT_TYPES;

    public abortedList = {};
    private doReload = false;

    constructor(
        private router: Router,
        protected dialogRef: NbDialogRef<any>,
        private shipmentService: ShipmentService,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.abortedList = {};
        this.doReload = false;
    }


    public close(): void{
        this.dialogRef.close({reload: this.doReload});
    }

    public navToShipmenTableWithParcelnumber(event: Shipment): void{
        this.router.navigate(['/shipments'],{state: {parcelNumber: event.parcelNumber, state: event.shipmentState}});
        this.close();
    }

    public getTypeName(typeVal: string): string{
        for(let i = 0; i < this.shipmentTypes.length; i++){
            if(this.shipmentTypes[i].value == typeVal){
                return this.shipmentTypes[i].title;
            }
        }
        return '';
    }


    public manuallyAbortTracking(shipmentId, index): void {
        this.shipmentService.abortTrackinManually(shipmentId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.ERROR_ON_MANUALLY_ABRORTING_TRACKING, 10000, err);
            },
            next: () => {
                this.abortedList[index+''] = true;
                this.doReload = true;
                this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.SUCCESS_ON_MANUALLY_ABRORTING_TRACKING);
            },
        });
    }

    public navToShipmenTable(event): void{
        let data = [TrackingService.GLS_STATE_IN_SERVICE];
        switch (event){
        case this.serviceCriticalType:
            data = [TrackingService.GLS_STATE_IN_SERVICE];
            break;
        case this.shopCriticalType:
            data = [TrackingService.GLS_STATE_PARCEL_SHOP];
            break;
        case this.missingCriticalType:
            data = [TrackingService.GLS_STATE_MISSING_INFO];
            break;
        default:
            return;
        }
        this.router.navigate(['/shipments'],{state: {data: data}});
        this.close();
    }
}
