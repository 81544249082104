<nb-card>
    <nb-card-header>
        {{deactivate ? headlineDeactivate : headline}}
    </nb-card-header>
    <nb-card-body>
        {{deactivate ? bodyDeactivate : body}}
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="confirm()">Ok</button>
        <button nbButton status="danger" (click)="dismiss()" style="float: right">Abbrechen</button>
    </nb-card-footer>
</nb-card>