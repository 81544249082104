import {Component, EventEmitter, Input} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `
            <nb-select
                    placeholder="Paketkategorie"
                    [selected]="rowData.categoryId"
                    *ngIf="cats != undefined"
                    (selectedChange)="change($event)"
                    [ngClass]="{'category-color-default-national':rowData.name.includes('Basis') && rowData.name.includes('National'),
                        'category-color-express-national':rowData.name.includes('Express') && rowData.name.includes('National'),
                        'category-color-default-international':!(rowData.name.includes('Express') && rowData.name.includes('National'))
                        && !(rowData.name.includes('Basis') && rowData.name.includes('National'))}"
                    >
                <nb-option
                        *ngFor="let cat of cats"
                        value="{{cat.categoryId}}"
                >{{cat.categoryName}}</nb-option>
            </nb-select>
            <!--
        <span *ngIf="rowData.name.includes('Basis') && rowData.name.includes('National')"
        class="category-color-default-national">{{rowData.name}}</span>
        <span *ngIf="rowData.name.includes('Express') && rowData.name.includes('National')"
        class="category-color-express-national">{{rowData.name}}</span>
        <span *ngIf="!(rowData.name.includes('Express') && rowData.name.includes('National'))
        && !(rowData.name.includes('Basis') && rowData.name.includes('National'))"
        class="category-color-default-international">{{rowData.name}}</span>
        -->
  `,
})
export class CategorySelectColumnComponent implements ViewCell {
    @Input() value: string;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object

    public cats: any[];

    public change(event: any): void{
        this.rowData.categoryId = event;
        this.rowData.name = this.cats.find(x => x.categoryId == this.rowData.categoryId).categoryName;
    }

}