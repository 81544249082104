import * as Routes from "../Routes";
import {Address} from "../../models/Address";
import {DataService} from "../data/data.service";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Response} from "../../responses/Response";


@Injectable({
    providedIn: 'root',
})
export class EbayService{


    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }

    public getOAuthUrl(baseUrl: string): Observable<Response> {
        return this.http.get<Response>(`${Routes.PARCEL_EBAY_ROUTE}/OAuth/` + baseUrl, this.dataService.getHttpOptions());
    }
    public getOrders(selectedAddress: Address): Observable<Response> {
        return this.http.post<Response>(`${Routes.PARCEL_EBAY_ROUTE}/getOrders`, selectedAddress, this.dataService.getHttpOptions());
    }

    public registerCode(code: string): Observable<Response> {
        return this.http.post<Response>(`${Routes.PARCEL_EBAY_ROUTE}/registerCode`, code, this.dataService.getHttpOptions());
    }

    public verify(): Observable<Response> {
        return this.http.get<Response>(`${Routes.PARCEL_EBAY_ROUTE}/verify/`, this.dataService.getHttpOptions());
    }
}