export class Address {
    public firstName: string = '';
    public lastName: string = '';
    public street: string = '';
    public housenumber: string = '';
    public zip: string = '';
    public city: string = '';
    public country: string = "Deutschland";

    public toGetOrderAddress(): GetOrderAddress{
        let address = new GetOrderAddress();
        address.senderName = this.firstName;
        address.senderAdditionalName = this.lastName;
        address.senderStreet = this.street;
        address.senderHousenumber = this.housenumber;
        address.senderZip = this.zip;
        address.senderCity = this.city;
        address.senderCountry = this.country;
        return address;
    }

}

export class SenderAddress extends Address {
    public glsContactId: string = '';

    public toGetOrderAddress(): GetOrderAddress {
        let address =  super.toGetOrderAddress();
        address.senderContactId = this.glsContactId;
        return address;
    }
}

export class GetOrderAddress{
    public senderName: string = '';
    public senderAdditionalName: string = '';
    public senderStreet: string = '';
    public senderHousenumber: string = '';
    public senderZip: string = '';
    public senderCity: string = '';
    public senderCountry: string = '';
    public senderContactId: string = '';
}
