import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy} from '@angular/core';
import {DatePipe} from "@angular/common";
import { LayoutService } from '../../../@core/utils';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';


@Component({
    selector: 'ngx-multibar-chart',
    styleUrls: ['./multibar-chart.component.scss'],
    template: `
        <div class="multibarChart">
            <div class="header">
                <div class="legend" *ngIf="selectedPeriodItem != undefined">
                    <div class="legend-item" *ngFor="let item of selectedPeriodItem.data.label; let i = index">
                        <span class="indicator" style="background-color:{{selectedPeriodItem.data.colors[i]}}"></span>{{item}}
                    </div>
                </div>
                <nb-select class="period-select" [selected]="selectedPeriod" (selectedChange)="changePeriod($event)">
                    <nb-option *ngFor="let period of periods" [value]="period.period">
                        {{ period.period }}
                    </nb-option>
                </nb-select>
            </div>
            <div echarts [options]="options" class="echart" (chartInit)="onChartInit($event)"></div>
        </div>
  `,
})
export class ProfitChartComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() selectedPeriod: string = 'week';
    @Input() periods: {period: string, data: {chartLabel: string[], data: number[][], label: string[], colors: string[]}}[] = [];
    @Input() doUpdateEvent: EventEmitter<any> = new EventEmitter<any>();

    private monthMap = {
        Jan: 'Januar',
        Feb: 'Februar',
        Mar: 'März',
        Apr: 'April',
        Mai: 'Mai',
        Jun: 'Juni',
        Jul: 'Juli',
        Aug: 'August',
        Sep: 'September',
        Okt: 'Oktober',
        Nov: 'November',
        Dez: 'Dezember',
    };

    private alive = true;
    public selectedPeriodItem;

    echartsIntance: any;
    options: any = {};

    constructor(private theme: NbThemeService,
                private layoutService: LayoutService,
                private datePipe: DatePipe) {
        this.layoutService.onSafeChangeLayoutSize()
            .pipe(
                takeWhile(() => this.alive)
            )
            .subscribe(() => this.resizeChart());
        this.selectedPeriodItem = this.periods.find(x => x.period == this.selectedPeriod);
    }

    ngOnChanges(): void {
        this.selectedPeriodItem = this.periods.find(x => x.period == this.selectedPeriod);
        if (this.echartsIntance) {
            this.updateProfitChartOptions();
        }
    }

    ngAfterViewInit() {
        this.selectedPeriodItem = this.periods.find(x => x.period == this.selectedPeriod);
        this.theme.getJsTheme()
            .pipe(takeWhile(() => this.alive))
            .subscribe(config => {
                const eTheme: any = config.variables.profit;

                this.setOptions(eTheme);
            });
        this.doUpdateEvent.subscribe(() => {
            this.ngOnChanges();
        });
    }

    setOptions(eTheme) {
        this.options = {
            backgroundColor: eTheme.bg,
            tooltip: this.setupTooltips(),
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLine: {
                        lineStyle: {
                            color: eTheme.axisLineColor,
                        },
                    },
                    axisLabel: {
                        color: eTheme.axisTextColor,
                        fontSize: eTheme.axisFontSize,
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: eTheme.axisLineColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: eTheme.splitLineColor,
                        },
                    },
                    axisLabel: {
                        color: eTheme.axisTextColor,
                        fontSize: eTheme.axisFontSize,
                    },
                },
            ],
            series: [],
        };
        let selectedData = this.periods.find(x => x.period == this.selectedPeriod);
        this.options.xAxis[0].data = selectedData.data.chartLabel;
        for(let i = 0; i < selectedData.data.label.length; i++){
            this.options.series.push({
                name: selectedData.data.label[i],
                type: 'bar',
                barGap: 0,
                barWidth: (100/(selectedData.data.label.length + 2)) + '%',
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: selectedData.data.colors[i],
                        }, {
                            offset: 1,
                            color: selectedData.data.colors[i],
                        }]),
                    },
                },
                data: selectedData.data.data[i],
            });
        }
    }

    updateProfitChartOptions() {
        const options = this.options;
        let selectedData = this.periods.find(x => x.period == this.selectedPeriod);
        // const series = this.getNewSeries(options.series, selectedData.data.data);
        let series = [];

        for(let i = 0; i < selectedData.data.label.length; i++){
            series.push({
                name: selectedData.data.label[i],
                type: 'bar',
                barGap: 0,
                barWidth: (100/(selectedData.data.label.length + 2)) + '%',
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: selectedData.data.colors[i],
                        }, {
                            offset: 1,
                            color: selectedData.data.colors[i],
                        }]),
                    },
                },
                data: selectedData.data.data[i],
            });
        }

        this.echartsIntance.setOption({
            tooltip: this.setupTooltips(),
            series: series,
            xAxis: {
                data: selectedData.data.chartLabel,
            },
        });
    }

    getNewSeries(series, data: number[][]) {
        return series.map((line, index) => {
            return {
                ...line,
                data: data[index],
            };
        });
    }

    onChartInit(echarts) {
        this.echartsIntance = echarts;
    }

    resizeChart() {
        if (this.echartsIntance) {
            setTimeout(() => {
                this.echartsIntance.resize();
            }, 0);
        }
    }

    changePeriod(period: string): void {
        this.selectedPeriod = period;
        this.ngOnChanges();
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    public setupTooltips(): any{
        if(this.selectedPeriod == 'Woche'){
            return this.options.tooltip = {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    shadowStyle: {
                        color: 'rgba(0, 0, 0, 0.3)',
                    },
                },
                formatter: (params) => {
                    let now = new Date();
                    let dayOffset = 1;
                    switch(params[0].axisValueLabel){
                    case 'Montag':
                        dayOffset = 1;
                        break;
                    case 'Dienstag':
                        dayOffset = 2;
                        break;
                    case 'Mittwoch':
                        dayOffset = 3;
                        break;
                    case 'Donnerstag':
                        dayOffset = 4;
                        break;
                    case 'Freitag':
                        dayOffset = 5;
                        break;
                    case 'Samstag':
                        dayOffset = 6;
                        break;
                    default:
                        dayOffset = 1;
                        break;
                    }
                    let displayDay = new Date(now.setDate(now.getDate() - now.getDay() + dayOffset));
                    return  `
                                ${params[0].axisValueLabel} - ${this.datePipe.transform(displayDay, 'dd.MM.yyyy')}<br>
                                <span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 10px;
                                height: 10px; background-color: ${params[0].color.colorStops[0].color};"></span>
                                ${params[0].seriesName}: ${params[0].seriesName == 'Kosten' ? params[0].value.toFixed(2) : Math.round(params[0].value * 100) / 100}${params[0].seriesName == 'Kosten' ? '&euro;' : ''}
                            `;
                },
            };
        }else if(this.selectedPeriod == 'Monat'){
            return this.options.tooltip = {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    shadowStyle: {
                        color: 'rgba(0, 0, 0, 0.3)',
                    },
                },
                formatter: (params) => {
                    let now = new Date();
                    now.setDate(+params[0].axisValueLabel);
                    return  `
                                ${now.toLocaleDateString('de-DE', {weekday: 'long'})} - ${this.datePipe.transform(now, 'dd.MM.yyyy')}<br>
                                <span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 10px;
                                height: 10px; background-color: ${params[0].color.colorStops[0].color};"></span>
                                ${params[0].seriesName}: ${params[0].seriesName == 'Kosten' ? params[0].value.toFixed(2) : Math.round(params[0].value * 100) / 100}${params[0].seriesName == 'Kosten' ? '&euro;' : ''}
                            `;
                },
            };
        }else if(this.selectedPeriod == 'Jahr'){
            return this.options.tooltip = {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    shadowStyle: {
                        color: 'rgba(0, 0, 0, 0.3)',
                    },
                },
                formatter: (params) => {
                    let now = new Date();
                    return  `
                                ${this.monthMap[params[0].axisValueLabel]} ${now.getFullYear()}<br>
                                <span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 10px;
                                height: 10px; background-color: ${params[0].color.colorStops[0].color};"></span>
                                ${params[0].seriesName}: ${params[0].seriesName == 'Kosten' ? params[0].value.toFixed(2) : Math.round(params[0].value * 100) / 100}${params[0].seriesName == 'Kosten' ? '&euro;' : ''}
                            `;
                },
            };
        }
    }
}
