import {Component, Input, OnInit} from "@angular/core";
import {ViewCell} from "ng2-smart-table";

@Component({
    template: `
        <span *ngIf="rowData.shipmentType == 'MANUAL'" style="white-space: nowrap">Manuell</span>
        <span *ngIf="rowData.shipmentType == 'EBAY'" style="white-space: nowrap">Ebay</span>
        <span *ngIf="rowData.shipmentType == 'WOOCOMMERCE'" style="white-space: nowrap">Woocommerce</span>
        <span *ngIf="rowData.shipmentType == 'SHOPWARE'" style="white-space: nowrap">Shopware 6</span>
        <span *ngIf="rowData.shipmentType == 'SHOPWARE_V5'" style="white-space: nowrap">Shopware 5</span>
        <span *ngIf="rowData.shipmentType == 'SHOPIFY'" style="white-space: nowrap">Shopify</span>
        <span *ngIf="rowData.shipmentType != 'EBAY' &&
        rowData.shipmentType != 'MANUAL' &&
        rowData.shipmentType != 'SHOPWARE' &&
        rowData.shipmentType != 'SHOPWARE_V5' &&
        rowData.shipmentType != 'SHOPIFY' &&
        rowData.shipmentType != 'WOOCOMMERCE'"
        >{{ rowData.shipmentType }}</span>
        <br *ngIf="rowData.importAdditionalRef != undefined && rowData.importAdditionalRef.length > 0">
        <span *ngIf="rowData.importAdditionalRef != undefined && rowData.importAdditionalRef.length > 0"
              style="white-space: nowrap">{{ rowData.importAdditionalRef }}</span>
    `,
})
export class ShipmentTypeColumnComponent implements ViewCell, OnInit {
    @Input() value: string | number;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object

    // tslint:disable-next-line:no-empty
    ngOnInit(): void { }

}
