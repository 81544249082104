import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from "@nebular/theme";

@Component({
    selector: 'ngx-create-label-info',
    templateUrl: './create-label-info.component.html',
    styleUrls: ['./create-label-info.component.scss'],
})
export class CreateLabelInfoComponent implements OnInit {

    constructor(
        protected dialogRef: NbDialogRef<any>
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
    }

    public close(): void{

        this.dialogRef.close();
    }

}
