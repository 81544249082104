export class FileTemplate{
    public templateId: string = '';
    public templateName: string = '';
    public templateType: string = '';
    public fileNameRegex: string = '';
    public fileLineBreak: string = '';
    public dateFormat: string = '';
    public fileEncoding; string = '';
    public separatorCharacter: string = '';
    public fileHasHeadline: boolean = false;
    public oneFileExport: boolean = true;
    public receiverNameIndex: number = -1;
    public receiverLastNameIndex: number = -1;
    public receiverAdditionalNameIndex: number = -1;
    public receiverStreetIndex: number = -1;
    public receiverHouseNumberIndex: number = -1;
    public receiverAddressLine2Index: number = -1;
    public receiverCityIndex: number = -1;
    public receiverZipIndex: number = -1;
    public receiverCountryIndex: number = -1;
    public receiverEmailIndex: number = -1;
    public receiverPhoneIndex: number = -1;
    public receiverAdditionalContactLine1Index: number = -1;
    public receiverAdditionalContactLine2Index: number = -1;
    public packageWeightIndex: number = -1;
    public packageLengthIndex: number = -1;
    public packageWidthIndex: number = -1;
    public packageHeightIndex: number = -1;
    public additionalKeysMap: Map<string, number> = new Map<string, number>();
    public additionalKeys: any;
}