import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {Component, Input, OnInit} from '@angular/core';
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-drag-n-drop',
    templateUrl: './drag-n-drop.component.html',
    styleUrls: ['./drag-n-drop.component.scss'],
})
export class DragNDropComponent implements OnInit {

    @Input() selected: {label: string, value: string, required: boolean}[];
    @Input() available: {label: string, value: string, required: boolean}[];
    @Input() available2: {label: string, value: string, required: boolean}[];
    @Input() firstVertical: boolean = true;
    @Input() secondVertical: boolean = true;
    @Input() addInfiniteEmpty: boolean = true;

    public INFINITE_EMPTY_VALUE = {label:'Leerspalte', value: 'empty', required: false};
    public addValue: string = '';
    public topBottomMode: boolean = false;


    constructor(
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        if(this.addInfiniteEmpty){
            this.available2.push(this.INFINITE_EMPTY_VALUE);
        }
    }

    drop(event: CdkDragDrop<{label: string, value: string, required: boolean}[]>) {
        if (event.previousContainer === event.container) {
            if(event.isPointerOverContainer){
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }else{
                if((event.container.connectedTo as any[]).length > 1){
                    if(this.selected[event.previousIndex].value != this.INFINITE_EMPTY_VALUE.value){
                        if(this.available.length <= this.available2.length){
                            this.available.push(this.selected[event.previousIndex]);
                        }else{
                            this.available2.push(this.selected[event.previousIndex]);
                        }
                    }
                    this.selected.splice(event.previousIndex,1);
                }
            }
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        if(this.addInfiniteEmpty){
            if(this.available2.indexOf(this.available2.find(x => x.value == this.INFINITE_EMPTY_VALUE.value)) < 0){
                this.available2.push(this.INFINITE_EMPTY_VALUE);
            }
        }
    }

    public addItem(): void{
        if(this.addValue == undefined || this.addValue == ''){
            return;
        }
        if(this.available.find(x => x.label == this.addValue) == undefined && this.selected.find(x => x.label == this.addValue) == undefined){
            const TOADD = {label: this.addValue, value: 'custom', required: false};
            this.available.unshift(TOADD);
            this.addValue = '';
        }else{
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning,this.toastService.DRAG_NEW_ENTRY_DUPLICATE,5000);
        }
    }

}
