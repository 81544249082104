import {Component, Input, OnInit} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'ngx-multi-confirm',
    templateUrl: './multiConfirm.component.html',
    styleUrls: ['./multiConfirm.component.scss'],
})
export class MultiConfirmComponent implements OnInit {

    @Input() public number: any;
    @Input() public actionDesc: any;

    constructor(
        protected dialogRef: NbDialogRef<any>
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void { }

    public confirm(): void {
        this.dialogRef.close({delete: true});
    }

    public dismiss(): void {
        this.dialogRef.close();
    }

}
