import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostalFileData } from 'app/models/PostalFileData';

@Injectable({
    providedIn: 'root',
})
export class PostalService {

    public countryKeys = [
        'DE',
    ];
    private fileURLs = [
        'assets/zuordnung_plz_ort.csv',
    ];
    private fileData: Map<string, PostalFileData[]>[];

    constructor(
        private http: HttpClient
    ) { }

    public getPostalFileData(): void {
        this.fileData = [];
        for (let i = 0; i < this.countryKeys.length; i++) {
            this.http.get(`${this.fileURLs[i]}`, {responseType: 'text'}).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                },
                next: (data: string) => {
                    if (this.fileData != undefined && this.fileData.length >= this.countryKeys.length) return;
                    let fileData = new Map();
                    const temp = data.split('\n');
                    for (let j = 1; j < temp.length; j++) {
                        let postalData = new PostalFileData(temp[j]);
                        if (postalData.plz != undefined) {
                            if (fileData.get(postalData.plz) != undefined) {
                                fileData.get(postalData.plz).push(postalData);
                            } else {
                                fileData.set(postalData.plz, [postalData]);
                            }
                        }
                    }
                    this.fileData.push(fileData);
                },
            });
        }
    }

    public getFileData(): any[] {
        return this.fileData;
    }

}