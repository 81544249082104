import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DefaultEditor, ViewCell } from 'ng2-smart-table';

@Component({
    template: `
        <span *ngIf="rowData.receiverNameLine2 != undefined && rowData.receiverNameLine2.length > 0">{{ rowData.receiverNameLine2 }}</span>
        <br *ngIf="rowData.receiverNameLine2 != undefined && rowData.receiverNameLine2.length > 0">
        <span style="white-space: nowrap">{{ rowData.receiverNameLine1 }}</span>
        <br>
        <span style="white-space: nowrap">{{ rowData.receiverAddressLine1 }}</span>
        <br>
        <span *ngIf="rowData.receiverAddressLine2 != undefined && rowData.receiverAddressLine2.length > 0"
              style="white-space: nowrap">{{ rowData.receiverAddressLine2 }}</span>
        <br *ngIf="rowData.receiverAddressLine2 != undefined && rowData.receiverAddressLine2.length > 0">
        <span style="white-space: nowrap">{{ rowData.receiverCountry }}-{{ rowData.receiverZip }} {{ rowData.receiverCity }}</span>
        <br>
        <i class="fas fa-exclamation-triangle validationIndicator"></i>
    `,
})
export class AddressTableColumnComponent implements ViewCell, OnInit {
    @Input() value: string | number;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object

    // tslint:disable-next-line:no-empty
    ngOnInit(): void { }

}

@Component({
    // selector: 'custom-address-filter',
    template: `
        <input
                #customAddressTableInputName1
                type="text"
                class="form-control"
                placeholder="Name"
                maxlength="40"
                (keyup)="updateValue()"
        >
        <input
                #customAddressTableInputName2
                type="text"
                class="form-control"
                placeholder="Firma"
                (keyup)="updateValue()"
        >
        <input
                #customAddressTableInputAddress
                type="text"
                class="form-control"
                placeholder="Adresse"
                (keyup)="updateValue()"
        >
        <input
                #customAddressTableInputZip
                type="text"
                class="form-control"
                placeholder="PLZ"
                (keyup)="updateValue()"
        >
        <input
                #customAddressTableInputCity
                type="text"
                class="form-control"
                placeholder="Stadt"
                (keyup)="updateValue()"
        >
    `,
})
export class AddressTableColumnEditorComponent extends DefaultEditor implements AfterViewInit {

    @ViewChild('customAddressTableInputName1')
    public nameRef: ElementRef;
    @ViewChild('customAddressTableInputName2')
    public firmaRef: ElementRef;
    @ViewChild('customAddressTableInputAddress')
    public addressRef: ElementRef;
    @ViewChild('customAddressTableInputZip')
    public zipRef: ElementRef;
    @ViewChild('customAddressTableInputCity')
    public cityRef: ElementRef;

    constructor() {
        super();
    }

    public ngAfterViewInit(): void {
        const data = this.cell.newValue.split('&&&');
        this.nameRef.nativeElement.value = data[0];
        if (data[1] === 'null' || data[1] === 'undefined') {
            this.firmaRef.nativeElement.value = '';
        } else {
            this.firmaRef.nativeElement.value = data[1];
        }
        this.addressRef.nativeElement.value = data[2];
        this.zipRef.nativeElement.value = data[3];
        this.cityRef.nativeElement.value = data[4];
    }

    public updateValue(): void {
        const name = this.nameRef.nativeElement.value;
        const firma = this.firmaRef.nativeElement.value;
        const address = this.addressRef.nativeElement.value;
        const zip = this.zipRef.nativeElement.value;
        const city = this.cityRef.nativeElement.value;
        this.cell.newValue = `${name}&&&${firma}&&&${address}&&&${zip}&&&${city}`;
    }

}
