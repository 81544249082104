import { Injectable } from '@angular/core';
import {NbDialogRef} from "@nebular/theme/components/dialog/dialog-ref";
import {NbDialogService} from "@nebular/theme";
import {SpinnerComponent} from "../../components/spinner/spinner.component";

@Injectable({
    providedIn: 'root',
})
export class SpinnerService {

    private modal: NbDialogRef<any>;

    constructor(
        private modalController: NbDialogService
    ) { }

    public open(): void{
        this.modal = this.modalController.open(SpinnerComponent, {closeOnBackdropClick  : false});
    }

    public close(): void{
        this.modal.close();
    }

}
