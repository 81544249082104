<div class="infoModalContent" style="border-color: white;">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline text-center">Hier k&ouml;nnen Sie festlegen, von wo Sie Wetterinformationen erhalten m&ouml;chten.</h2>
            <p class="text-center">(Bei Änderungen kann es bis zu 15 Minuten dauern)</p>
        </div>
        <div class="modalBody">
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="city">Stadt</label>
                <input id="city" nbInput fullWidth type="text" placeholder="Stadt" [(ngModel)]="weatherData.city">
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="plz">PLZ</label>
                <input id="plz" nbInput fullWidth type="text" placeholder="PLZ" [(ngModel)]="weatherData.plz">
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="country">Land</label>
                <input id="country" nbInput fullWidth type="text" placeholder="Land" [(ngModel)]="weatherData.country">
            </div>
            <div class="justify-content-center d-flex">
                <button nbButton (click)="save()">Speichern</button>
            </div>
        </div>
    </div>
</div>
