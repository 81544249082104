import {Address, SenderAddress} from "../../models/Address";
import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {

    @Input()
    public address: Address | SenderAddress;
    public isSenderAddressFlag: boolean;
    @Input()
    public index: number;
    public disableEdit: boolean = false;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
    }

    public save(): void{
        if(this.isValid()){
            this.dialogRef.close({
                save: true,
                address: this.address,
                index: this.index,
            });
        }else{
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD_AND_NAME_OR_COMPANY, 5000);
        }
    }

    public cancel(): void{
        this.dialogRef.close({
            save: false,
        });
    }

    public isSenderAddress(): boolean {
        return this.isSenderAddressFlag;
    }

    private isValid(): boolean{

        let isSenderAddressAndHasIdSet: boolean = true;

        if(this.isSenderAddressFlag){
            const addr: SenderAddress = this.address as SenderAddress;
            isSenderAddressAndHasIdSet = addr.glsContactId != undefined && addr.glsContactId.length > 0;
        }

        return isSenderAddressAndHasIdSet && this.address != undefined &&
            this.address.country != undefined && this.address.country.length > 0 &&
            this.address.city != undefined && this.address.city.length > 0 &&
            this.address.zip != undefined && this.address.zip.length > 0 &&
            this.address.housenumber != undefined && this.address.housenumber.length > 0 &&
            this.address.street != undefined && this.address.street.length > 0 &&
            (
                this.address.firstName != undefined && this.address.firstName.length > 0 ||
                this.address.lastName != undefined && this.address.lastName.length > 0
            );

    }

}
