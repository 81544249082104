<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">Button "Abschluss erstellen"</h2>
        </div>
        <div class="modalBody">
            <p>1. &Uuml;ber Mehrfachauswahl k&ouml;nnen Sie mehrere Versandlabels auf einmal downloaden.</p>
            <p>2. Die Funktion „Abschluss erstellen“ k&ouml;nnen Sie sowohl &uuml;ber die Mehrfachauswahl, als auch &uuml;ber den Button „Abschluss erstellen“ links oben anstoßen. Hierzu m&uuml;ssen Auftr&auml;ge in der Liste links ausgew&auml;hlt sein.</p>

            <p><b>Was bewirkt die Funktion oder der Button „ Abschluss erstellen“?</b></p>
            <ul>
                <li>
                    Sie erhalten einen QR Code, der alle Ihre ausgew&auml;hlten Pakete beinhaltet. Der Paketdienstleister kann somit schnell und sicher alle Pakete scannen.
                </li>
                <li>
                    Alle markierten Auftr&auml;ge landen nach dem „Abschluss erstellen“ automatisch in ihrem Versandarchiv. Hier k&ouml;nnen Sie jederzeit erstellte Labels mit verschiedenen Filtern durchsuchen.
                </li>
            </ul>
        </div>
    </div>
</div>
