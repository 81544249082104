<div class="">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-sm-12">
                <h5 *ngIf="!edit">Benutzer hinzuf&uuml;gen</h5>
                <h5 *ngIf="edit">Benutzer bearbeiten</h5>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form>
            <div class="row">
                <div class="col-12 mandatoryCol">
                    <span class="mandatory">*</span> Pflichtfeld
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-2 col-xl-1 mb-lg-3 label" for="salutation">Anrede</label>
                <div class="col-lg-4 col-xl-1 mb-lg-3">
                    <nb-select #salutation id="salutation" fullWidth [(selected)]="user.salutation" placeholder="Anrede">
                        <nb-option value="Herr">Herr</nb-option>
                        <nb-option value="Frau">Frau</nb-option>
                        <nb-option value="null">-</nb-option>
                    </nb-select>
                </div>
                <div class="col-lg-6 hidden-xl"></div>
                <label class="col-lg-2 col-xl-1 label" for="firstName">Vorname</label>
                <div class="col-lg-4 col-xl-3">
                    <input #firstName id="firstName" name="firstName" placeholder="Vorname" [(ngModel)]="user.firstName" nbInput fullWidth>
                </div>
                <label class="col-lg-2 col-xl-2 label" for="lastName">Nachname</label>
                <div class="col-lg-4 col-xl-4">
                    <input #lastName id="lastName" name="lastName" placeholder="Nachname" [(ngModel)]="user.lastName" nbInput fullWidth>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 label" for="username">Benutzername <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input #username id="username" name="userName" placeholder="Benutzername" [(ngModel)]="user.userName" nbInput fullWidth>
                </div>
                <label class="col-sm-2 label" for="email">E-Mail <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input #email id="email" name="email" placeholder="E-Mail" [(ngModel)]="user.email" nbInput fullWidth>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 label" for="phone">Telefon</label>
                <div class="col-sm-4">
                    <input #phone id="phone" name="phone" placeholder="Telefon" [(ngModel)]="user.phone" nbInput fullWidth>
                </div>
                <label *ngIf="userIsAdmin" class="col-sm-2 label" for="isAdmin">Administrator</label>
                <div *ngIf="userIsAdmin" class="col-sm-4">
                    <nb-checkbox #isAdmin id="isAdmin" name="isAdmin" status="basic" [(ngModel)]="user.admin" ></nb-checkbox>
                </div>
            </div>
            <div class="form-group row" *ngIf="edit && (userIsAdmin || userIsSelf)">
                <label class="col-sm-2 label" for="pw1">Passwort &auml;ndern</label>
                <div class="col-sm-4">
                    <input #pw1 id="pw1" name="pw1" placeholder="Passwort &auml;ndern" [(ngModel)]="newPassword" nbInput fullWidth type="password">
                </div>
                <label class="col-sm-2 label" for="pw2">Passwort best&auml;tigen</label>
                <div class="col-sm-4">
                    <input #pw2 id="pw2" name="pw2" placeholder="Passwort best&auml;tigen" [(ngModel)]="newPasswordConfirm" nbInput fullWidth type="password">
                </div>
            </div>
            <div class="form-group row" *ngIf="edit && userIsAdmin">
                <label class="col-sm-2 label" for="isActive">Aktiv?</label>
                <div class="col-sm-4">
                    <nb-checkbox #isActive id="isActive" name="isActive" status="basic" [(ngModel)]="user.active" ></nb-checkbox>
                </div>
                <div class="col-6"></div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row btnRow">
            <div class="col-sm-12">
                <button nbButton fullWidth (click)="submit()">Speichern</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>