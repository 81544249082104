<div class="content">
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <h3>Unsere E-Shop Plugins</h3>
            <p class="text-center">Laden Sie hier unser passendes Plugin f&uuml;r Ihren Shop herunter. (Nicht jeder Shop-Typ benötigt ein Plugin. z.b. Ebay)</p>
        </div>
    </div>
    <div class="row">
        <div class="col-4 mb-2">
            <button nbButton (click)="download('woocommerce')">Woocommerce</button>
        </div>
    </div>
</div>
