import {Component, Input, OnInit} from '@angular/core';
import {ConsentService} from "../../../services/consent/consent.service";
import {NbDialogRef} from "@nebular/theme";
import {ToastService} from "../../../services/toast/toast.service";

@Component({
    selector: 'ngx-agb',
    templateUrl: './agb.component.html',
    styleUrls: ['./agb.component.scss'],
})
export class AgbComponent implements OnInit {

    @Input() public consent: any;

    public agbOk: boolean = false;
    public securityOk: boolean = false;

    constructor(
        private toastController: ToastService,
        private modalController: NbDialogRef<any>,
        private consentService: ConsentService
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
    }

    public submit(): void{
        if(this.agbOk && this.securityOk){
            if(this.consent.consentId == undefined){
                this.toastController.showToast(this.toastController.TOAST_STATUS.danger, this.toastController.FIRST_LOGIN_ACCEPT_ALL_FAILED,15000, '');
            }else{
                this.consentService.updateConsent(this.consent.consentId, this.agbOk, this.securityOk).subscribe({
                    error: err => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastController.showToast(this.toastController.TOAST_STATUS.danger, this.toastController.FIRST_LOGIN_ACCEPT_ALL_FAILED,15000, err);
                    },
                    next: value => {
                        // tslint:disable-next-line:ter-max-len
                        if(value.body != undefined && value.body.consent1 != undefined && value.body.consent1 && value.body.consent2 != undefined && value.body.consent2){
                            this.toastController.showToast(this.toastController.TOAST_STATUS.success, this.toastController.SAVE_SUCCESS);
                            this.modalController.close();
                        }
                    },
                });
            }
        }else{
            this.toastController.showToast(this.toastController.TOAST_STATUS.warning, this.toastController.FIRST_LOGIN_ACCEPT_ALL,15000);
        }
    }

}
