import {Address, SenderAddress} from "../../models/Address";
import {Component, OnInit, ViewChild} from '@angular/core';
import {MarketplaceDefinitionService, MarketplaceType} from "../../services/marketplace/marketplace-definition.service";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {AmazonImportComponent} from "../amazon-import/amazon-import.component";
import {EbayService} from "../../services/ebay/ebay.service";
import {FileSystemFileEntry} from "ngx-file-drop";
import {FileTemplate} from "../../models/FileTemplate";
import {FileTemplateService} from "../../services/fileTemplate/fileTemplate.service";
import {GenericResponse} from "../../responses/GenericResponse";
import {GetSettingResponse} from "../../responses/settings/GetSettingResponse";
import {HttpErrorResponse} from "@angular/common/http";
import {MarketplaceDefinition} from "../../models/MarketplaceDefinition";
import {Setting} from "../../models/Settings.model";
import {SettingsService} from "../../services/settings/settings.service";
import {ShopDefinition} from "../../models/ShopDefinition.model";
import {ShopType} from "../../models/ShopType.model";
import {ShopsService} from "../../services/shops/shops.service";
import {SpinnerService} from "../../services/spinner/spinner.service";
import {TimeoutError} from "rxjs";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-import-dialog',
    templateUrl: './import-dialog.component.html',
    styleUrls: ['./import-dialog.component.scss'],
})
export class ImportDialogComponent implements OnInit {

    public senderAddresses: SenderAddress[] = [];
    public selectedAddress: SenderAddress;
    public addressSetupDone = false;
    public selectedAddressIndex = 0;
    public selectedTab: number = 1;
    public templates: FileTemplate[];
    public selectedTemplate: FileTemplate;
    public selectedFiles: File[];
    public eShops: ShopDefinition[];
    public eShopTypes: ShopType[];
    public marketPlaces: MarketplaceDefinition[];
    public marketPlaceTypes: MarketplaceType[];
    public dragLabel = "Dateien hier her ziehen";
    public btnActive: boolean = false;
    private defaultSetting: Setting;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private settingsService: SettingsService,
        private toastService: ToastService,
        private ebayService: EbayService,
        private templateService: FileTemplateService,
        private shopService: ShopsService,
        private modalController: NbDialogService,
        private marketplaceService: MarketplaceDefinitionService,
        private spinnerService: SpinnerService
    ) { }

    async ngOnInit(): Promise<void>{
        this.senderAddresses = [];
        this.selectedFiles = [];
        this.templates = [];
        this.eShopTypes = [];
        this.eShops = [];
        this.selectedAddressIndex = 0;
        this.selectedAddress = undefined;
        this.addressSetupDone = false;
        await this.getSetting();
        this.loadEbaySetting();
        this.loadAddresses();
        this.loadTemplates();
        this.loadShopAPIs();
        this.loadMarketsAPIs();
    }

    private async createSetting(): Promise<void>{
        let setting: Setting = new Setting();
        setting.key = this.settingsService.KEY_DEFAULT_TEMPLATE_ID;
        await this.settingsService.createSetting(setting).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.SETTINGS_CREATE_FAILED, 5000, err);
            },
            next: async () => {
                await this.getSetting();
            },
        });
    }

    private async getSetting(): Promise<void>{
        await this.settingsService.getSetting(this.settingsService.KEY_DEFAULT_TEMPLATE_ID).toPromise()
            .catch(async err => {
                if(err.error != undefined && err.error.errors != undefined && err.error.errors.length > 0 && err.error.errors[0].includes('0023')){
                    await this.createSetting();
                }
            })
            .then((res: GetSettingResponse) => {
                this.defaultSetting = res.body;
            });
    }

    private loadEbaySetting(): void {
        this.btnActive = false;
        this.settingsService.getSetting(this.settingsService.EBAY_TOKEN_SETTINGS_KEY).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                /*console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);*/
            },
            next: value => {
                if (value.body != undefined && value.body.value != undefined && value.body.value != '') {
                    this.btnActive = true;
                }
            },
        });
    }

    private loadAddresses(): void{

        this.settingsService.getSetting(this.settingsService.KEY_SENDER_ADDITIONAL_ADDRESSES).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
            },
            next: value => {
                this.senderAddresses = JSON.parse(value.body.value) as SenderAddress[];
            },
            complete: () => {

                this.settingsService.getSetting(this.settingsService.KEY_SENDER_DATA).subscribe({
                    error: (err: HttpErrorResponse) => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                    },
                    next: response => {
                        let senderData = JSON.parse(response.body.value);
                        let newAddress = new SenderAddress();
                        newAddress.city = senderData.pickupAddress.city;
                        newAddress.country = senderData.pickupAddress.country;
                        newAddress.housenumber = senderData.pickupAddress.housenumber;
                        newAddress.firstName = senderData.pickupAddress.firstName;
                        newAddress.lastName = senderData.pickupAddress.lastName;
                        newAddress.street = senderData.pickupAddress.street;
                        newAddress.zip = senderData.pickupAddress.zip;
                        newAddress.glsContactId = senderData.GLSContactId;
                        this.senderAddresses.unshift(newAddress);
                        this.selectedAddress = this.senderAddresses[0];
                        this.addressSetupDone = true;
                    },
                });
            },
        });
    }

    public doAmazonImport(): void{
        const modal = this.modalController.open(AmazonImportComponent,
            {
                autoFocus: false,
                context: {
                    address: this.senderAddresses[this.selectedAddressIndex],
                },
            }
        );
        modal.onClose.subscribe({
            next: res => {
                if (res !== undefined && res.type != undefined) {
                    this.dialogRef.close({ type: res.type});
                }
            },
        });
    }

    public doEbayImport(): void{
        this.spinnerService.open();
        this.ebayService.getOrders(this.mapToAddressForEbayRequest(this.senderAddresses[this.selectedAddressIndex])).subscribe({
            // tslint:disable-next-line:no-console
            error: err => console.log(err),
            next: value => {
                // console.log(value);
                this.spinnerService.close();
                this.dialogRef.close({count: (JSON.parse(value.body)).addedOrdersCount, type: 'type--ebay'});
            },
        });
    }

    public doImport(shop: ShopDefinition): void{
        this.spinnerService.open();
        this.shopService.getOrders(
            this.mapToAddressForEbayRequest(
                this.senderAddresses[this.selectedAddressIndex]
            ),
            shop.shopDefinitionId,
            shop.shopType.split('.')[shop.shopType.split('.').length - 1]
        ).subscribe({
            error: err => {
                if(err instanceof TimeoutError){
                    let toastMSG = {
                        title: 'Achtung! Laufzeitüberschreitung',
                        // tslint:disable-next-line:ter-max-len
                        body: `Die Anfrage an den eShop dauert länger als geplant. Damit Sie das System weiter nutzen können, führen wir den Import nun im Hintergrund weiter. Die Bestellungen sollten in wenigen Minuten erfolgreich importiert sein. Hierzu dann einfach die Seite neu aufrufen.`,
                    };
                    this.toastService.showToast(this.toastService.TOAST_STATUS.warning, toastMSG, 20000);
                }else{
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    let toastMSG = {
                        title: 'Es ist ein unerwarteter Fehler aufgetreten!',
                        body: `Es konnten keine Lieferungen bei ${shop.shopName} abgefragt werden!`,
                    };
                    if(err.error != undefined && err.error.errors != undefined && err.error.errors[0]){
                        toastMSG.body += '\n' + err.error.errors[0];
                    }else if(err.error != undefined && err.error.error != undefined && err.error.error.length > 0){
                        toastMSG.body += '\n' + err.error.error;
                    }
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, toastMSG, 10000);
                }
                this.spinnerService.close();
                this.dialogRef.close({count: -1, type: undefined});
            },
            next: value => {
                // console.log(value);
                this.spinnerService.close();
                this.dialogRef.close({count: value.body, type: shop.shopName});
            },
        });
    }

    public doImportMarketplace(marketplace: MarketplaceDefinition): void {
        this.spinnerService.open();
        this.marketplaceService.getOrders(
            this.mapToAddressForEbayRequest(
                this.senderAddresses[this.selectedAddressIndex]
            ),
            marketplace.marketplaceDefinitionId,
            marketplace.marketplaceType.split('.')[marketplace.marketplaceType.split('.').length - 1]
        ).subscribe({
            error: err => {
                if(err instanceof TimeoutError){
                    let toastMSG = {
                        title: 'Achtung! Laufzeitüberschreitung',
                        // tslint:disable-next-line:ter-max-len
                        body: `Die Anfrage an den Marktplatz dauert länger als geplant. Damit Sie das System weiter nutzen können, führen wir den Import nun im Hintergrund weiter. Die Bestellungen sollten in wenigen Minuten erfolgreich importiert sein. Hierzu dann einfach die Seite neu aufrufen.`,
                    };
                    this.toastService.showToast(this.toastService.TOAST_STATUS.warning, toastMSG, 20000);
                }else{
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    let toastMSG = {
                        title: 'Es ist ein unerwarteter Fehler aufgetreten!',
                        body: `Es konnten keine Lieferungen von ${marketplace.marketplaceName} abgefragt werden!`,
                    };
                    if(err.error != undefined && err.error.errors != undefined && err.error.errors[0]){
                        toastMSG.body += '\n' + err.error.errors[0];
                    }else if(err.error != undefined && err.error.error != undefined && err.error.error.length > 0){
                        toastMSG.body += '\n' + err.error.error;
                    }
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, toastMSG, 10000);
                }
                this.spinnerService.close();
                this.dialogRef.close({count: -1, type: undefined});
            },
            next: value => {
                // console.log(value);
                this.spinnerService.close();
                this.dialogRef.close({count: value.body, type: marketplace.marketplaceName});
            },
        });
    }


    private loadTemplates(): void{

        this.templateService.getAll().subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.API_TEMPLATE_LOAD_FAILED,10000, err);
            },
            next: value => {
                this.templates = [];
                for(let i = 0; i < value.body.length; i++){
                    if(value.body[i].templateType == 'import'){
                        this.templates.push(value.body[i]);
                        if(this.defaultSetting != undefined && this.defaultSetting.value != undefined && this.defaultSetting.value == value.body[i].templateId){
                            this.selectedTemplate = value.body[i];
                        }
                    }
                }
            },
        });
    }

    public mapToAddressForEbayRequest(address: SenderAddress): any{
        let mappedAddress: any = {};

        mappedAddress.senderName = address.firstName;
        mappedAddress.senderAdditionalName = address.lastName;
        mappedAddress.senderStreet = address.street;
        mappedAddress.senderHousenumber = address.housenumber;
        mappedAddress.senderZip = address.zip;
        mappedAddress.senderCity = address.city;
        mappedAddress.senderCountry = "DE";
        mappedAddress.senderContactId = address.glsContactId;

        return mappedAddress;
    }

    public templateImport(): void{
        let counter = 0;
        let senderAddress: SenderAddress = this.senderAddresses[0];
        if(this.senderAddresses[this.selectedAddressIndex] != undefined){
            senderAddress = this.senderAddresses[this.selectedAddressIndex];
        }

        this.spinnerService.open();
        this.selectedFiles.forEach(file => {
            this.templateService.doImport(this.selectedTemplate.templateId, senderAddress, file).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                },
                // tslint:disable-next-line:no-empty
                next: value => {
                    let count = 0;
                    let errors = [];
                    for(let i = 0; i < value.body.body.length; i++){
                        if(value.body.body[i].length > 0){
                            errors.push(file.name + ': ' + value.body.body[i]);
                        }else{
                            count++;
                        }
                    }
                    errors.forEach(x => {
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.FILE_IMPORT_FAIL, 0, x);
                    });
                    if(count > 0){
                        const MESSSAGE = `Es wurden ${count} Lieferungen aus ${file.name} importiert.`;
                        this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.FILE_IMPORT_SUCCESS, 10000, MESSSAGE);
                    }
                },
                complete: () => {
                    counter++;
                    if(counter >= this.selectedFiles.length){
                        this.spinnerService.close();
                        this.dialogRef.close({
                            type: 'type--file',
                        });
                    }
                },
            });
        });
    }

    public dropped(event: any): void{
        let data = [];
        this.selectedFiles = [];
        event.forEach(drop => {
            data.push(drop.fileEntry);
        });
        let count = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].isFile) {
                const fileEntry = data[i] as FileSystemFileEntry;
                fileEntry.file((f: File) => {
                    this.selectedFiles.push(f);
                    count++;
                });
            }
        }
    }

    public fileUploadClick(): void{
        document.getElementById('fileOnClickUpload').click();
    }

    public fileInputChange(event: any): void{
        let data: FileList = event.target.files;
        this.selectedFiles = [];
        for (let i = 0; i < data.length; i++) {
            if (data.item(i) != undefined) {
                this.selectedFiles.push(data.item(i));
            }
        }
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    public async loadShopAPIs(): Promise<void>{
        await this.shopService.getAllTypes().toPromise().then((res: GenericResponse<ShopType[]>) => {
            this.eShopTypes = res.body;
        }).catch(err => {
            // tslint:disable-next-line:no-console
            console.log(err);
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
        });
        await this.shopService.getAll().toPromise().then((res: GenericResponse<ShopDefinition[]>) => {
            this.eShops = res.body;
        }).catch(err => {
            // tslint:disable-next-line:no-console
            console.log(err);
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
        });
    }

    public async loadMarketsAPIs(): Promise<void>{
        await this.marketplaceService.getAllTypes().toPromise().then((res: GenericResponse<MarketplaceType[]>) => {
            this.marketPlaceTypes = res.body;
        }).catch(err => {
            // tslint:disable-next-line:no-console
            console.log(err);
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
        });
        await this.marketplaceService.getAll().toPromise().then((res: GenericResponse<MarketplaceDefinition[]>) => {
            this.marketPlaces = res.body;
        }).catch(err => {
            // tslint:disable-next-line:no-console
            console.log(err);
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
        });
    }

    public getEShopTypeName(type: string): string{
        if(this.eShopTypes.find(x => x.key == type) != undefined){
            return this.eShopTypes.find(x => x.key == type).name;
        }
        return '';
    }

    public getMarketPlaceName(type: string): string{
        if(this.marketPlaceTypes.find(x => x.key == type) != undefined){
            return this.marketPlaceTypes.find(x => x.key == type).name;
        }
        return '';
    }
}
