<div class="content">
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>

    <div class="form-group row" *ngIf="senderAddresses != undefined && senderAddresses.length > 0 && addressSetupDone">
        <div class="col-12">
            <label class="label col-form-label" for="autocompleteAddresses">Absender</label>
            <nb-select id="autocompleteAddresses" [(selected)]="selectedAddressIndex">
                <nb-option *ngFor="let option of senderAddresses; let i = index" [value]="i">
                    {{ option.firstName !== undefined && option.firstName !== null ? option.firstName : '' }} {{ option.lastName !== undefined && option.lastName !== null ? option.lastName : '' }}, {{option.street}} {{option.housenumber}}, {{option.zip}} {{option.city}}
                </nb-option>
            </nb-select>
        </div>
    </div>
    <div class="row buttons">
        <div class="col-12 d-flex justify-content-center">

            <nb-actions class="headerActions" size="medium" fullWidth>
                <nb-action (click)="selectedTab = 1" [ngClass]="{'selected': selectedTab == 1}">
                    <nb-icon class="fas fa-shopping-cart"></nb-icon>
                    <span>Marktpl&auml;tze</span>
                </nb-action>
                <nb-action (click)="selectedTab = 2" [ngClass]="{'selected': selectedTab == 2}">
                    <nb-icon class="fas fa-shopping-cart"></nb-icon>
                    <span>E-Shops</span>
                </nb-action>
                <nb-action (click)="selectedTab = 3" [ngClass]="{'selected': selectedTab == 3}">
                    <nb-icon class="fas fa-file-csv"></nb-icon>
                    <span>Import (Datei)</span>
                </nb-action>
            </nb-actions>

        </div>
    </div>
    <div class="row" *ngIf="selectedTab == 1">
        <div class="col-12">
            <button nbButton style="width: 100%; margin-bottom: 10px;" (click)="doAmazonImport()">Amazon Import</button>
            <button nbButton [disabled]="!btnActive" style="width: 100%;" (click)="doEbayImport()">Ebay Import</button>
            <button *ngFor="let place of marketPlaces" nbButton style="width: 100%; margin-top: 10px;" (click)="doImportMarketplace(place)">{{getMarketPlaceName(place.marketplaceType)}} - {{place.marketplaceName}}</button>
        </div>
    </div>
    <div class="row" *ngIf="selectedTab == 2">
        <div class="col-12">
            <button *ngFor="let shop of eShops" nbButton style="width: 100%; margin-bottom: 5px;" (click)="doImport(shop)">{{getEShopTypeName(shop.shopType)}} - {{shop.shopName}}</button>
        </div>
    </div>
    <div class="row" *ngIf="selectedTab == 3">
        <div class="col-12">
            <div class="form-group row" *ngIf="templates != undefined">
                <div class="col-12">
                    <label class="label col-form-label" for="templateList">Template</label>
                    <nb-select id="templateList" placeholder="Template hier auswählen" [(selected)]="selectedTemplate" class="w-100" style="max-width: 100% !important;">
                        <nb-option *ngFor="let option of templates" [value]="option">
                            {{ option.templateName }}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-12">
                    <ngx-file-drop dropZoneLabel="{{selectedFiles.length > 0 ? selectedFiles.length + ' Dateien ausgewählt.' : 'Dateien hier her ziehen oder Datei durch Klick hochladen'}}"
                                   (onFileDrop)="dropped($event)" (click)="fileUploadClick()" style="cursor:pointer;">
                    </ngx-file-drop>
                    <input id="fileOnClickUpload" multiple="multiple" type="file" style="display: none;" (change)="fileInputChange($event)">
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <button nbButton (click)="templateImport()" [disabled]="selectedTemplate == undefined">Importieren</button>
                </div>
            </div>
        </div>
    </div>
</div>
