<div class="row">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="cancel()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <h5>Adresse hinzuf&uuml;gen</h5>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-12 mandatoryCol">
                <span class="mandatory">*</span> Pflichtfeld
            </div>
        </div>
        <div class="full-name-inputs">
            <div class="form-group row">
                <label for="senderData.pickupAddress.firstName" class="label col-sm-3 col-form-label">Name</label>
                <input id="senderData.pickupAddress.firstName" class="col-9" type="text" [(ngModel)]="address.firstName" nbInput fullWidth placeholder="Name" [disabled]="disableEdit">
            </div>
            <div class="form-group row">
                <label for="senderData.pickupAddress.lastName" class="label col-sm-3 col-form-label">Firma</label>
                <input id="senderData.pickupAddress.lastName" class="col-9" type="text" [(ngModel)]="address.lastName" nbInput fullWidth placeholder="Firma" [disabled]="disableEdit">
            </div>
        </div>
        <div class="form-group row">
            <label for="senderData.pickupAddress.street" class="label col-sm-3 col-form-label">Stra&szlig;e <span class="mandatory">*</span></label>
            <input id="senderData.pickupAddress.street" class="col-9" type="text" [(ngModel)]="address.street" nbInput fullWidth placeholder="Straße" [disabled]="disableEdit">
        </div>
        <div class="form-group row">
            <label for="senderData.pickupAddress.housenumber" class="label col-sm-3 col-form-label">Hausnummer <span class="mandatory">*</span></label>
            <input id="senderData.pickupAddress.housenumber" class="col-9" type="text" [(ngModel)]="address.housenumber" nbInput fullWidth placeholder="Hausnummer" [disabled]="disableEdit">
        </div>
        <div class="form-group row">
            <label for="senderData.pickupAddress.zip" class="label col-sm-3 col-form-label">Postleitzahl <span class="mandatory">*</span></label>
            <input id="senderData.pickupAddress.zip" class="col-9" type="text" [(ngModel)]="address.zip" nbInput fullWidth placeholder="Postleitzahl" [disabled]="disableEdit">
        </div>
        <div class="form-group row">
            <label for="senderData.pickupAddress.city" class="label col-sm-3 col-form-label">Stadt <span class="mandatory">*</span></label>
            <input id="senderData.pickupAddress.city" class="col-9" type="text" [(ngModel)]="address.city" nbInput fullWidth placeholder="Stadt" [disabled]="disableEdit">
        </div>
        <div class="form-group row">
            <label for="senderData.pickupAddress.country" class="label col-sm-3 col-form-label">Land <span class="mandatory">*</span></label>
            <input id="senderData.pickupAddress.country" class="col-9" type="text" [(ngModel)]="address.country" nbInput fullWidth placeholder="Land" disabled>
        </div>
        <div *ngIf="isSenderAddress()" class="form-group row">
            <label for="senderData.pickupAddress.glsid" class="label col-sm-3 col-form-label">GLS Kontakt ID <span class="mandatory">*</span></label>
            <input id="senderData.pickupAddress.glsid" class="col-9" type="text" [(ngModel)]="address.glsContactId" nbInput fullWidth placeholder="Kontakt ID">
        </div>


    </nb-card-body>
    <nb-card-footer class="justify-content-between" style="display: flex;">
        <button nbButton (click)="save()">
            Speichern
        </button>
    </nb-card-footer>
</nb-card>