import * as Routes from "../Routes";
import {DataService} from "../data/data.service";
import {GetAllSettingResponse} from "../../responses/settings/GetAllSettingResponse";
import {GetSettingResponse} from "../../responses/settings/GetSettingResponse";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Setting} from "../../models/Settings.model";

@Injectable({
    providedIn: 'root',
})
export class SettingsService {

    public KEY_SENDER_DATA = 'sender.data.key';
    public KEY_SENDER_ADDITIONAL_ADDRESSES = 'sender.data.addresses';
    public KEY_EBAY_DATA = 'ebay.data.key';
    public KEY_CUSTOMER_WEIGH = 'customer.no.weigh.status';
    public NO_WEIGH_CUSTOMER_SETTINGS_KEY = "customer.no.weigh.settings";
    public KEY_WEATHER_DATA = "weather.data";
    public EBAY_TOKEN_SETTINGS_KEY = "ebay.token";
    public CUSTOMER_SOFTWARE_STATUS = "customer.is.software";
    public KEY_PICKUP_DAYS = "sender.data.pickupdays";
    public KEY_COMPANY_LOGO = "sender.data.companylogo";
    public KEY_DEFAULT_TEMPLATE_ID = "template.default.id";
    public KEY_DEFAULT_FLEX_DELIVERY = "flexdelivery.default.value";

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }


    public getSetting(key?: number | string): Observable<GetSettingResponse> {
        if (key === undefined) {
            return this.http.get<GetSettingResponse>(`${Routes.SETTINGS_ROUTE}/`, this.dataService.getHttpOptions());
        }
        return this.http.get<GetSettingResponse>(`${Routes.SETTINGS_ROUTE}/${key}/`, this.dataService.getHttpOptions());
    }

    public createSetting(setting: Setting): Observable<GetSettingResponse> {
        return this.http.post<GetSettingResponse>(`${Routes.SETTINGS_ROUTE}/`, setting, this.dataService.getHttpOptions());
    }

    public updateSetting(key: number | string, setting: Setting): Observable<GetSettingResponse> {
        return this.http.put<GetSettingResponse>(`${Routes.SETTINGS_ROUTE}/${key}/`, setting, this.dataService.getHttpOptions());
    }

    public deleteSetting(key: number | string): Observable<GetAllSettingResponse> {
        return this.http.delete<GetAllSettingResponse>(`${Routes.SETTINGS_ROUTE}/${key}/`, this.dataService.getHttpOptions());
    }

    public getAll(): Observable<GetAllSettingResponse>{
        return this.http.get<GetAllSettingResponse>(`${Routes.SETTINGS_ROUTE}/all/all`, this.dataService.getHttpOptions());
    }


}
