import {Component, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {DefaultFilter} from "ng2-smart-table";
import {FormControl} from "@angular/forms";


@Component({
    template: `
        <input class="form-control"
               [placeholder]="column.title"
               [formControl]="inputControl"
               [ngClass]="{'hasValue': inputControl.value != undefined && inputControl.value.length > 0}">
        <i class="fas fa-times clearFilterValue" (click)="clear()" *ngIf="inputControl.value != undefined && inputControl.value.length > 0"></i>
    `,
})
export class SimpleTableFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    inputControl = new FormControl();

    constructor() {
        super();
    }

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(this.delay)
            )
            .subscribe((value: string) => {
                if(this.inputControl.status === 'VALID' && this.inputControl.value != undefined)
                {
                    this.query = value !== null ? value : "";
                    this.setFilter();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.query);
        }
    }

    public clear(): void{
        this.inputControl.setValue(undefined);
        this.query = '';
        this.setFilter();
    }
}