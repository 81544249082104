<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">Jetzt Tagesabschluss erstellen:</h2>
        </div>
        <div class="modalBody">
            <p>Es wurde zuletzt kein Abschluss erstellt.</p>
            <p>Um alle Funktionen von Mr. Shipment nutzen zu k&ouml;nnen ist es notwendig einen Abschluss zu erstellen. Ihre Sendungen werden dann in den Bereich „Versandarchiv“ geschoben und sind dort auch zu finden.</p>

            <p>Ihre Vorteile u.a.: Suchfunktionen, nach Paketstatus filtern und Warnhinweise bei Paketen im Paketshop.</p>
            <p><b>Jetzt alle Pakete mit dem Status unterwegs in Versandarchiv verschieben?</b></p>
            <p><button nbButton class="mr-2" (click)="doFinalReport()">JA</button><button nbButton (click)="close()">NEIN</button></p>
        </div>
    </div>
</div>
