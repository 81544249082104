<nb-card>
    <nb-card-header>
        Eintr&auml;ge {{actionDesc}}?
    </nb-card-header>
    <nb-card-body>
        Sind Sie sicher, dass Sie {{number}} Eintr&auml;ge {{actionDesc}} m&ouml;chten?
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="confirm()">Ok</button>
        <button nbButton status="danger" (click)="dismiss()" style="float: right">Abbrechen</button>
    </nb-card-footer>
</nb-card>