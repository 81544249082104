import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Contact } from 'app/models/Contact.model';
import { Country } from 'app/models/Country';
import { NbDialogRef } from '@nebular/theme';
import { ToastService } from 'app/services/toast/toast.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ngx-contact-modal',
    templateUrl: './contact-modal.component.html',
    styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit, AfterViewInit {

    @Input()
    public contact: Contact;
    @Input()
    public countries: {value:string, title: string}[];
    @Input()
    public update: boolean;

    @ViewChild('autocompleteCountry')
    public elementAutocompleteCountry: ElementRef;
    public autocompleteCountries: Country[];
    public selectedCountry: Country;
    public filteredCountryOptions: Observable<Country[]>;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.mapCountries();
        this.filteredCountryOptions = this.getFilteredCountryOptions('');
    }

    ngAfterViewInit(): void {
        if (this.contact.country != undefined && this.contact.country != '') {
            const temp = this.autocompleteCountries.find(x => x.key == this.contact.country);
            if (temp != undefined) {
                this.onSelectionChangeCountry(temp);
                return;
            }
        }
    }

    public submit(): void {
        if (!this.dataIsValid(true)) {
            return;
        }
        this.dialogRef.close({
            save: (this.update === undefined || this.update === false) ? true : false,
            update: this.update,
            contact: this.contact,
        });
    }

    public dataIsValid(showToastB: boolean): boolean {
        if (
            this.contact.nameLine1 === '' ||
            this.contact.addressLine === '' ||
            this.contact.postal === '' ||
            this.contact.city === '' ||
            this.contact.country === '' || !/^(.*[0-9].*)$/.test(this.contact.addressLine)
        ) {
            if(showToastB){
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD, 4000);
            }
            if(showToastB && !/^(.*[0-9].*)$/.test(this.contact.addressLine)){
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_HOUSENUMBER, 4000);
            }
            return false;
        }
        return true;
    }

    public set(property: string, value: any) {
        this.contact[property] = value;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    public onSelectionChangeCountry(event: Country): void {
        this.selectedCountry = event;
        const countryDisplay = `${event.name} `;
        this.filteredCountryOptions = this.getFilteredCountryOptions(countryDisplay);
        this.elementAutocompleteCountry.nativeElement.value = countryDisplay;
        this.set('country', event.key);
    }

    private getFilteredCountryOptions(value: string): Observable<Country[]> {
        return of(value).pipe(
            map(filterString => this.filterCountry(filterString))
        );
    }
    public onChangeCountry(): void {
        this.filteredCountryOptions = this.getFilteredCountryOptions(this.elementAutocompleteCountry.nativeElement.value);
    }

    private filterCountry(value: string): Country[] {
        const filterString = value.toLowerCase();

        return this.autocompleteCountries.filter(x => {
            if ((x.name != null && x.name.toLocaleLowerCase().includes(filterString))
                || (x.key != null && x.key.toLocaleLowerCase().includes(filterString))
            ) {
                return true;
            }
        });
    }

    private mapCountries(): void {
        this.autocompleteCountries = [];
        if (this.countries == undefined) {
            return;
        }
        for (let c of this.countries) {
            const temp = new Country();
            temp.name = c.title;
            temp.key = c.value;
            this.autocompleteCountries.push(temp);
        }

    }
}
