<div class="row footerRow">
    <div class="col-12">
        <span class="element">
            <a href="https://mrshipment.de/impressum/" target="_blank">Impressum</a>
        </span>
        <span class="element">
            <a href="https://mrshipment.de/datenschutzerklaerung/" target="_blank">Datenschutzerkl&auml;rung</a>
        </span>
        <span class="element">
            <a href="https://mrshipment.de/wp-content/uploads/Allgemeine-Geschaeftsbedingungen-Mr-Shipment-Gipfelfreunde-UG.pdf" target="_blank">AGB</a>
        </span>
    </div>
</div>