<div class="content">
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-12">
            <div class="form-group row" *ngIf="templates != undefined">
                <div class="col-12">
                    <label class="label col-form-label" for="templateList">Template</label>
                    <nb-select id="templateList" placeholder="Template hier auswählen" [(selected)]="selectedTemplate" class="w-100" style="max-width: 100% !important;">
                        <nb-option *ngFor="let option of templates" [value]="option">
                            {{ option.templateName }}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <button nbButton (click)="templateExport()" [disabled]="selectedTemplate == undefined">Exportieren</button>
                </div>
            </div>
        </div>
    </div>
</div>