import {Component, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";

@Component({
    selector: 'ngx-e-shop-plugins',
    templateUrl: './e-shop-plugins.component.html',
    styleUrls: ['./e-shop-plugins.component.scss'],
})
export class EShopPluginsComponent implements OnInit {

    constructor(
        protected dialogRef: NbDialogRef<any>
    ) {
    }

    ngOnInit(): void {
        //
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    public download(type: string): void{
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');

        switch (type){
        case 'woocommerce':
            link.href = 'assets/mr-shipment-woocommerce-plugin.zip';
            link.download = 'mr-shipment-woocommerce-plugin.zip';
            break;
        default:
            return;
        }

        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
