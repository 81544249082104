import {Component, EventEmitter, Input} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `
    <input class="form-control ng-pristine" [(ngModel)]="value" (focusout)="change()" (ngModelChange)="modelChange()" type="text"/>
  `,
})
export class StepperLengthTableColumnComponent implements ViewCell {
    @Input() value: number;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object
    public emitter: EventEmitter<any> = new EventEmitter<any>();

    public change(): void{
        this.rowData.length = this.value;
        this.rowData.isValid = true;
        this.emitter.emit(this.rowData);
    }

    public modelChange(): void{
        this.rowData.length = this.value;
        this.rowData.isValid = true;
    }
}