import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {DatePipe} from '@angular/common';
import {GenericResponse} from "../../responses/GenericResponse";
import {HttpErrorResponse} from '@angular/common/http';
import {SettingsService} from "../../services/settings/settings.service";
import {Shipment} from "../../models/Shipment.model";
import {ShipmentService} from "../../services/shipments/shipment.service";
import {SupportPopupComponent} from "../support-popup/support-popup.component";
import {ToastService} from "../../services/toast/toast.service";
import {TrackingService} from "../../services/tracking/tracking.service";

@Component({
    selector: 'ngx-tracking-modal',
    templateUrl: './tracking-modal.component.html',
    styleUrls: ['./tracking-modal.component.scss'],
})
export class TrackingModalComponent implements OnInit {
    @Input() public shipment: Shipment;
    public trackingLoading: boolean = true;
    public trackingData: any[];
    public isParcelShop: boolean = false;
    public isParcelShopLoading: boolean = false;
    public weighCustomer: boolean = false;
    public trackingStatus: number = 0; // 0: parcel-data sent, 1: in shipping, 2: delivered, 3: delivered to parcelShop, 4: questions, 5: not delivered

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private trackingService: TrackingService,
        private settingsService: SettingsService,
        private modalController: NbDialogService,
        private shipmentService: ShipmentService
    ) {
        this.trackingLoading = true;
    }

    ngOnInit(): void {
        this.trackingLoading = true;
        if(this.shipment == undefined){
            setTimeout(() => {
                if(this.shipment == undefined){
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger,this.toastService.LOADING_DATA_FAILED,10000);
                    this.close();
                }else{
                    this.loadTracking();
                }
            }, 2000);
        }else{
            this.loadTracking();
        }
        this.settingsService.getSetting(this.settingsService.KEY_CUSTOMER_WEIGH).subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
            },
            next: response => {
                this.weighCustomer = response.body.value === 'true';
            },
        });
    }

    public loadTracking(): void{
        if(this.shipment.glsState == TrackingService.GLS_STATE_IN_SERVICE){
            this.trackingStatus = 1;
        }else if(this.shipment.glsState == TrackingService.GLS_STATE_SHIPPED){
            this.trackingStatus = 2;
        }else if(this.shipment.glsState == TrackingService.GLS_STATE_PARCEL_SHOP){
            this.trackingStatus = 3;
        }else if(this.shipment.glsState == TrackingService.GLS_STATE_MISSING_INFO){
            this.trackingStatus = 4;
        }else if(this.shipment.glsState == TrackingService.GLS_STATE_NOT_DELIVERED){
            this.trackingStatus = 5;
        }
        this.isParcelShop = this.shipment.glsState == TrackingService.GLS_STATE_PARCEL_SHOP;

        this.trackingService.getTrackingInfo(this.shipment.shipmentId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_TRACKING_DATA_FAILED,10000,err);
            },
            next: value => {
                this.trackingData = value.body;
                this.trackingLoading = false;
            },
        });
        if(this.isParcelShop){
            this.isParcelShopLoading = true;
            this.trackingService.getParcelShopTrackingInfo(this.shipment.shipmentId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_TRACKING_PARCELSHOP_DATA_FAILED,10000,err);
                    this.isParcelShopLoading = false;
                },
                next: value => {
                    let container = document.createElement('div');
                    container.innerHTML = value.body
                        .replace(/<img.*>/gm,'')
                        .replace(/<script>.*<\/script>/gm,'')
                        .replace(/<style>.*<\/style>/gm,'')
                        .replace(/<iframe>.*<\/iframe>/gm,'');
                    let content = container.getElementsByClassName('container_parcel_info')[0];
                    if(content == undefined){
                        content = document.createElement('div');
                        content.className = 'col-12';
                        content.innerHTML = `<span style="color: red;">Paketshop-Daten konnten nicht geladen werden:</span><br>`;
                        let link = document.createElement('a');
                        link.href = `https://www.gls-pakete.de/sendungsverfolgung?trackingNumber=${this.shipment.parcelNumber}`;
                        link.target = '_blank';
                        link.text = 'GLS-Tracking';
                        content.appendChild(link);
                    }else{
                        for(let i = 0; i < content.getElementsByTagName('a').length; i++){
                            let btn = content.getElementsByTagName('a')[i];
                            btn.remove();
                        }
                    }
                    document.getElementById('importedParcelContent').appendChild(content);
                    this.isParcelShopLoading = false;
                },
            });
        }
    }

    public getShipmentDate(): string {

        let trackingDate: string = '-';

        if(!this.trackingLoading){
            this.trackingData.forEach(glsEvent => {
                if(glsEvent.glsEventCode == '0.0') {
                    trackingDate = this.formatDate(glsEvent.glsEventCreationTime);
                }
            });
        }

        return trackingDate;
    }

    public getDeliveredDate(): string {

        let trackingDate: string = '-';

        if(!this.trackingLoading) {
            this.trackingData.forEach(glsEvent => {
                if (glsEvent.glsEventCode == '3.0') {
                    trackingDate = this.formatDate(glsEvent.glsEventCreationTime);
                }
            });
        }

        return trackingDate;
    }

    public formatDate(input: string): string {
        const datepipe: DatePipe = new DatePipe('en-US');
        return datepipe.transform(input, 'dd.MM.yyyy');
    }

    public formatDateTime(input: string): string {
        const datepipe: DatePipe = new DatePipe('en-US');
        return datepipe.transform(input, 'dd.MM.yyyy - HH:mm');
    }

    public close(load: boolean = false): void{
        this.dialogRef.close({reload: load});
    }

    public export(): void{

        this.trackingService.getTrackingSummary(this.shipment.shipmentId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.ERROR_CREATING_LABEL, 10000, err);
            },
            next: value => {

                let blob = new Blob([value.body], { type: 'application/octet-stream'});
                let url = window.URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.setAttribute('type','hidden');
                link.href = url;
                link.download = this.shipment.parcelNumber + '-trackingSummary.pdf';
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
        });
    }

    public manuallyAbortTracking(): void {
        this.shipmentService.abortTrackinManually(this.shipment.shipmentId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.ERROR_ON_MANUALLY_ABRORTING_TRACKING, 10000, err);
            },
            next: value => {
                this.close(true);
            },
        });
    }

    public canManuallyAbort(): boolean {
        return this.shipment.glsState != TrackingService.GLS_STATE_SHIPPED && this.shipment.glsState != TrackingService.GLS_STATE_CANCELED;
    }

    public support(): void{

        const modal = this.modalController.open(SupportPopupComponent, {closeOnBackdropClick  : false});
        modal.onClose.subscribe({
            next: data => {
                if (data !== undefined && data.send != undefined && data.send) {
                    this.shipmentService.sendSupport(this.shipment.shipmentId).toPromise()
                        .catch((err: HttpErrorResponse) => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.SUPPORT_REQUEST_FAILED, 10000, err);
                        })
                        .then((res: GenericResponse<string>) => {
                            this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.SUPPORT_REQUEST_SUCCESS);
                        });
                }
            },
        });
    }
}
