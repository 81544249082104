import { Component, Input } from '@angular/core';
import {ParcelCategoryService} from "../../services/parcel-category/parcel-category.service";
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `
    <span *ngIf="!weighCustomer">
        {{ value | number:'1.2-2':'de-DE' }} &euro;
        <span class="priceTooltipInfo">i</span>
        <div class="priceTooltip">
            <span>Paketpreis bestehend aus:</span>
            <span>Paket {{rowData.categoryKey}}: {{ getBasePrice() | number:'1.2-2':'de-DE' }} &euro;</span>
            <span *ngIf="rowData.notBandCapable">NB-Zuschlag: {{ getNbPrice() | number:'1.2-2':'de-DE' }} &euro;</span>
            <span *ngIf="rowData.island">Inselzuschlag: {{ getIslandPrice() | number:'1.2-2':'de-DE' }} &euro;</span>
            <span class="miniInfo">*alle Preise zzgl. MwSt.</span>
        </div>
    </span>
    <span *ngIf="weighCustomer">-
        <span class="priceTooltipInfo" *ngIf="rowData.island">i</span>
        <div class="priceTooltip" *ngIf="rowData.island">
            <span>Paket mit Inselzuschlag + {{ getIslandPrice() | number:'1.2-2':'de-DE' }}&euro;</span>
            <span class="miniInfo">*alle Preise zzgl. MwSt.</span>
        </div>
    </span>
  `,
})
export class PriceTableColumnComponent implements ViewCell {
    @Input() value: number;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object

    public weighCustomer: boolean;
    constructor(
        private categoryService: ParcelCategoryService
    ) {
    }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
    }

    public getBasePrice(): number{
        for(let i = 0; i < this.categoryService.categories.length; i++){
            const cat = this.categoryService.categories[i];
            if(cat.countryGroupKey == this.rowData.countryGroupKey) {
                for (let j = 0; j < cat.priceClassifications.length; j++) {
                    const price = cat.priceClassifications[j];
                    if (price.classificationName == this.rowData.categoryKey) {
                        return price.price;
                    }
                }
            }
        }
        return 0;
    }

    public getNbPrice(): number{
        for(let i = 0; i < this.categoryService.categories.length; i++){
            const cat = this.categoryService.categories[i];
            if(cat.countryGroupKey == this.rowData.countryGroupKey && this.rowData.categoryKey.startsWith(cat.categoryKey)){
                return cat.oversizeSupplement;
            }
        }
        return 0;
    }

    public getIslandPrice(): number{
        for(let i = 0; i < this.categoryService.categories.length; i++){
            const cat = this.categoryService.categories[i];
            if(cat.countryGroupKey == this.rowData.countryGroupKey && this.rowData.categoryKey.startsWith(cat.categoryKey)){
                return cat.islandSupplement;
            }
        }
        return 0;
    }

}
