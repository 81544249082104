<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">Hinweis</h2>
        </div>
        <div class="modalBody">
            <p>Nicht vergessen, wenn sich Ihre Sendungen nicht im Versandarchiv befinden, k&ouml;nnen Sie Funktionen wie Statusfilter oder Pakete im Paketshop nicht vollumf&auml;nglich nutzen.</p>
            <p><b>Jetzt Sendungen ins Versandarchiv verschieben und Abschluss erstellen?</b></p>
            <p><button nbButton class="mr-2" (click)="doFinalReport()">JA</button><button nbButton (click)="close()">NEIN</button></p>
        </div>
    </div>
</div>
