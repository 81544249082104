import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from "@nebular/theme";

@Component({
    selector: 'ngx-support-popup',
    templateUrl: './support-popup.component.html',
    styleUrls: ['./support-popup.component.scss'],
})
export class SupportPopupComponent implements OnInit {

    constructor(
        protected dialogRef: NbDialogRef<any>
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void { }

    public close(send: boolean = false): void{
        this.dialogRef.close({send: send});
    }

}
