<div class="content">
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>
    <nb-stepper orientation="horizontal" [selectedIndex]="step">
        <nb-step label="Empf&auml;nger Eingabe">
            <form>
                <div class="form-group row" *ngIf="senderAddresses != undefined && senderAddresses.length > 0 && addressSetupDone">
                    <div class="col-12">
                        <label class="label col-form-label" for="autocompleteAddresses">Absender</label>
                        <nb-select id="autocompleteAddresses" [(selected)]="selectedAddressIndex">
                            <nb-option *ngFor="let option of senderAddresses; let i = index" [value]="i">
                                {{ option.firstName !== undefined && option.firstName !== null ? option.firstName : '' }} {{ option.lastName !== undefined && option.lastName !== null ? option.lastName : '' }}, {{option.street}} {{option.housenumber}}, {{option.zip}} {{option.city}}
                            </nb-option>
                        </nb-select>
                        <!--
                        <input
                            #autocompleteAddresses
                            id="autocompleteAddresses"
                            nbInput
                            [nbAutocomplete]="autoAddresses"
                            placeholder="Absender ausw&auml;hlen..."
                            (input)="onChangeAddresses()"
                            type="text"
                            autocomplete="off"
                        >
                        <nb-autocomplete #autoAddresses (selectedChange)="onSelectionChangeAddresses($event)">
                            <nb-option (click)="clearAddressSelection()" *ngIf="selectedAddress != undefined">Stammdaten verwenden</nb-option>
                            <nb-option *ngFor="let option of filteredOptionsAddresses | async" [value]="option">
                                {{ option.firstName !== undefined && option.firstName !== null ? option.firstName : '' }} {{ option.lastName !== undefined && option.lastName !== null ? option.lastName : '' }} {{option.street}} {{option.housenumber}}, {{option.zip}} {{option.city}}
                            </nb-option>
                        </nb-autocomplete>
                        -->
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <label class="label col-form-label" for="autocomplete">Kontakt-Vorlage</label>
                        <input
                            #autocomplete
                            id="autocomplete"
                            nbInput
                            [nbAutocomplete]="auto"
                            placeholder="Kontakt ausw&auml;hlen..."
                            (input)="onChange()"
                            type="text"
                            autocomplete="off"
                        >
                        <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
                            <nb-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{ option.nameLine1 }} {{ option.nameLine2 !== undefined && option.nameLine2 !== null ? option.nameLine2 : '' }}, {{option.addressLine}}, {{option.postal}} {{option.city}} {{option.country}}
                            </nb-option>
                        </nb-autocomplete>
                    </div>
                </div>
                <div class="row">
                    <hr style="width: 100% !important;">
                </div>
                <div class="row">
                    <div class="col-12 mandatoryCol">
                        <span class="mandatory">*</span> Pflichtfeld
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 label col-form-label" for="name">Name <span class="mandatory">*</span></label>
                    <div class="col-sm-4">
                        <input id="name" name="receiverNameLine1" maxlength="40" placeholder="Name" nbInput fullWidth [(ngModel)]="receiverNameLine1">
                    </div>
                    <label class="col-sm-2 label col-form-label" for="extra">Firma</label>
                    <div class="col-sm-4">
                        <input #extra id="extra" name="receiverNameLine2" maxlength="40" placeholder="Firma" nbInput fullWidth [(ngModel)]="receiverNameLine2">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 label col-form-label" for="address">Stra&szlig;e/ Hr. <span class="mandatory">*</span></label>
                    <div class="col-sm-4">
                        <input #address id="address" name="receiverAddressLine" maxlength="40" placeholder="Straße" nbInput fullWidth [(ngModel)]="receiverAddressLine" >
                    </div>
                    <label class="col-sm-2 label col-form-label" for="receiverAddressLine2">Adresse 2</label>
                    <div class="col-sm-4">
                        <input #address2 id="receiverAddressLine2" name="receiverAddressLine2" maxlength="40" placeholder="Adresse 2" nbInput fullWidth [(ngModel)]="receiverAddressLine2">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 label col-form-label" for="postal">PLZ <span class="mandatory">*</span></label>
                    <div class="col-sm-4">
                        <input #postal id="postal" name="receiverPostal" maxlength="10" placeholder="PLZ" nbInput fullWidth [(ngModel)]="receiverPostal" (blur)="checkPostalData()" >
                    </div>
                    <label class="col-sm-2 label col-form-label" for="city">Stadt <span class="mandatory">*</span></label>
                    <div class="col-sm-4">
                        <input #city id="city" name="receiverCity" maxlength="40" placeholder="Stadt" nbInput fullWidth [(ngModel)]="receiverCity" (blur)="checkPostalData(false)" >
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-2 label col-form-label">
                        <label class="label col-form-label" for="autocompleteCountry">Land</label>
                    </div>
                    <div class="col-sm-4">
                        <input
                            #autocompleteCountry
                            id="autocompleteCountry"
                            nbInput
                            [nbAutocomplete]="autoCountry"
                            placeholder="Land ausw&auml;hlen..."
                            (input)="onChangeCountry()"
                            type="text"
                            autocomplete="off"
                            style="max-width: 100%; width: 100%;"
                        >
                        <nb-autocomplete #autoCountry (selectedChange)="onSelectionChangeCountry($event)">
                            <nb-option *ngFor="let option of filteredCountryOptions | async" [value]="option">
                                {{ option.name }}
                            </nb-option>
                        </nb-autocomplete>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 label col-form-label" for="mail">E-Mail</label>
                    <div class="col-sm-4">
                        <input #mail id="mail" name="receiverEmail" maxlength="80" placeholder="E-Mail" nbInput fullWidth [(ngModel)]="receiverEmail" >
                    </div>
                    <label class="col-sm-2 label col-form-label" for="phone">Telefon</label>
                    <div class="col-sm-4">
                        <input #phone id="phone" name="receiverPhone" maxlength="40" placeholder="Telefon" nbInput fullWidth [(ngModel)]="receiverPhone">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 label col-form-label" for="additionalContactInformation1">Kontakt</label>
                    <div class="col-sm-4">
                        <input #additionalContact id="additionalContactInformation1" name="additionalContactInformation1" maxlength="40" placeholder="Kontakt" nbInput fullWidth [(ngModel)]="additionalContactInformation1" >
                    </div>
                    <label class="col-sm-2 label col-form-label" for="additionalContactInformation2">Zusatz</label>
                    <div class="col-sm-4">
                        <input #additionalContact2 id="additionalContactInformation2" name="additionalContactInformation2" maxlength="40" placeholder="Zusatz" nbInput fullWidth [(ngModel)]="additionalContactInformation2">
                    </div>
                </div>
                <div class="row"  *ngIf="!shipmentHasHousenumber()">
                    <div class="col-12">
                        <span style="color: red; font-size: 12px">Pr&uuml;fen Sie Ihre Eingabe auf eine fehlende Hausnummer.</span>
                    </div>
                </div>
                <div class="row btnRow">
                    <button nbButton (click)="nextStep()" [disabled]="!shouldGoToStep2()">Weiter</button>
                </div>
            </form>
        </nb-step>
        <nb-step label="Pakete">
            <div class="row categoryRow">
                <div
                        *ngFor="let data of validPrices"
                        [ngClass]="{'hide': !shouldDisplayCategory(data), 'form-group': true, 'price': true, 'col-2': true,
                         'color-national-default' : data.categoryKey == 'BP', 'color-national-express': data.categoryKey == 'EXP',
                         'color-international-default': data.categoryKey.includes('LG') || data.categoryKey == 'INT'}"
                        (click)="addPriceToList(data)"
                >
                    <label class="label col-12 col-form-label">{{data.categoryName}}</label>
                </div>
                <!--
                <div 
                    *ngFor="let data of validPrices"
                    [ngClass]="{'hide': !shouldDisplayCategory(data.category), 'form-group': true, 'price': true, 'col-2': true}"
                    (click)="addPriceToList(data.category, data.price)"
                >
                    <label class="label col-12 col-form-label">{{data.price.classificationName}}</label>
                    <div class="col-12">
                        {{ formatPrice(data.price.price) }}
                    </div>
                </div>
                -->
            </div>
            <div class="row tableRow">
                <ngx-smart-table
                    class="smartTable"
                    [settings]="settings"
                    [loading]="loading"
                    [data]="dataSource"
                    (editConfirm)="onTableEdit($event)"
                    (deleteConfirm)="onTableDelete($event)"
                    (custom)="onCustomAction($event)"
                    [shouldConfirmDelete]="false"
                ></ngx-smart-table>
            </div>
            <div class="row btnRow">
                <button nbButton (click)="previousStep()">Zur&uuml;ck</button>
                <button nbButton (click)="nextStep()" [disabled]="!shouldGoToStep3()" >Weiter</button>
            </div>
        </nb-step>
        <nb-step label="Hinzufügen">
            <div class="row" *ngIf="finalCategories.length === 0">
                <p>Es wurden keine Einträge gefunden!</p>
            </div>
            <div class="row">
                <table class="table">
                    <thead *ngIf="!weighCustomer">
                        <tr>
                            <th>
                                Paketkategorie
                            </th>
                            <th>
                                Gewicht (kg)
                            </th>
                            <th>
                                L&auml;nge (cm)
                            </th>
                            <th>
                                Breite (cm)
                            </th>
                            <th>
                                H&ouml;he (cm)
                            </th>
                            <th *ngIf="!softwareCustomer">
                                Preis (&euro;)
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!weighCustomer">
                        <tr *ngFor="let data of finalCategories">
                            <td>
                                {{ prepareCategoryName(data) }}
                            </td>
                            <td>
                                {{ data.weight }}
                            </td>
                            <td>
                                {{ data.length }}
                            </td>
                            <td>
                                {{ data.width }}
                            </td>
                            <td>
                                {{ data.height }}
                            </td>
                            <td *ngIf="!softwareCustomer">
                                {{ data.price | number:'1.2-2':'de-DE' }}
                            </td>
                        </tr>
                        <tr *ngIf="!softwareCustomer">
                            <td [colSpan]="4">

                            </td>
                            <td>
                                Summe:
                            </td>
                            <td>
                                <b>{{ getSum() | number:'1.2-2':'de-DE' }}</b>
                            </td>
                        </tr>
                    </tbody>
                    <thead *ngIf="weighCustomer">
                        <tr>
                            <th>
                                Paketkategorie
                            </th>
                            <th>Anzahl</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="weighCustomer">
                        <tr *ngFor="let data of finalCategories">
                            <td>
                                {{ prepareCategoryName(data) }}
                            </td>
                            <td>
                                {{ data.amount }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!--
                <nb-card style="width: 100% !important;">
                    <nb-card-body>
                        <div class="row">
                            <div [ngClass]="{'col-2': !weighCustomer, 'col-12': weighCustomer}">
                                <b>Paketkategorie</b>
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                <b>Gewicht (kg)</b>
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                <b>L&auml;nge (cm)</b>
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                <b>Breite (cm)</b>
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                <b>H&ouml;he (cm)</b>
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                <b>Preis (&euro;)</b>
                            </div>
                        </div>
                        <div class="row finalRow" *ngFor="let data of finalCategories">
                            <div [ngClass]="{'col-2': !weighCustomer, 'col-12': weighCustomer}">
                                {{ data.categoryKey }}
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                {{ data.weight }}
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                {{ data.length }}
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                {{ data.width }}
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                {{ data.height }}
                            </div>
                            <div class="col-2" *ngIf="!weighCustomer">
                                {{ data.price | number:'1.2-2':'de-DE' }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 offset-10">
                                <hr style="border-color: black; margin-bottom: 3px;">
                                <hr style="border-color: black; margin-top: 3px;">
                                {{ getSum() | number:'1.2-2':'de-DE' }}
                            </div>
                        </div>
                    </nb-card-body>
                </nb-card>
                -->
            </div>
            <div class="row btnRow">
                <button nbButton (click)="previousStep()">Zur&uuml;ck</button>
                <button nbButton (click)="finalize()">Hinzuf&uuml;gen</button>
            </div>
        </nb-step>
    </nb-stepper>

</div>
