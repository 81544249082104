import * as Routes from "../Routes";
import {EventEmitter, Injectable} from "@angular/core";
import {DataService} from "../data/data.service";
import {GenericResponse} from "../../responses/GenericResponse";
import {GetOrderAddress} from '../../models/Address';
import {HttpClient} from "@angular/common/http";
import {MarketplaceDefinition} from "../../models/MarketplaceDefinition";
import {Observable} from "rxjs";
import {timeout} from "rxjs/operators";

export class MarketplaceType{
    public name: string;
    public key: string;
}


@Injectable({
    providedIn: 'root',
})
export class MarketplaceDefinitionService{

    public typesUpdateEvent: EventEmitter<any> = new EventEmitter<any>();
    public marketplaceTypes: MarketplaceType[] = [];

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) {
        this.typesUpdateEvent.subscribe(() => {
            this.loadTypes();
        });
        this.loadTypes();
    }


    public create(def: MarketplaceDefinition): Observable<GenericResponse<MarketplaceDefinition>> {
        return this.http.post<GenericResponse<MarketplaceDefinition>>(`${Routes.MARKETPLACE_ROUTE}/`, def, this.dataService.getHttpOptions());
    }

    public update(id: number | string, def: MarketplaceDefinition): Observable<GenericResponse<MarketplaceDefinition>> {
        return this.http.put<GenericResponse<MarketplaceDefinition>>(`${Routes.MARKETPLACE_ROUTE}/${id}/`, def, this.dataService.getHttpOptions());
    }

    public delete(id: number | string): Observable<GenericResponse<string>> {
        return this.http.delete<GenericResponse<string>>(`${Routes.MARKETPLACE_ROUTE}/${id}/`, this.dataService.getHttpOptions());
    }

    public get(id: number | string): Observable<GenericResponse<MarketplaceDefinition>> {
        return this.http.get<GenericResponse<MarketplaceDefinition>>(`${Routes.MARKETPLACE_ROUTE}/${id}/`, this.dataService.getHttpOptions());
    }

    public getAll(): Observable<GenericResponse<MarketplaceDefinition[]>> {
        return this.http.get<GenericResponse<MarketplaceDefinition[]>>(`${Routes.MARKETPLACE_ROUTE}/all`, this.dataService.getHttpOptions());
    }

    public getAllTypes(): Observable<GenericResponse<MarketplaceType[]>> {
        return this.http.get<GenericResponse<MarketplaceType[]>>(`${Routes.MARKETPLACE_ROUTE}/types`, this.dataService.getHttpOptions());
    }

    public healthCheck(id: number | string): Observable<GenericResponse<boolean>> {
        return this.http.get<GenericResponse<boolean>>(`${Routes.MARKETPLACE_ROUTE}/verify/${id}/`, this.dataService.getHttpOptions());
    }

    private loadTypes(): void{
        this.getAllTypes().subscribe((res: GenericResponse<MarketplaceType[]>) => {
            this.marketplaceTypes = res.body;
        });
    }

    public getOrders(selectedAddress: GetOrderAddress, marketId: string, marketType: string): Observable<GenericResponse<number>>{
        return this.http.post<GenericResponse<number>>(
            `${Routes.BASIC_ROUTE}${marketType}/orders/${marketId}/`,
            selectedAddress, this.dataService.getHttpOptions()).pipe(timeout(30000));
    }

}
