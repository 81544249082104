import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {Router} from "@angular/router";
import {Shipment} from "../../../models/Shipment.model";
import {ShipmentService} from "../../../services/shipments/shipment.service";
import {ToastService} from "../../../services/toast/toast.service";

@Component({
    selector: 'ngx-final-report-warn',
    templateUrl: './final-report-warn.component.html',
    styleUrls: ['./final-report-warn.component.scss'],
})
export class FinalReportWarnComponent implements OnInit {

    @Input() public shipments: Shipment[];

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private shipmentService: ShipmentService,
        private toastService: ToastService,
        private router: Router
    ) {
    }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
    }

    public close(): void {

        this.dialogRef.close();
    }

    public doFinalReport(): void{
        let ids = [];
        this.shipments.forEach(row => {
            ids.push((row as Shipment).shipmentId);
        });
        this.shipmentService.finalize(ids).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.ERROR_GLS_REGISTER, 10000, err);
            },
            next: value => {
                this.shipmentService.finalReportCalledEvent.next();
                this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.GLS_FINALIZE_SUCCESS);
                let date = new Date();
                this.downLoadFile(value.body,'application/pdf', `tagesabschluss-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.pdf`);
                this.router.navigate(['/shipments'],{state: {data: []}});
                this.dialogRef.close();
                setTimeout(() => {
                    this.shipmentService.tableReloadEvent.next();
                }, 1000);
            },
        });
    }

    private downLoadFile(data: any, type: string, name: string): void{
        let blob = new Blob([data], { type: 'application/octet-stream'});
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.setAttribute('type','hidden');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

}
