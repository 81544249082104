import {Component, Input, OnInit} from '@angular/core';
import {FileTemplate} from "../../models/FileTemplate";
import {GetSettingResponse} from "../../responses/settings/GetSettingResponse";
import {NbDialogRef} from "@nebular/theme";
import {Setting} from "../../models/Settings.model";
import {SettingsService} from "../../services/settings/settings.service";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-template-edit',
    templateUrl: './template-edit.component.html',
    styleUrls: ['./template-edit.component.scss'],
})
export class TemplateEditComponent implements OnInit {

    @Input()
    public template: FileTemplate;

    @Input()
    public isAdd: boolean = false;

    public selected = [];
    public available = [];
    public available2 = [];
    public availableImport = [
        {label: 'Name Empfänger', value:'receiverNameIndex', required: true},
        {label: 'Nachname Empfänger', value:'receiverLastNameIndex', required: false},
        {label: 'Namenszusatz Empfänger', value:'receiverAdditionalNameIndex', required: false},
        {label: 'Straße inkl./ exkl. Hr. Empfänger', value:'receiverStreetIndex', required: true},
        {label: 'Hausnummer Empfänger', value:'receiverHouseNumberIndex', required: false},
        {label: 'Adresse 2 Empfänger', value:'receiverAddressLine2Index', required: false},
        {label: 'PLZ Empfänger', value:'receiverZipIndex', required: true},
        {label: 'Ort Empfänger', value:'receiverCityIndex', required: true},
        {label: 'Land Empfänger', value:'receiverCountryIndex', required: true},
        {label: 'E-Mail Empfänger', value:'receiverEmailIndex', required: false},
        {label: 'Telefon Empfänger', value:'receiverPhoneIndex', required: false},
        {label: 'Kontakt Empfänger', value:'receiverAdditionalContactLine1Index', required: false},
        {label: 'Zusatz Empfänger', value:'receiverAdditionalContactLine2Index', required: false},
        {label: 'Gewicht', value:'packageWeightIndex', required: false},
        {label: 'Länge', value:'packageLengthIndex', required: false},
        {label: 'Breite', value:'packageWidthIndex', required: false},
        {label: 'Höhe', value:'packageHeightIndex', required: false},
    ];

    public availableExport = [
        {label: 'Name Empfänger', value:'receiverNameIndex', required: false},
        {label: 'Nachname Empfänger', value:'receiverLastNameIndex', required: false},
        {label: 'Namenszusatz Empfänger', value:'receiverAdditionalNameIndex', required: false},
        {label: 'Straße inkl./ exkl. Hr. Empfänger', value:'receiverStreetIndex', required: false},
        {label: 'Hausnummer Empfänger', value:'receiverHouseNumberIndex', required: false},
        {label: 'Adresse 2 Empfänger', value:'receiverAddressLine2Index', required: false},
        {label: 'PLZ Empfänger', value:'receiverZipIndex', required: false},
        {label: 'Ort Empfänger', value:'receiverCityIndex', required: false},
        {label: 'Land Empfänger', value:'receiverCountryIndex', required: false},
        {label: 'E-Mail Empfänger', value:'receiverEmailIndex', required: false},
        {label: 'Telefon Empfänger', value:'receiverPhoneIndex', required: false},
        {label: 'Kontakt Empfänger', value:'receiverAdditionalContactLine1Index', required: false},
        {label: 'Zusatz Empfänger', value:'receiverAdditionalContactLine2Index', required: false},
        {label: 'Gewicht', value:'packageWeightIndex', required: false},
        {label: 'Länge', value:'packageLengthIndex', required: false},
        {label: 'Breite', value:'packageWidthIndex', required: false},
        {label: 'Höhe', value:'packageHeightIndex', required: false},
        {label: 'TrackingID', value:'custom', required: false},
        {label: 'Sendungsnummer', value:'custom', required: false},
        {label: 'Versanddienstleister', value:'custom', required: false},
        {label: 'Versanddatum', value:'custom', required: false},
    ];

    public templateName: string;
    public templateType: string;
    public fileNameRegex: string;
    public fileHasHeadline: boolean = false;
    public oneFileExport: boolean = false;
    public prevType = '';
    public default = false;
    private defaultSetting: Setting;
    private originalType = '';


    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private settingsService: SettingsService
    ) { }

    ngOnInit(): void {
        this.available = Object.assign([],this.availableImport);
        if(this.isAdd){
            this.template = new FileTemplate();
            this.setUptAvailable2();
        }else{
            if(this.template.templateId != undefined){
                this.getSetting();
            }
            this.available = this.reset(this.template.templateType);
            this.fillSelected();
            this.prevType = this.template.templateType;
            this.originalType = this.template.templateType;
        }
    }

    private createSetting(): void{
        let setting: Setting = new Setting();
        setting.key = this.settingsService.KEY_DEFAULT_TEMPLATE_ID;
        this.settingsService.createSetting(setting).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.SETTINGS_CREATE_FAILED, 5000, err);
            },
            next: () => {
                this.getSetting();
            },
        });
    }

    private getSetting(): void{
        this.settingsService.getSetting(this.settingsService.KEY_DEFAULT_TEMPLATE_ID).toPromise()
            .catch(err => {
                if(err.error != undefined && err.error.errors != undefined && err.error.errors.length > 0 && err.error.errors[0].includes('0023')){
                    this.createSetting();
                }
            })
            .then((res: GetSettingResponse) => {
                this.defaultSetting = res.body;
                this.default = res.body.value == this.template.templateId;
            });
    }

    public typeChange(): void{
        if(this.template.templateType != this.prevType){
            this.available = this.reset(this.template.templateType);
            this.setUptAvailable2();
        }
        this.prevType = this.template.templateType;
    }

    public fillSelected(): void {
        this.selected = [];
        this.available.forEach(x => {
            if(this.template[x.value] != -1){
                this.selected[this.template[x.value]] = x;
            }
        });
        Object.keys(this.template.additionalKeys).forEach(x => {
            if(this.template.additionalKeys[x] != undefined){
                this.selected[this.template.additionalKeys[x]] = {label: x, value: 'custom', required: false};
            }
        });
        this.selected.forEach(x => {
            if(this.available.find(y => y.label == x.label) != undefined){
                this.available.splice(this.available.indexOf(this.available.find(y => y.label == x.label)), 1);
            }
        });
        let i = 0;
        this.selected.forEach(x => {
            if(this.selected.indexOf(x) > i){
                i = this.selected.indexOf(x);
            }
        });
        for(let j = 0; j <= i; j++){
            if(this.selected[j] == undefined){
                this.selected[j] = {label:'Leerspalte', value: 'empty', required: false};
            }
        }
        this.setUptAvailable2();
    }

    public save(): void{
        if(this.validate()){
            this.clearTemplate();
            this.template.additionalKeysMap = new Map<string, number>();
            for(let i = 0; i < this.selected.length; i++){
                if(this.selected[i].value == 'custom'){
                    this.template.additionalKeysMap.set(this.selected[i].label, this.selected.indexOf(this.selected[i]));
                }else if(this.selected[i].value != 'empty' && this.selected[i].value != 'custom'){
                    this.template[this.selected[i].value] = this.selected.indexOf(this.selected[i]);
                }
            }
            this.dialogRef.close({
                template: this.template,
                save: true,
            });
            if(this.default){
                this.defaultSetting.value = this.template.templateId;
                this.settingsService.updateSetting(this.settingsService.KEY_DEFAULT_TEMPLATE_ID, this.defaultSetting).subscribe({
                    error: err => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger,this.toastService.SETTINGS_UPDATE_FAILED,0,err);
                    },
                });
            }
        }
    }

    private clearTemplate(): void{
        this.template.receiverNameIndex = -1;
        this.template.receiverLastNameIndex = -1;
        this.template.receiverAdditionalNameIndex = -1;
        this.template.receiverStreetIndex = -1;
        this.template.receiverHouseNumberIndex = -1;
        this.template.receiverCityIndex = -1;
        this.template.receiverZipIndex = -1;
        this.template.receiverCountryIndex = -1;
        this.template.receiverEmailIndex = -1;
        this.template.receiverPhoneIndex = -1;
        this.template.packageWeightIndex = -1;
        this.template.packageLengthIndex = -1;
        this.template.packageWidthIndex = -1;
        this.template.packageHeightIndex = -1;
    }

    public validate(): boolean {
        if(this.template.templateType == undefined || this.template.templateType == ''){
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.DRAG_MISSING_TYPE, 10000);
            return false;
        }
        if(this.template.templateName == undefined || this.template.templateName == ''){
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.DRAG_MISSING_NAME, 10000);
            return false;
        }
        if(this.template.separatorCharacter == undefined || this.template.separatorCharacter == ''){
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.DRAG_MISSING_SEPARATOR, 10000);
            return false;
        }
        for(let i = 0; i < this.available.length; i++){
            if(this.available[i].required){
                this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.DRAG_MISSING_MANDATORY, 10000);
                return false;
            }
        }
        return true;
    }

    public closeDialogue(): void {
        this.template.templateType = this.originalType;
        this.dialogRef.close({
            template: {},
            save: false,
        });
    }

    public setUptAvailable2(): void{
        let newAvailable: {label: string, value: string, required: boolean}[] = [];
        let itemCount = Math.round(this.available.length / 2);
        for(let i = 0; i < itemCount; i++){
            newAvailable.push(this.available[i]);
        }
        this.available2 = [];
        for(let i = itemCount; i < this.available.length; i++){
            this.available2.push(this.available[i]);
        }
        this.available = newAvailable;
    }

    private reset(type: string): {label: string, value: string, required: boolean}[]{
        let aval: {label: string, value: string, required: boolean}[];
        let labelsToRemove = [];
        if(type == 'export'){
            aval = Object.assign([],this.availableExport);
        }else{
            aval = Object.assign([],this.availableImport);
        }
        for(let i = 0; i < this.selected.length; i++){
            if(aval.find(x => x.label == this.selected[i].label) != undefined){
                this.selected[i] = aval.find(x => x.label == this.selected[i].label);
            }else{
                if(type != 'export' && this.availableExport.find(x => x.label == this.selected[i].label) != undefined){
                    labelsToRemove.push(this.selected[i].label);
                }
            }
        }
        labelsToRemove.forEach(label => {
            if(this.selected.find(y => y.label == label) != undefined){
                this.selected.splice(this.selected.indexOf(this.selected.find(y => y.label == label)) ,1);
            }
        });
        this.selected.forEach(x => {
            if(aval.find(y => y.label == x.label != undefined)){
                aval.splice(aval.indexOf(aval.find(y => y.label == x.label)), 1);
            }
        });
        let needsEmpty = true;
        aval.forEach(x => {
            if(x.value == 'empty'){
                needsEmpty = false;
            }
        });
        if(needsEmpty){
            aval.push({label:'Leerspalte', value: 'empty', required: false});
        }
        return aval;
    }

    public fullClear(): void{
        this.selected = [];
        this.available = this.reset(this.template.templateType);
        this.setUptAvailable2();
    }

}
