<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">Amazon Import</h2>
        </div>
        <div class="modalBody">
            <p>Bitte stellen Sie sicher, dass Sie in Ihrem Amazon SellerCentral Account eingeloggt sind.</p>
            <ol>
                <li>Mr. Shipment – Klicken Sie „Importieren"</li>
                <li>W&auml;hlen Sie jetzt „Amazon Import“</li>
                <li>Klicken Sie „Zu Amazon Seller Central“</li>
                <li>Jetzt Bestellbericht der offenen Bestellungen herunterladen</li>
                <li>Klicken Sie in Mr.Shipment Datei hochladen und w&auml;hlen Sie den Bestellbericht aus. Alternativ ziehen Sie die von SellerCentral heruntergeladene Datei in den Uploadbereich von Mr.Shipment.</li>
                <li>Sie finden nun alle Bestellungen im Bereich „Importierte Auftr&auml;ge“</li>
                <li>In der Spalte „Typ“ k&ouml;nnen Sie z.B. nach allen Amazonauftr&auml;gen filtern.</li>
            </ol>
            <div class="row">
                <div class="col-6 d-flex align-items-center justify-content-center">
                    <a nbButton [href]="url" target="_blank" class="amazonLink">Zu Amazon Seller Central</a>
                </div>
                <div class="col-6">
                    <ngx-file-drop dropZoneLabel="{{(selectedFiles != undefined && selectedFiles.length > 0) ? selectedFiles.length + ' Datei(en) ausgewählt.' : 'Datei hier her ziehen oder Datei durch Klick hochladen'}}"
                                   (onFileDrop)="dropped($event)" (click)="fileUploadClick()" style="cursor:pointer;">
                    </ngx-file-drop>
                    <input id="fileOnClickUpload" multiple="multiple" type="file" style="display: none;" (change)="fileInputChange($event)">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-8 offset-2 d-flex justify-content-center">
                    <button nbButton [disabled]="selectedFiles == undefined || selectedFiles.length < 1" (click)="doImport()">Importieren</button>
                </div>
            </div>
        </div>
    </div>
</div>
