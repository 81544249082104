export class Contact {
    public contactId: string;
    public nameLine1: string;
    public nameLine2: string;
    public addressLine: string;
    public addressLine2: string;
    public postal: string;
    public city: string;
    public country: string;
    public email: string;
    public phone: string;
    public createdAt: any;
    public updatedAt: any;
    public active: boolean;
    public additionalContactInformation1: string;
    public additionalContactInformation2: string;

    constructor() {
        this.nameLine1 = '';
        this.nameLine2 = '';
        this.addressLine = '';
        this.addressLine2 = '';
        this.postal = '';
        this.city = '';
        this.country = '';
        this.email = '';
        this.phone = '';
        this.additionalContactInformation1 = '';
        this.additionalContactInformation2 = '';
    }
}