import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {APP_BASE_HREF} from "@angular/common";
import { AuthGuardService } from './guards/auth/auth-guard.service';
import { NgModule } from '@angular/core';

export const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth-routing.module')
            .then(m => m.AuthRoutingModule),
    },
    {
        path: '',
        loadChildren: () => import('./pages/pages.module')
            .then(m => m.PagesModule),
        canActivate: [AuthGuardService],

    },
    { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
    useHash: false,
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
    providers: [{provide: APP_BASE_HREF, useValue: window['base-url']}],
})
export class AppRoutingModule {
}
