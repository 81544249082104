import {Component, Input, OnInit} from '@angular/core';
import {FileSystemFileEntry} from "ngx-file-drop";
import {NbDialogRef} from "@nebular/theme";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-upload-popup',
    templateUrl: './upload-popup.component.html',
    styleUrls: ['./upload-popup.component.scss'],
})
export class UploadPopupComponent implements OnInit {

    @Input() public multi: boolean = false;
    @Input() public accept: string = '*';
    public files: File[];

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
        this.files = [];
    }

    public dropped(event: any): void{
        let data = [];
        let wrongFileNames = [];
        this.files = [];
        event.forEach(drop => {
            data.push(drop.fileEntry);
        });
        if(this.multi){
            let count = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].isFile) {
                    const fileEntry = data[i] as FileSystemFileEntry;
                    fileEntry.file((f: File) => {
                        if(f.type.match(this.accept) != null && f.type.match(this.accept) != undefined) {
                            this.files.push(f);
                        }else{
                            wrongFileNames.push(f.name);
                        }
                        count++;
                        if(count >= data.length){
                            if(wrongFileNames.length > 0){
                                let errorMessage = `(Unterstützes Dateiformat: ${this.accept}) \n Folgende Dateien passen nicht zu diesem Format: `;
                                for(let j = 0; j < wrongFileNames.length; j++){
                                    errorMessage += `${wrongFileNames[j]},  `;
                                }
                                // tslint:disable-next-line:ter-max-len
                                this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.UPLOAD_WRONG_FILE_FORMAT_MULTIPLE_FILES, 20000, errorMessage);
                            }
                        }
                    });
                }
            }
        }else{
            if (data[0].isFile) {
                const fileEntry = data[0] as FileSystemFileEntry;
                fileEntry.file((f: File) => {
                    if(f.type.match(this.accept) != null && f.type.match(this.accept) != undefined){
                        this.files.push(f);
                    }else{
                        // tslint:disable-next-line:ter-max-len
                        this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.UPLOAD_WRONG_FILE_FORMAT_SINGLE_FILE, 20000, this.accept);
                    }
                });
            }
        }
    }

    public fileUploadClick(): void{
        document.getElementById('fileOnClickUpload').click();
    }

    public fileInputChange(event: any): void{
        let data: FileList = event.target.files;
        let wrongFileNames = [];
        this.files = [];
        for (let i = 0; i < data.length; i++) {
            if (data.item(i) != undefined) {
                if(data.item(i).type.match(this.accept) != null && data.item(i).type.match(this.accept) != undefined) {
                    this.files.push(data.item(i));
                }else{
                    wrongFileNames.push(data.item(i).name);
                }
            }
        }
        if(wrongFileNames.length > 0){
            let errorMessage = `(Unterstützes Dateiformat: ${this.accept}) \n Folgende Dateien passen nicht zu diesem Format: `;
            for(let j = 0; j < wrongFileNames.length; j++){
                errorMessage += `${wrongFileNames[j]},  `;
            }
            // tslint:disable-next-line:ter-max-len
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.UPLOAD_WRONG_FILE_FORMAT_MULTIPLE_FILES, 20000, errorMessage);
        }
    }

    public upload(): void{
        this.dialogRef.close({files: this.files});
    }

    public closeDialogue(): void{
        this.dialogRef.close();
    }
}
