<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">Achtung!</h2>
        </div>
        <div class="modalBody">
            <p *ngIf="type == missingCriticalType">Ein oder einige Pakete befinden sich in einem Status, der m&ouml;glicherweise Handlungsbedarf erfordert.</p>
            <p *ngIf="type == serviceCriticalType">Ein oder einige Pakete sind bereits mehr als 5 Tage unterwegs und der Status hat sich nicht ver&auml;ndert.</p>
            <p *ngIf="type == shopCriticalType">Es befinden sich ein oder mehrere Pakete im Paketshop.<br>Bitte beachten Sie, dass einige Pakete bereits l&auml;nger als 5 Tage nicht abgeholt wurden.<br>In den n&auml;chsten 2 Tagen werden diese Pakete an den Absender zur&uuml;ckgeschickt, sollten sie vom Empf&auml;nger nicht abgeholt werden.</p>
            <p *ngIf="type == missingCriticalType || type == serviceCriticalType"><b>Bitte &uuml;berpr&uuml;fen Sie folgende Pakete:</b></p>
            <p *ngIf="type == shopCriticalType"><b>Pakete im Paketshop:</b></p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Sendungsnummer</th>
                        <th>Empf&auml;nger</th>
                        <th>Importiert aus</th>
                        <th>Sendungsverfolgung abbrechen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let shipment of shipments; let i = index">
                        <td><u style="cursor: pointer" (click)="navToShipmenTableWithParcelnumber(shipment)">{{shipment.parcelNumber}}</u></td>
                        <td>{{shipment.receiverNameLine1 != undefined && shipment.receiverNameLine1 != '' ? shipment.receiverNameLine1 : shipment.receiverNameLine2}}</td>
                        <td>{{getTypeName(shipment.shipmentType)}}</td>
                        <td><button nbButton (click)="manuallyAbortTracking(shipment.shipmentId, i)" [disabled]="abortedList[''+i] != undefined == abortedList[''+i]">Sendungsverfolgung abbrechen</button></td>
                    </tr>
                </tbody>
            </table>
            <span><b>Handlungsempfehlung:</b> Kontaktieren Sie den Empf&auml;nger, damit das Paket im Paketshop abgeholt wird.</span>
            <button nbButton (click)="navToShipmenTable(type)" style="margin-top: 10px;">Alle Pakete mit dem Status "{{type == missingCriticalType ? 'Klärungsbedarf' : (type == serviceCriticalType ? 'Unterwegs' : 'im Paketshop' )}}" aufrufen</button>
        </div>
    </div>
</div>
