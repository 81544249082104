import {NbComponentStatus, NbGlobalPhysicalPosition, NbToastrConfig, NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    config: NbToastrConfig;

    public TOAST_STATUS = {
        primary: 0,
        success: 1,
        info: 2,
        warning: 3,
        danger: 4,
    };
    private MESSAGE_PLACEHOLDER = '%str_replace%';
    public SUPPORT_REQUEST_FAILED = {
        title: 'Supportanfrage fehlgeschlagen!',
        body: `Beim Senden der Supportanfrage ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SUPPORT_REQUEST_SUCCESS = {
        title: 'Supportanfrage erfolgreich!',
        body: `Ihre Supportanfrage wurde erfolgreich übermittelt.`,
    };
    public LOGIN_FAILED = {
        title: 'Login fehlgeschlagen!',
        body: `Es ist ein Fehler aufgetreten, überprüfen Sie Ihre Eingabe und versuchen Sie es erneut!\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public LOGIN_MISSING = {
        title: 'Fehlerhafte Eingabe!',
        body: 'Bitte füllen Sie alle Eingabefelder aus und versuchen Sie es erneut!',
    };
    public ADDRESS_LOAD_ERROR = {
        title: 'Fehler beim Laden der Adresse!',
        body: `Die von Ihnen ausgewählte Adresse konnte nicht geladen werden. Schließen Sie das Import-Fenster und wählen Sie die Adresse neu aus.`,
    };
    public FAILED_TO_LOAD_AMAZON_URL = {
        title: 'Fehler beim Laden der Amazon-URL!',
        body: `Die für die Weiterleitung notwendige Amazon-URL konnte nicht geladen werden.`,
    };
    public AMAZON_URL_REDIRECT_INC = {
        title: 'Die Datei wurde erfolgreich heruntergeladen!',
        body: `Sie werden in wenigen Sekunden zu Amazon weitergeleitet.`,
    };
    public FIRST_LOGIN_ACCEPT_ALL = {
        title: 'Fehlende Eingabe!',
        body: 'Bitte stimmen Sie sowohl unseren AGBs als auch unserer Datenschutzerklärung zu, um fortzufahren.',
    };
    public FIRST_LOGIN_ACCEPT_ALL_FAILED = {
        title: 'Fehler beim Speichern Ihrer Angaben!',
        body: `Beim Speichern Ihrer Angaben ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SAVE_SUCCESS = {
        title: 'Speichern erfolgreich!',
        body: 'Ihre Daten wurden erfolgreich gespeichert.',
    };
    public SAVE_FAILED = {
        title: 'Speichern fehlgeschlagen!',
        body: `Ihre Daten konnten leider nicht gespeichert werden.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public API_SHOP_SAVE_FAILED_NO_PLUGIN_AUTH_KEY = {
        title: 'Speichern fehlgeschlagen!',
        body: `Für diesen Shop-Typen wird ein Plugin-Schlüssel benötigt.`,
    };
    public LOADING_DATA_FAILED = {
        title: 'Fehler beim Laden der Daten!',
        body: `Beim Laden der Daten ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public LOADING_TRACKING_DATA_FAILED = {
        title: 'Fehler beim Laden der Tracking-Daten!',
        body: `Beim Laden der Tracking-Daten ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public LOADING_TRACKING_PARCELSHOP_DATA_FAILED = {
        title: 'Fehler beim Laden der Paketshop-Daten!',
        body: `Beim Laden der Paketshop-Daten von GLS ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPLOAD_WRONG_FILE_FORMAT_MULTIPLE_FILES = {
        title: 'Falsches Dateiformat.',
        body: `Bitte laden Sie hier nur Dateien mit passendem Format hoch. ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPLOAD_WRONG_FILE_FORMAT_SINGLE_FILE = {
        title: 'Falsches Dateiformat.',
        body: `Bitte laden Sie hier nur Dateien mit passendem Format hoch. (Unterstütze Formate: ${this.MESSAGE_PLACEHOLDER})`,
    };
    public UPLOAD_COMPANY_LOGO_FILE_TOO_BIG = {
        title: 'Datei zu groß.',
        body: `Bitte laden Sie hier nur Dateien hoch, die nicht größer als 4 MB sind.`,
    };
    public MISSING_MANDATORY_FIELD = {
        title: 'Fehlerhafte Eingabe!',
        body: `Bitte füllen Sie alle Pflichtfelder aus und versuchen Sie es erneut! (Fehlendes Feld: ${this.MESSAGE_PLACEHOLDER})`,
    };
    public MISSING_ANY_MANDATORY_FIELD = {
        title: 'Fehlerhafte Eingabe!',
        body: `Bitte füllen Sie alle Pflichtfelder aus und versuchen Sie es erneut!`,
    };
    public MISSING_HOUSENUMBER = {
        title: 'Fehlerhafte Eingabe!',
        body: `Bitte überprüfen Sie, ob eine Hausnummer bei der Adresseingabe angegeben wurde.`,
    };
    public MISSING_ANY_MANDATORY_FIELD_AND_NAME_OR_COMPANY = {
        title: 'Fehlerhafte Eingabe!',
        body: `Bitte füllen Sie alle Pflichtfelder und Namen oder Firma aus und versuchen Sie es erneut!`,
    };
    public STREET_AND_HOUSENUMBER_TOO_LONG = {
        title: 'Fehlerhafte Eingabe!',
        body: `Straße und Hausnummer dürfen gemeinsam (inkl. Leerzeichen zwischen den Werten) nicht mehr als 40 Zeichen lang sein.`,
    };
    public COPIED_TO_CLIPBOARD = {
        title: 'Kopieren erfolgreich!',
        body: `Der Inhalt wurde in die Zwischenablage kopiert.`,
    };
    public CREATE_USER_FAILED = {
        title: 'Fehler beim Anlegen des Nutzers!',
        body: `Beim Anlegen des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPDATE_USER_FAILED = {
        title: 'Fehler beim Ändern des Nutzers!',
        body: `Beim Ändern des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public DELETE_USER_FAILED = {
        title: 'Fehler beim Löschen des Nutzers!',
        body: `Beim Löschen des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public DISABLE_USER_FAILED = {
        title: 'Fehler beim Deaktivieren des Nutzers!',
        body: `Beim Deaktivieren des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATE_USER_SUCCESS = {
        title: 'Nutzer erfolgreich angelegt!',
        body: `Der Nutzer wurde erfolgreich angelegt.`,
    };
    public UPDATE_USER_SUCCESS = {
        title: 'Nutzer erfolgreich geändert!',
        body: `Der Nutzer wurde erfolgreich geändert.`,
    };
    public DELETE_USER_SUCCESS = {
        title: 'Nutzer erfolgreich gelöscht!',
        body: `Der Nutzer wurde erfolgreich gelöscht.`,
    };
    public DISABLE_USER_SUCCESS = {
        title: 'Nutzer erfolgreich deaktiviert!',
        body: `Der Nutzer wurde erfolgreich deaktiviert und hat keinen Zugriff mehr auf Ihr System.`,
    };
    public CREATE_CONTACT_SUCCESS = {
        title: 'Kontakt erfolgreich angelegt!',
        body: `Der Kontakt wurde erfolgreich angelegt.`,
    };
    public UPDATE_CONTACT_SUCCESS = {
        title: 'Kontakt erfolgreich geändert!',
        body: `Der Kontakt wurde erfolgreich geändert.`,
    };
    public DELETE_CONTACT_SUCCESS = {
        title: 'Kontakt erfolgreich gelöscht!',
        body: `Der Kontakt wurde erfolgreich gelöscht.`,
    };
    public CREATE_CONTACT_FAILED = {
        title: 'Fehler beim Anlegen des Kontaktes!',
        body: `Beim Anlegen des Kontaktes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPDATE_CONTACT_FAILED = {
        title: 'Fehler beim Ändern des Kontaktes!',
        body: `Beim Ändern des Kontaktes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public DELETE_CONTACT_FAILED = {
        title: 'Fehler beim Löschen des Kontaktes!',
        body: `Beim Löschen des Kontaktes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public INSUFFICIENT_PASSWORD_STRENGTH = {
        title: 'Passwort unsicher!',
        body: `Das Password muss aus mindestens 6 Zeichen bestehen und folgendes beinhalten: Großbuchstabe, Kleinbuchstabe, Ziffer, Sonderzeichen.`,
    };
    public SETTINGS_LOAD_FAILED = {
        title: 'Fehler beim laden der Einstellungen',
        body: `Beim laden der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public WEATHER_NEED_CITY_OR_ZIP = {
        title: 'Unzureichende Angabe',
        body: `Tragen Sie bitte eine PLZ oder Stadt ein.`,
    };
    public WEATHER_NEED_CITY = {
        title: 'Unzureichende Angabe',
        body: `Tragen Sie bitte eine Stadt ein.`,
    };
    public SETTINGS_CREATE_FAILED = {
        title: 'Fehler beim erstellen der Einstellung',
        body: `Beim erstellen der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_DUPLICATED_KEY = {
        title: 'Fehler beim erstellen der Einstellung',
        body: 'Der eingetragene Schlüssel ist bereits vorhanden!',
    };
    public SETTINGS_DELETE_FAILED = {
        title: 'Fehler beim löschen der Einstellung',
        body: `Beim löschen der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_UPDATE_FAILED = {
        title: 'Fehler beim ändern der Einstellung',
        body: `Beim ändern der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_DELETE_SUCCESS = {
        title: 'Einstellung erfolgreich gelöscht',
        body: `Die Einstellung wurde erfolgreich gelöscht.`,
    };
    public SETTINGS_UPDATE_SUCCESS = {
        title: 'Einstellung erfolgreich geändert',
        body: `Die Einstellung wurde erfolgreich geändert.`,
    };
    public SETTINGS_CREATE_SUCCESS = {
        title: 'Einstellung erfolgreich erstellt',
        body: `Die Einstellung wurde erfolgreich erstellt.`,
    };
    public SHIPMENT_LOAD_FAILED = {
        title: 'Fehler beim laden der Lieferungen',
        body: `Beim laden der Lieferungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SHIPMENT_LOAD_COUNTRIES_FAILED = {
        title: 'Fehler beim laden der Ländergruppen',
        body: `Beim laden der Ländergruppen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SHIPMENT_CREATE_FAILED = {
        title: 'Fehler beim erstellen der Lieferung',
        body: `Beim laden der Lieferung ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SHIPMENT_DUPLICATE_FAILED = {
        title: 'Fehler beim Duplizieren der Lieferung',
        body: `Beim Duplizieren der Lieferung ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SHIPMENT_DELETE_FAILED = {
        title: 'Fehler beim löschen der Lieferung',
        body: `Beim laden der Lieferung ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SHIPMENT_UPDATE_FAILED = {
        title: 'Fehler beim ändern der Lieferungen',
        body: `Beim ändern der Lieferung ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SHIPMENT_CREATE_SUCCESS = {
        title: 'Lieferung erfolgreich erstellt',
        body: `Die Lieferung wurde erfolgreich erstellt.`,
    };
    public SHIPMENT_DUPLICATE_SUCCESS = {
        title: 'Duplizieren erfolgreich!',
        body: `Sie finden die neuen Sendungen in dem Bereich 'Importierte Aufträge'.`,
    };
    public SHIPMENT_DELETE_SUCCESS = {
        title: 'Lieferung erfolgreich gelöscht',
        body: `Die Lieferung wurde erfolgreich gelöscht.`,
    };
    public SHIPMENT_GIRTH_EXCEEDED_300 = {
        title: 'Gurtmaß überschritten!',
        body: `Dieses Paket überschreitet das zulässige Gurtmaß von 300cm.`,
    };
    public SHIPMENT_GIRTH_EXCEEDED_500 = {
        title: 'Gurtmaß überschritten!',
        body: `Dieses Paket überschreitet das zulässige Gurtmaß von 500cm.`,
    };
    public SHIPMENT_WEIGHT_EXCEEDED = {
        title: 'Paket zu schwer!',
        body: `Eines Ihrer Pakete überschreitet das Maximal-Gewicht von 40kg.`,
    };
    public SHIPMENT_STEPPER_CATEGORY_NOT_FOUND = {
        title: 'Kategorie nicht gefunden!',
        body: `Beim Auflisten der ausgewählten Kategorien ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.`,
    };
    public SHIPMENT_STEPPER_NUMBER_ONLY = {
        title: 'Fehlerhafte Eingabe!',
        body: `Tragen Sie in dieser Tabelle nur Zahlen ein.`,
    };
    public SHIPMENT_UPDATE_SUCCESS = {
        title: 'Lieferung erfolgreich geändert',
        body: `Die Lieferung wurde erfolgreich geändert.`,
    };
    public PARCEL_CATEGORY_LOAD_FAILED = {
        title: 'Fehler beim laden der Paketkategorien',
        body: `Beim laden der Paketkategorien ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public PARCEL_CATEGORY_UPDATE_SUCCESS = {
        title: 'Paketkategorie erfolgreich geändert',
        body: `Die Paketkategorie wurde erfolgreich geändert.`,
    };
    public PARCEL_CATEGORY_UPDATE_FAILED = {
        title: 'Fehler beim ändern der Paketkategorie',
        body: `Beim ändern der Paketkategorie ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_READING_SHIPMENT_DATA = {
        title: 'Fehler beim laden der Daten!',
        body: `Die Daten der gewählten Lieferung konnten nicht geladen werden. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_CREATING_LABEL = {
        title: 'Fehler beim Erstellen des Labels!',
        body: `Beim Erstellen des Labels ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_CREATING_RETURN_LABEL = {
        title: 'Fehler beim Erstellen des Retoure-Labels!',
        body: `Beim Erstellen des Retoure-Labels ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_CREATING_PDF = {
        title: 'Fehler beim Erstellen der PDF!',
        body: `Beim Erstellen der PDF ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATING_PDF_SUCCESSFUL = {
        title: 'PDF erfolgreich erstellt!',
        body: `Die PDF wurde erfolgreich generiert.`,
    };
    public  ERROR_CREATING_RETURN_LABEL_INVALID_COUNTRY = {
        title: 'Fehlerhafte Auswahl!',
        // tslint:disable-next-line:ter-max-len
        body: `Für ein oder mehr ausgewählte Länder kann kein Retoure-Label erstellt werden. Folgende Sendungsnummern sind betroffen: \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_LABEL_BATCH_CANCEL = {
        title: 'Fehler beim Stornieren der Lieferungen!',
        body: `Beim Stornieren der Lieferungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SUCCESS_LABEL_BATCH_CANCEL = {
        title: 'Lieferungen erfolgreich storniert!',
        body: `${this.MESSAGE_PLACEHOLDER} Ihrer ausgewählten Lieferungen wurden storniert.`,
    };
    public WARN_LABEL_BATCH_CANCEL = {
        title: 'Lieferung konnte nicht storniert werden!',
        body: `${this.MESSAGE_PLACEHOLDER} Ihrer ausgewählten Lieferungen konnten aufgrund ihres Status nicht storniert werden.`,
    };
    public CATEGORIZE_FAILED = {
        title: 'Fehler bei der Kategorisierung!',
        body: `Das Kategorisieren des Paketes ist fehlgeschlagen. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_GLS_REGISTER = {
        title: 'Fehler bei der Kommunikation!',
        body: `Das Anmelden ist fehlgeschlagen. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public GLS_REGISTER_SUCCESS = {
        title: 'Erfolg!',
        body: `Es wurde ein Label für das Paket erstellt.`,
    };
    public GLS_SEND_SUCCESS = {
        title: 'Erfolg!',
        body: `Die Versand-Anmeldung war erfolgreich.`,
    };
    public GLS_CATEGORIZE_SUCCESS = {
        title: 'Erfolg!',
        body: `Kategorisieren erfolgreich.`,
    };
    public GLS_FINALIZE_SUCCESS = {
        title: 'Erfolg!',
        body: `Abschluss erfolgreich.`,
    };
    public DRAG_NEW_ENTRY_DUPLICATE = {
        title: 'Duplikat gefunden!',
        body: `Dieser Eintrag existiert bereits.`,
    };
    public DRAG_MISSING_MANDATORY = {
        title: 'Fehlende Angaben!',
        body: `Bitte weisen Sie alle Pflichtfelder zu.`,
    };
    public DRAG_MISSING_NAME = {
        title: 'Fehlende Angaben!',
        body: `Bitte tragen Sie einen Namen ein.`,
    };
    public DRAG_MISSING_TYPE = {
        title: 'Fehlende Angaben!',
        body: `Bitte wählen Sie einen Typen aus.`,
    };
    public DRAG_MISSING_SEPARATOR = {
        title: 'Fehlende Angaben!',
        body: `Bitte tragen Sie ein Trennzeichen ein.`,
    };
    public API_LOAD_FAILED = {
        title: 'Anfrage fehlegeschlagen!',
        body: `Beim Laden der Schnittstellen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public API_CHECK_FAILED = {
        title: 'Anfrage fehlegeschlagen!',
        body: `Die Prüfung einer Schnittstelle ist fehlgeschlagen. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public API_DELETE_FAILED = {
        title: 'Das Aufheben der Verknüpfung ist fehlgeschlagen!',
        body: `Die Verknüpfung konnte nicht aufgehoben werden. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public API_DELETE_SUCCESS = {
        title: 'Das Aufheben der Verknüpfung war erfolgreich!',
        body: `Die Verknüpfung wurde erfolgreich aufgehoben.`,
    };
    public API_REGISTER_FAILED = {
        title: 'Das Anlegen der Verknüpfung ist fehlgeschlagen!',
        body: `Die Verknüpfung konnte nicht angelegt werden. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public API_REGISTER_SUCCESS = {
        title: 'Verknüpfung angelegt!',
        body: `Die Verknüpfung wurde erfolgreich angelegt.`,
    };
    public API_TEMPLATE_LOAD_FAILED = {
        title: 'Laden der Templates fehlgeschlagen!',
        body: `Die Templates konnten nicht geladen werden. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public API_TEMPLATE_SAVE_FAILED = {
        title: 'Speichern des Templates fehlgeschlagen!',
        body: `Das Template konnten nicht gespeichert werden. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public API_TEMPLATE_SAVE_SUCCESS = {
        title: 'Speichern erfolgreich!',
        body: `Das Template wurde gespeichert`,
    };
    public API_TEMPLATE_NONE_CREATED = {
        title: 'Fehlendes Template!',
        body: `Es wurde kein Template angelegt. Bitte legen Sie zuerst ein Template an.`,
    };
    public FILE_IMPORT_SUCCESS = {
        title: 'Import erfolgreich!',
        body: `${this.MESSAGE_PLACEHOLDER}`,
    };
    public FILE_IMPORT_FAIL = {
        title: 'Fehlerhafte Zeile!',
        body: `Folgender Fehler trat bei dem Import einer Zeile auf: \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public FILE_EXPORT_FAIL = {
        title: 'Export fehlgeschlagen!',
        body: `Beim Exportieren der Zeilen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public TABLE_NONE_SELECTED = {
        title: 'Fehlerhafte Auswahl!',
        body: `Es wurden keine Pakete ausgewählt! \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_GLS_SEND = {
        title: 'Fehler bei der Kommunikation!',
        body: `Beim Versenden des Versandauftrages ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public ERROR_LOADING_STATISTICS = {
        title: 'Fehler beim Erstellen der Statistiken!',
        body: `Beim Lader der Statistiken ist ein unerwarteter Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public PASSWORDS_DONT_MATCH = {
        title: 'Die eingegebenen Passwörter stimmen nicht überein!',
        body: 'Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut!',
    };
    public ERROR_ON_MANUALLY_ABRORTING_TRACKING = {
        title: 'Das Abbrechen der manuellen Sendungsverfolgung war nicht erfolgreich!',
        body: 'Bitte versuchen Sie es erneut!',
    };
    public SUCCESS_ON_MANUALLY_ABRORTING_TRACKING = {
        title: 'Das Abbrechen der manuellen Sendungsverfolgung war erfolgreich!',
        body: 'Die Sendungsverfolgung wurde erfolgreich abgebrochen.',
    };
    public CHECK_POSTAL_INFORMATION = {
        title: `Es wurde von uns kein Ort mit der eingegebenen Postleitzahl gefunden!`,
        body: `Bitte überprüfen Sie Ihre Eingabe.`,
    };
    public CHECK_CITY_INFORMATION = {
        title: `Es wurde von uns kein Ort namens "${this.MESSAGE_PLACEHOLDER}" mit der von Ihnen eingegebenen PLZ gefunden!`,
        body: `Bitte überprüfen Sie Ihre Eingabe.`,
    };
    public RETOURE_LABEL_CREATE_FAILED = {
        title: 'Fehler beim erstellen des Retoure-Labels',
        body: `Beim erstellen des Retoure-Labels ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SENDING_DATA_FAILED = {
        title: 'Übertragung fehlgeschlagen!',
        body: `Beim Übertragen des Daten ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public WOOCOMMERCE_SENDING_DATA_SUCCESS = {
        title: 'Übertragung erfolgreich!',
        body: `Die Daten dieser Sendung wurden erneut an das hinterlegte WooCommerce System versendet.`,
    };

    private types: NbComponentStatus[] = [
        'primary',
        'success',
        'info',
        'warning',
        'danger',
    ];
    private defaultSuccessDuration = 5000;
    private hasIcon = true;
    private position = NbGlobalPhysicalPosition.TOP_RIGHT;
    private preventDuplicates = false;

    constructor(
        private toastrService: NbToastrService
    ) { }

    public showToast(type: number, message: any, durationInMs: number = -1, additionalInfo: any = '') {
        if(durationInMs === -1){
            if(type === 1){
                durationInMs = this.defaultSuccessDuration;
            }else{
                durationInMs = 0;
            }
        }
        const config = {
            status: this.types[type],
            destroyByClick: true,
            duration: durationInMs,
            hasIcon: this.hasIcon,
            position: this.position,
            preventDuplicates: this.preventDuplicates,
        };

        if(additionalInfo instanceof HttpErrorResponse){
            if(additionalInfo.error != undefined && additionalInfo.error.errors != undefined && additionalInfo.error.errors[0]){
                additionalInfo = additionalInfo.error.errors[0];
            }else{
                additionalInfo = '';
            }
        }

        const titleContent = message.title ? `${message.title.replace(this.MESSAGE_PLACEHOLDER, additionalInfo)}` : '';
        const bodyContent = message.body ? `${message.body.replace(this.MESSAGE_PLACEHOLDER,additionalInfo)}` : '';

        this.toastrService.show(
            bodyContent,
            titleContent,
            config);
    }

}
