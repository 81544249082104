import * as Routes from "../Routes";
import {DataService} from "../data/data.service";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Response} from "../../responses/Response";

@Injectable({
    providedIn: 'root',
})
export class WeatherService{

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }


    public getWeatherInfo(): Observable<any> {
        return this.http.get<Response>(`${Routes.WEATHER_ROUTE}/`, this.dataService.getHttpOptions());
    }
}