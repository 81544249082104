<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-12" style="display:flex;">
                <h2 style="display: inline-block">Anordnung</h2>
                <div *ngIf="!firstVertical" class="writingModeSwitch">
                    <nb-checkbox id="tbmode-active" [(ngModel)]="topBottomMode"></nb-checkbox>
                    <label for="tbmode-active" class="label col-sm-9 col-form-label">Platzsparende Anzeige <span style="font-size: 9px">(Empfohlen bei langen Spaltennamen / hoher Spaltenanzahl)</span></label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p><b>So funktioniert's: Um ein verf&uuml;gbares Feld in Ihr Template zu integrieren, ziehen Sie das Feld in die Anordnung. Um es wieder zu entfernen, ziehen Sie es zur&uuml;ck. Sie haben auch die M&ouml;glichkeit ein eigenes Feld hinzuzuf&uuml;gen. Geben Sie dem "Neuem Feld" den gew&uuml;nschten Namen und f&uuml;gen Sie es zur Anordnung hinzu.</b></p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div
                        cdkDropList
                        cdkDropListOrientation="{{firstVertical ? 'vertical':'horizontal'}}"
                        #todoList="cdkDropList"
                        [cdkDropListData]="selected"
                        [cdkDropListConnectedTo]="[doneList,doneList2]"
                        class="dropList {{firstVertical ? 'vertical':'horizontal'}}"
                        (cdkDropListDropped)="drop($event)">
                    <div class="dropItem {{item.value}} {{topBottomMode ? 'topBottomMode' : ''}}" *ngFor="let item of selected" cdkDrag>{{item.label}}<span class="required" *ngIf="item.required">*</span></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12 col-md-4">
        <div class="row">
            <div class="col-12">
                <h2>Verf&uuml;gbar</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 flex-component">
                <input nbInput type="text" placeholder="Neues Element" [(ngModel)]="addValue">
                <button nbButton (click)="addItem()">Hinzuf&uuml;gen</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span style="font-size: 10px; font-weight: bold"><span class="required">*</span> Pflichtspalten</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div
                        cdkDropList
                        cdkDropListOrientation="{{secondVertical ? 'vertical':'horizontal'}}"
                        #doneList="cdkDropList"
                        [cdkDropListData]="available"
                        [cdkDropListConnectedTo]="[todoList]"
                        class="dropList {{secondVertical ? 'vertical':'horizontal'}}"
                        (cdkDropListDropped)="drop($event)">
                    <div class="dropItem" [ngClass]="{'infiniteEmpty' : item == INFINITE_EMPTY_VALUE}" *ngFor="let item of available" cdkDrag>{{item.label}}<span class="required" *ngIf="item.required">*</span></div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-4">
        <div class="row transparent">
            <div class="col-12">
                <h2>Verf&uuml;gbar</h2>
            </div>
        </div>
        <div class="row transparent">
            <div class="col-12 flex-component">
                <input nbInput type="text" placeholder="Neues Element" [(ngModel)]="addValue">
                <button nbButton (click)="addItem()">Hinzuf&uuml;gen</button>
            </div>
        </div>
        <div class="row transparent">
            <div class="col-12">
                <span style="font-size: 10px; font-weight: bold"><span class="required">*</span> Pflichtspalten</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div
                        cdkDropList
                        cdkDropListOrientation="{{secondVertical ? 'vertical':'horizontal'}}"
                        #doneList2="cdkDropList"
                        [cdkDropListData]="available2"
                        [cdkDropListConnectedTo]="[todoList]"
                        class="dropList {{secondVertical ? 'vertical':'horizontal'}}"
                        (cdkDropListDropped)="drop($event)">
                    <div class="dropItem" [ngClass]="{'infiniteEmpty' : item == INFINITE_EMPTY_VALUE}" *ngFor="let item of available2" cdkDrag>{{item.label}}<span class="required" *ngIf="item.required">*</span></div>
                </div>
            </div>
        </div>
    </div>
</div>
