import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {Setting} from "../../models/Settings.model";
import {SettingsService} from "../../services/settings/settings.service";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-weather-config',
    templateUrl: './weather-config.component.html',
    styleUrls: ['./weather-config.component.scss'],
})
export class WeatherConfigComponent implements OnInit {

    public weatherData = {city: '', plz: '', country: ''};

    private needToCreateSetting = false;


    constructor(
        protected dialogRef: NbDialogRef<any>,
        private settingsService: SettingsService,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.needToCreateSetting = false;
        this.loadWeatherData();
    }

    private loadWeatherData(): void{
        this.settingsService.getSetting(this.settingsService.KEY_WEATHER_DATA).subscribe({
            error: err => {
                if(err.error.errors[0].includes("(0023)")){
                    this.needToCreateSetting = true;
                    // this.createSetting();
                }else{
                    this.toastService.showToast(this.toastService.TOAST_STATUS.warning,this.toastService.SETTINGS_LOAD_FAILED,10000,err);
                }
            },
            next: value => {
                this.weatherData = JSON.parse(value.body.value);
            },
        });
    }

    private createSetting(): void{
        let set: Setting = new Setting();
        set.value = JSON.stringify(this.weatherData);
        set.key= this.settingsService.KEY_WEATHER_DATA;
        set.category = "MANUAL";
        this.settingsService.createSetting(set).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: value => {
                this.close();
            },
        });
    }

    private updateSetting(): void{

        let set: Setting = new Setting();
        set.value = JSON.stringify(this.weatherData);
        set.key= this.settingsService.KEY_WEATHER_DATA;
        set.category = "MANUAL";
        this.settingsService.updateSetting(set.key, set).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: value => {
                this.close();
            },
        });
    }

    public close(): void{

        this.dialogRef.close();
    }

    public save(): void{
        if(this.weatherData.city == '' && this.weatherData.plz == ''){
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning,this.toastService.WEATHER_NEED_CITY_OR_ZIP,10000);
            return;
        }
        if(this.weatherData.city == '' && this.weatherData.country != ''){
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning,this.toastService.WEATHER_NEED_CITY,10000);
            return;
        }
        if(this.needToCreateSetting){
            this.needToCreateSetting = false;
            this.createSetting();
        }
        this.updateSetting();
    }

}
