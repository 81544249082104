<div class="content">
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h2 *ngIf="isAdd">Neues Template</h2>
            <h2 *ngIf="!isAdd">Template bearbeiten</h2>
        </div>
    </div>
    <div class="row topRow">
        <div class="col-12 col-sm-3">
            <label for="templateName">Name</label>
            <input nbInput id="templateName" type="text" [(ngModel)]="template.templateName">
        </div>
        <div class="col-12 col-sm-3">
            <label for="templateType">Typ</label>
            <nb-select id="templateType" [(selected)]="template.templateType" (selectedChange)="typeChange()">
                <nb-option [value]="'import'">Import</nb-option>
                <nb-option [value]="'export'">Export</nb-option>
            </nb-select>
        </div>
        <div class="col-12 col-sm-3">
            <label for="separatorCharacter">Trennzeichen</label>
            <input nbInput id="separatorCharacter" type="text" [(ngModel)]="template.separatorCharacter">
        </div>
        <div class="col-12 col-sm-3" *ngIf="template.templateType != undefined && template.templateType == 'import'">
            <label for="fileEncoding">Datei Encoding</label>
            <nb-select id="fileEncoding" [(selected)]="template.fileEncoding" (selectedChange)="typeChange()">
                <nb-option [value]="'UTF-8'">UTF-8</nb-option>
                <nb-option [value]="'Cp1252'">Cp1252</nb-option>
            </nb-select>
        </div>
    </div>
    <div class="row topRow">
        <div class="col-12 col-sm-3" *ngIf="template.templateType != undefined && template.templateType == 'export'">
            <label for="exportFileName">Export Dateiname</label>
            <input nbInput id="exportFileName" type="text" [(ngModel)]="template.fileNameRegex">
        </div>
        <div class="col-12 col-sm-3" *ngIf="template.templateType != undefined && template.templateType == 'export'">
            <label for="dateFormat">Format (Versanddatum)</label>
            <input nbInput id="dateFormat" type="text" [(ngModel)]="template.dateFormat" placeholder="dd-MM-yyyy">
        </div>
        <div class="col-12 col-sm-3" *ngIf="template.templateType != undefined && template.templateType == 'export'">
            <label for="fileLineBreak">Zeilenumbruch</label>
            <nb-select id="fileLineBreak" [(selected)]="template.fileLineBreak" (selectedChange)="typeChange()">
                <nb-option [value]="'\\n'">Unix (LF)</nb-option>
                <nb-option [value]="'\\r\\n'">Windows (CR+LF)</nb-option>
                <nb-option [value]="'\\r'">Macintosh (CR)</nb-option>
            </nb-select>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 d-flex align-items-center">
            <nb-checkbox id="fileHasHeadline" [(ngModel)]="template.fileHasHeadline"></nb-checkbox>
            <label for="fileHasHeadline" class="label col-form-label mr-4 ml-2">Datei mit Kopfreihe</label>
            <nb-checkbox *ngIf="template.templateType == 'import'" class="ml-2" id="useAsDefault" [(ngModel)]="default"></nb-checkbox>
            <label *ngIf="template.templateType == 'import'" for="useAsDefault" class="label col-form-label mr-4 ml-2">Als Standard f&uuml;r Import</label>

            <nb-checkbox id="oneFileExport" [(ngModel)]="template.oneFileExport"  *ngIf="template.templateType != undefined && template.templateType == 'export'"></nb-checkbox>
            <label for="oneFileExport" class="label col-form-label ml-2"  *ngIf="template.templateType != undefined && template.templateType == 'export'">Export in einer Datei</label>
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-end align-items-end">
            <button nbButton status="danger" class="mr-3" (click)="fullClear()">Neu beginnen</button>
            <button nbButton (click)="save()">Speichern</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ngx-drag-n-drop [selected]="selected" [available]="available" [available2]="available2" [firstVertical]="false" [secondVertical]="true"></ngx-drag-n-drop>
        </div>
    </div>
</div>
