import { Component, Input } from '@angular/core';
import {NbDialogService} from "@nebular/theme";
import {ShipmentService} from "../../services/shipments/shipment.service";
import {TrackingModalComponent} from "../tracking-modal/tracking-modal.component";
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `
        <a href="#" (click)="openTracking($event)">{{value}}</a>
  `,
})
export class TrackingLinkTableColumnComponent implements ViewCell {
    @Input() value: number;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object

    constructor(private modalController: NbDialogService, private shipmentService: ShipmentService) {
    }

    public openTracking(event: any): void{
        event.preventDefault();
        const modal = this.modalController.open(TrackingModalComponent,
            {
                context: {shipment: this.rowData},
                closeOnBackdropClick  : false,
                autoFocus: false,
            });

        modal.onClose.subscribe({
            next: response => {
                if (response !== undefined && response.reload) {
                    setTimeout(() => {
                        this.shipmentService.tableReloadEvent.next();
                    }, 500);
                }
            },
        });
    }
}