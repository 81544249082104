import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    NbDialogService,
    NbMediaBreakpointsService,
    NbMenuService,
    NbSidebarService,
    NbThemeService
} from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';

import {FinalReportWarnComponent} from "../../../components/info-popups/final-report-warn/final-report-warn.component";
import {GenericResponse} from "../../../responses/GenericResponse";
import { LayoutService } from '../../../@core/utils';
import {SettingsService} from "../../../services/settings/settings.service";
import {Shipment} from "../../../models/Shipment.model";
import {ShipmentService} from "../../../services/shipments/shipment.service";
import { Subject } from 'rxjs';
import { UserData } from '../../../@core/data/users';
import {WeatherConfigComponent} from "../../../components/weather-config/weather-config.component";
import {WeatherService} from "../../../services/weather/weather.service";

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    public MANUAL_URL = 'https://mrshipment.de/bedienungs-assistent/';
    public PACKING_INFO_URL = 'https://mrshipment.de/wp-content/uploads/nb-gut-anweisung.pdf';

    public demo: boolean = false;

    private destroy$: Subject<void> = new Subject<void>();
    public loadingWeatherFailed = false;
    userPictureOnly: boolean = false;
    user: any;
    public loadingWeather: boolean = false;

    public weatherData: any;
    public weatherIcon: string;
    public temperature: string;
    public warnShipments: Shipment[] = [];

    themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
        {
            value: 'cosmic',
            name: 'Cosmic',
        },
        {
            value: 'corporate',
            name: 'Corporate',
        },
    ];

    currentTheme = 'default';

    userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

    constructor(private sidebarService: NbSidebarService,
                private menuService: NbMenuService,
                private themeService: NbThemeService,
                private userService: UserData,
                private settingsService: SettingsService,
                private layoutService: LayoutService,
                private modalController: NbDialogService,
                private breakpointService: NbMediaBreakpointsService,
                private weatherService: WeatherService,
                private shipmentService: ShipmentService) {
    }

    ngOnInit() {
        this.warnShipments = [];
        this.settingsService.getSetting("status.demo").subscribe(x => x.body.value.toLocaleLowerCase() == "true" ? this.demo = true : this.demo = false);

        this.currentTheme = this.themeService.currentTheme;

        this.userService.getUsers()
            .pipe(takeUntil(this.destroy$))
            .subscribe((users: any) => this.user = users.nick);

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$)
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$)
            )
            .subscribe(themeName => this.currentTheme = themeName);
        this.loadingWeather = false;

        this.shipmentService.finalReportCalledEvent.subscribe(() => {
            this.loadReportRemainingData();
        });
        this.loadWeatherData();
        this.loadReportRemainingData();
        setInterval(() => {
            this.loadWeatherData();
            this.loadReportRemainingData();
        }, 300000);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }

    public openWeatherSettings(): void{

        const modal = this.modalController.open(WeatherConfigComponent);
        modal.onClose.subscribe(() => this.loadWeatherData());
    }


    private loadWeatherData(): void{
        this.loadingWeather = true;
        this.weatherService.getWeatherInfo().subscribe({
            error: err => {
                // console.log(err);
                this.loadingWeatherFailed = true;
            },
            next: value => {
                if(value.body == 'empty'){
                    this.loadingWeatherFailed = true;
                }else{

                    this.weatherData = JSON.parse(value.body);
                    if (this.weatherData != undefined && this.weatherData.weather != undefined
                        && this.weatherData.weather.length > 0 && this.weatherData.weather[0] != undefined
                        && this.weatherData.weather[0].icon != undefined) {
                        this.weatherIcon = this.weatherData.weather[0].icon;
                    }
                    if (this.weatherData != undefined && this.weatherData.main != undefined
                        && this.weatherData.main.temp != undefined) {
                        this.temperature = this.weatherData.main.temp + '';
                    }
                    this.loadingWeatherFailed = false;

                }
            },
            complete: () => {
                this.loadingWeather = false;
            },
        });
    }

    public openManual(): void{
        let link = document.createElement("a");
        link.href = this.MANUAL_URL;
        link.target = '_blank';
        link.click();
        link.remove();
    }

    public openPackingInfo(): void{
        let link = document.createElement("a");
        link.href = this.PACKING_INFO_URL;
        link.target = '_blank';
        link.click();
        link.remove();
    }

    private loadReportRemainingData(): void{
        this.shipmentService.getAlreadyShippedButNotShippedState().subscribe((res: GenericResponse<Shipment[]>) => {
            this.warnShipments = res.body;
        });
    }

    public openFinalReportWarn(): void{
        this.modalController.open(
            FinalReportWarnComponent,
            {
                closeOnBackdropClick  : false,
                autoFocus: false,
                context:{
                    shipments: this.warnShipments,
                },
            });
    }

    public isChristmasHustle(): boolean {
        let now : Date = new Date();
        return now.getMonth() === 11 && now.getFullYear() > 2024;
    }
}
