<div class="content">
    <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
        </div>
    </div>
    <div class="row headerRow">
        <div class="col-sm-12">
            <h5>Sendung bearbeiten</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mandatoryCol">
            <span class="mandatory">*</span> Pflichtfeld
        </div>
    </div>
    
    <div class="form-group row" *ngIf="senderAddresses != undefined && senderAddresses.length > 0 && addressSetupDone">
        <div class="col-12">
            <label class="label col-form-label" for="autocompleteAddresses">Absender</label>
            <nb-select id="autocompleteAddresses" [placeholder]="addressPlaceholder" [(selected)]="selectedAddressIndex" fullWidth (selectedChange)="onSenderChange($event)">
                <nb-option *ngFor="let option of senderAddresses; let i = index" [value]="i">
                    {{ option.firstName !== undefined && option.firstName !== null ? option.firstName : '' }} {{ option.lastName !== undefined && option.lastName !== null ? option.lastName : '' }}, {{option.street}} {{option.housenumber}}, {{option.zip}} {{option.city}}
                </nb-option>
            </nb-select>
        </div>
    </div>

    <div class="row headerRow"></div>
    <div class="row form-group">
        <label class="label">Empf&auml;nger</label>
    </div>

    <div class="row form-group">
        <label class="col-sm-2 label" for="name">Name <span class="mandatory">*</span></label>
        <div class="col-sm-4">
            <input id="name" nbInput fullWidth placeholder="Name" maxlength="40" [(ngModel)]="shipment.receiverNameLine1" [ngClass]="{'invalid': shipment.receiverNameLine1 != undefined && shipment.receiverNameLine1.length > 40}">
        </div>
        <label class="col-sm-2 label" for="firm">Firma</label>
        <div class="col-sm-4">
            <input id="firm" nbInput fullWidth placeholder="Firma" maxlength="40" [(ngModel)]="shipment.receiverNameLine2" [ngClass]="{'invalid': shipment.receiverNameLine2 != undefined && shipment.receiverNameLine2.length > 40}">
        </div>
    </div>
    <div class="row form-group">
        <label class="col-sm-2 label" for="address">Adresse <span class="mandatory">*</span></label>
        <div class="col-sm-4">
            <input id="address" nbInput fullWidth placeholder="Adresse" maxlength="40" [(ngModel)]="shipment.receiverAddressLine1" [ngClass]="{'invalid': shipment.receiverAddressLine1 != undefined && shipment.receiverAddressLine1.length > 40}">
        </div>
        <label class="col-sm-2 label" for="receiverAddressLine2">Adresse 2</label>
        <div class="col-sm-4">
            <input id="receiverAddressLine2" nbInput fullWidth placeholder="Adresse 2" maxlength="40" [(ngModel)]="shipment.receiverAddressLine2" [ngClass]="{'invalid': shipment.receiverAddressLine2 != undefined && shipment.receiverAddressLine2.length > 40}">
        </div>
    </div>
    <div class="row form-group">
        <label class="col-sm-2 label" for="zip">PLZ <span class="mandatory">*</span></label>
        <div class="col-sm-4">
            <input id="zip" nbInput fullWidth placeholder="PLZ" maxlength="10" [(ngModel)]="shipment.receiverZip" [ngClass]="{'invalid': shipment.receiverZip != undefined && shipment.receiverZip.length > 10}">
        </div>
        <label class="col-sm-2 label" for="city">Stadt <span class="mandatory">*</span></label>
        <div class="col-sm-4">
            <input id="city" nbInput fullWidth placeholder="Stadt" maxlength="40" [(ngModel)]="shipment.receiverCity" [ngClass]="{'invalid': shipment.receiverCity != undefined && shipment.receiverCity.length > 40}">
        </div>
    </div>

    <div class="row form-group">
        <label class="col-sm-2 label" for="country">Land</label>
        <div class="col-sm-4">
            <input id="country" nbInput disabled fullWidth [value]="shipment.receiverCountry">
        </div>
        <div class="col-sm-6"></div>
    </div>

    <div class="form-group row">
        <label class="col-sm-2 label" for="receiverEmail">Email</label>
        <div class="col-sm-4">
            <input id="receiverEmail" name="receiverEmail" maxlength="40" placeholder="Email" nbInput fullWidth [(ngModel)]="shipment.receiverEmail" [pattern]="emailPattern">
        </div>
        <label class="col-sm-2 label" for="receiverPhone">Telefon</label>
        <div class="col-sm-4">
            <input id="receiverPhone" name="receiverPhone" maxlength="40" placeholder="Telefon" nbInput fullWidth [(ngModel)]="shipment.receiverPhone">
        </div>
    </div>

    <div class="form-group row">
        <label class="col-sm-2 label" for="additionalContactInformation1">Kontakt</label>
        <div class="col-sm-4">
            <input id="additionalContactInformation1" name="additionalContactInformation1" maxlength="40" placeholder="Kontakt" nbInput fullWidth [(ngModel)]="shipment.additionalContactInformation1" >
        </div>
        <label class="col-sm-2 label" for="additionalContactInformation2">Zusatz</label>
        <div class="col-sm-4">
            <input id="additionalContactInformation2" name="additionalContactInformation2" maxlength="40" placeholder="Zusatz" nbInput fullWidth [(ngModel)]="shipment.additionalContactInformation2">
        </div>
    </div>

    <div class="row headerRow"></div>
    <div class="row form-group">
        <label class="label">Paket & Versand</label>
    </div>

    <div class="row form-group">
        <label class="col-sm-1 label" for="weight">Gewicht (kg)</label>
        <div class="col-sm-2">
            <input id="weight" nbInput fullWidth type="number" [(ngModel)]="shipment.weight">
        </div>
        <label class="col-sm-1 label" for="length" *ngIf="!weighCustomer">L&auml;nge (cm)</label>
        <div class="col-sm-2" *ngIf="!weighCustomer">
            <input id="length" nbInput fullWidth type="number" [(ngModel)]="shipment.length">
        </div>
        <label class="col-sm-1 label" for="width" *ngIf="!weighCustomer">Breite (cm)</label>
        <div class="col-sm-2" *ngIf="!weighCustomer">
            <input id="width" nbInput fullWidth type="number" [(ngModel)]="shipment.width">
        </div>
        <label class="col-sm-1 label" for="height" *ngIf="!weighCustomer">H&ouml;he (cm)</label>
        <div class="col-sm-2" *ngIf="!weighCustomer">
            <input id="height" nbInput fullWidth type="number" [(ngModel)]="shipment.height">
        </div>
    </div>
    <div class="row form-group">
        <label class="col-sm-1 label" for="type">Typ</label>
        <div class="col-sm-5">
            <input id="type" nbInput disabled fullWidth [value]="shipment.shipmentType">
        </div>
        <label class="col-sm-1 label" for="category" *ngIf="!weighCustomer">Kategorie</label>
        <div class="col-sm-5" *ngIf="!weighCustomer">
            <input id="category" nbInput disabled fullWidth [value]="shipment.categoryKey">
        </div>
    </div>
    <div class="row form-group">
        <label for="express" class="col-sm-2 label">Express</label>
        <div class="col-sm-4">
            <nb-checkbox id="express" [(ngModel)]="shipment.express" [disabled]="!expressChangeable()"></nb-checkbox>
        </div>
        <label for="express" class="col-sm-4 label">
            Flex Delivery Service (kostenlos) - Paketinfo per Mail an Empf&auml;nger
            <span class="infoTooltipBtn">i</span>
            <div class="tooltipInfo">
                <p><b>Flex Delivery Service (kostenlos) - Paketinfo per Mail an Empf&auml;nger</b></p>
                <p>Ihr Paketdienstleister GLS informiert Ihre Empf&auml;nger über das versendete Paket E-Mail.</p>

                <b>Vorteile für Sie als Absender:</b>
                <ul>
                    <li>Anzahl der R&uuml;ckfragen geringer</li>
                    <li>Reduzierter Kundenserviceaufwand</li>
                    <li>Erh&ouml;hung der Kundenzufriedenheit</li>
                    <li>Erh&ouml;hte Zustellquote</li>
                </ul>

                <b>Vorteile für den Empf&auml;nger:</b>
                <ul>
                    <li>Info über voraussichtliches Zustellzeitfenster</li>
                    <li>Mehr Flexibilit&auml;t bei der Paketzustellung</li>
                    <li>Zustellung zum Wunschtermin</li>
                    <li>Abstellerlaubnis erteilen</li>
                    <li>Zustellung im GLS Paketshop</li>
                </ul>
            </div>
        </label>
        <div class="col-sm-2">
            <nb-checkbox id="flexDelivery" [(ngModel)]="shipment.useFlexDelivery" [disabled]="shipment.express"></nb-checkbox>
        </div>
    </div>
    <div class="row"  *ngIf="!shipmentHasHousenumber()">
        <div class="col-12">
            <span style="color: red; font-size: 12px">Pr&uuml;fen Sie Ihre Eingabe auf eine fehlende Hausnummer.</span>
        </div>
    </div>
    <div class="form-group row btnRow">
        <div class="col-sm-12">
            <button nbButton fullWidth (click)="submit()" [disabled]="!shipmentHasHousenumber()">Speichern</button>
        </div>
    </div>
</div>
