
export class User {
    public active: boolean;
    public isAdmin: boolean;
    public admin: boolean;
    public city: string;
    public country: string;
    public createdAt: Date | any;
    public email: string;
    public firstName: string;
    public housenumber: string;
    public lastName: string;
    public password: string;
    public phone: string;
    public postal: string;
    public salt: string;
    public salutation: string;
    public street: string;
    public updatedAt: Date | any;
    public userId: string;
    public userName: string;
    public updatePassword?: string;

    constructor() {
        this.admin = false;
        this.active = true;
        this.city = '';
        this.country = '';
        this.email = '';
        this.firstName = '';
        this.housenumber = '';
        this.lastName = '';
        this.phone = '';
        this.postal = '';
        this.salutation = '';
        this.street = '';
        this.userName = '';
    }
}
