import { Address, SenderAddress } from 'app/models/Address';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NbDialogRef } from '@nebular/theme';
import {ParcelCategory} from "../../models/ParcelCategory";
import { SettingsService } from 'app/services/settings/settings.service';
import { Shipment } from 'app/models/Shipment.model';
import { ToastService } from 'app/services/toast/toast.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ngx-shipment-edit',
    templateUrl: './shipment-edit.component.html',
    styleUrls: ['./shipment-edit.component.scss'],
})
export class ShipmentEditComponent implements OnInit {

    @Input()
    public shipment: Shipment;

    @Input()
    public weighCustomer: boolean = false;

    public parcelCategories: ParcelCategory[];

    public senderAddresses: SenderAddress[] = [];
    public filteredOptionsAddresses: Observable<Address[]>;
    public addressSetupDone = false;
    public selectedAddressIndex: number;
    public addressPlaceholder: string = '';

    public emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private settingsService: SettingsService,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.senderAddresses = [];
        this.addressSetupDone = false;
        this.getSender();
        this.addressPlaceholder = `Aktuelle Adresse: ${ this.shipment.senderFirstName !== undefined && this.shipment.senderFirstName !== null ? this.shipment.senderFirstName : '' } ${ this.shipment.senderLastName !== undefined && this.shipment.senderLastName !== null ? this.shipment.senderLastName : '' }, ${this.shipment.senderStreet} ${this.shipment.senderHousenumber}, ${this.shipment.senderZip} ${this.shipment.senderCity}`;
    }

    public getSender(): void {
        this.settingsService.getSetting(this.settingsService.KEY_SENDER_ADDITIONAL_ADDRESSES).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: value => {
                this.senderAddresses = JSON.parse(value.body.value) as SenderAddress[];
                this.filteredOptionsAddresses = this.getFilteredOptionsAddresses('');
            },
            complete: () => {

                this.settingsService.getSetting(this.settingsService.KEY_SENDER_DATA).subscribe({
                    error: (err: HttpErrorResponse) => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                    },
                    next: response => {
                        if(this.senderAddresses == undefined){
                            this.senderAddresses = [];
                        }
                        let senderData = JSON.parse(response.body.value);
                        let newAddress = new SenderAddress();
                        newAddress.city = senderData.pickupAddress.city;
                        newAddress.country = senderData.pickupAddress.country;
                        newAddress.housenumber = senderData.pickupAddress.housenumber;
                        newAddress.firstName = senderData.pickupAddress.firstName;
                        newAddress.lastName = senderData.pickupAddress.lastName;
                        newAddress.street = senderData.pickupAddress.street;
                        newAddress.zip = senderData.pickupAddress.zip;
                        newAddress.glsContactId = senderData.GLSContactId;
                        this.senderAddresses.unshift(newAddress);
                        this.getSenderFromShipment();
                        this.addressSetupDone = true;
                    },
                });

            },
        });
    }

    private getSenderFromShipment(): void {
        let shipment = new Shipment();
        Object.assign(shipment, this.shipment);

        if (shipment.senderCity === null || shipment.senderCity === undefined) {
            shipment.senderCity = '';
        }
        if (shipment.senderContactId === null || shipment.senderContactId === undefined) {
            shipment.senderContactId = '';
        }
        if (shipment.senderCountry === null || shipment.senderCountry === undefined) {
            shipment.senderCountry = '';
        }
        if (shipment.senderFirstName === null || shipment.senderFirstName === undefined) {
            shipment.senderFirstName = '';
        }
        if (shipment.senderLastName === null || shipment.senderLastName === undefined) {
            shipment.senderLastName = '';
        }
        if (shipment.senderStreet === null || shipment.senderStreet === undefined) {
            shipment.senderStreet = '';
        }
        if (shipment.senderHousenumber === null || shipment.senderHousenumber === undefined) {
            shipment.senderHousenumber = '';
        }
        if (shipment.senderZip === null || shipment.senderZip === undefined) {
            shipment.senderZip = '';
        }

        for (let i = 0; i < this.senderAddresses.length; i++) {
            if (
                this.senderAddresses[i].city == shipment.senderCity &&
                this.senderAddresses[i].glsContactId == shipment.senderContactId &&
                this.senderAddresses[i].country == shipment.senderCountry &&
                this.senderAddresses[i].firstName == shipment.senderFirstName &&
                this.senderAddresses[i].lastName == shipment.senderLastName &&
                this.senderAddresses[i].street == shipment.senderStreet &&
                this.senderAddresses[i].housenumber == shipment.senderHousenumber &&
                this.senderAddresses[i].zip == shipment.senderZip
            ) {
                this.selectedAddressIndex = i;
                return;
            }
        }
    }

    public onSenderChange(event: any): void {
        this.shipment.senderCity = this.senderAddresses[event].city;
        this.shipment.senderContactId = this.senderAddresses[event].glsContactId;
        this.shipment.senderCountry = this.senderAddresses[event].country;
        this.shipment.senderFirstName = this.senderAddresses[event].firstName;
        this.shipment.senderLastName = this.senderAddresses[event].lastName;
        this.shipment.senderStreet = this.senderAddresses[event].street;
        this.shipment.senderHousenumber = this.senderAddresses[event].housenumber;
        this.shipment.senderZip = this.senderAddresses[event].zip;
    }

    private getFilteredOptionsAddresses(value: string): Observable<Address[]> {
        return of(value).pipe(
            map(filterString => this.filterAddresses(filterString))
        );
    }

    private filterAddresses(value: string): Address[] {
        const filterString = value.toLowerCase();

        return this.senderAddresses.filter(x => {
            if ((x.firstName != null && x.firstName.toLocaleLowerCase().includes(filterString))
                || (x.lastName != null && x.lastName.toLocaleLowerCase().includes(filterString))
                || (x.street != null && x.street.toLocaleLowerCase().includes(filterString))
                || (x.housenumber != null && x.housenumber.toLocaleLowerCase().includes(filterString))
                || (x.zip != null && x.zip.toLocaleLowerCase().includes(filterString))
                || (x.city != null && x.city.toLocaleLowerCase().includes(filterString))
            ) {
                return true;
            }
        });
    }

    public submit(): void {
        this.dialogRef.close({
            shipment: this.shipment,
            save: true,
        });
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    public shipmentHasHousenumber(): boolean{
        if (this.shipment.shipmentState == 'NEW_UNCATEGORIZED') return true;
        return /^(.*[0-9].*)$/.test(this.shipment.receiverAddressLine1);
    }

    public expressChangeable(): boolean {

        if (Number.isNaN(Number(this.shipment.weight))
            || Number.isNaN(Number(this.shipment.height))
            || Number.isNaN(Number(this.shipment.width))
            || Number.isNaN(Number(this.shipment.length))
        ) {
            return false;
        }

        const girth = this.calculateGirth(Number(this.shipment.length),Number(this.shipment.width),Number(this.shipment.height));
        if (this.shipment.receiverCountry !== 'DE') {
            return false;
        }

        if(this.shipment.express && girth < 300){
            return true;
        }
        return !this.shipment.express;


    }


    private calculateGirth(length: number, width: number, height: number): number{

        if(width >= length && width >= height){
            return width + 2 * height + 2 * length;
        }

        if(height >= length && height >= width){
            return height + 2 * width + 2 * length;
        }

        return length + 2 * width + 2 * height;
    }
}
