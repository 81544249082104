<div class="content">
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="row mt-3 mb-3">
                <div class="col-12">
                    <ngx-file-drop dropZoneLabel="{{files.length > 0 ? files.length + ' Dateien ausgewählt.' : 'Dateien hier her ziehen oder Datei durch Klick hochladen'}}"
                                   (onFileDrop)="dropped($event)" (click)="fileUploadClick()" style="cursor:pointer;">
                    </ngx-file-drop>
                    <input id="fileOnClickUpload" [accept]="accept" [multiple]="multi" type="file" style="display: none;" (change)="fileInputChange($event)">
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <button nbButton (click)="upload()" [disabled]="files.length < 1">Hochladen</button>
                </div>
            </div>
        </div>
    </div>
</div>