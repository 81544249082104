import { CanActivate } from '@angular/router';
import { GetUserResponse } from 'app/responses/user/GetUserResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AdminPermissionsGuardService implements CanActivate{

    constructor(
        private userService: UserService
    ) { }


    public canActivate(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.userService.getUser().subscribe({
                error: (err: HttpErrorResponse) => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    observer.next(false);
                },
                next: (response: GetUserResponse) => {
                    if (response.status === 200) {
                        if (response.body.admin) {
                            observer.next(true);
                        } else {
                            observer.next(false);
                        }
                    }
                },
                complete: () => {
                    observer.complete();
                },
            });
        });
    }

}
