import {Component, Input, OnInit} from '@angular/core';
import {AmazonService} from "../../services/amazon/amazon.service";
import {FileSystemFileEntry} from "ngx-file-drop";
import {GenericResponse} from "../../responses/GenericResponse";
import {NbDialogRef} from "@nebular/theme";
import {SenderAddress} from "../../models/Address";
import {SpinnerService} from "../../services/spinner/spinner.service";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-amazon-import',
    templateUrl: './amazon-import.component.html',
    styleUrls: ['./amazon-import.component.scss'],
})
export class AmazonImportComponent implements OnInit {

    @Input() public address: SenderAddress;
    public selectedFiles: File[];
    public url: string;
    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private amazonService: AmazonService,
        private spinnerService: SpinnerService
        ) {}

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
        this.amazonService.getImportUrl().subscribe({
            error: err => {
                this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.FAILED_TO_LOAD_AMAZON_URL, 15000);
            },
            next: (res: GenericResponse<string>) => {
                this.url = res.body;
            },
        });
    }

    public fileUploadClick(): void{
        document.getElementById('fileOnClickUpload').click();
    }

    public fileInputChange(event: any): void{
        let data: FileList = event.target.files;
        this.selectedFiles = [];
        for (let i = 0; i < data.length; i++) {
            if (data.item(i) != undefined) {
                this.selectedFiles.push(data.item(i));
            }
        }
    }

    public dropped(event: any): void{
        let data = [];
        this.selectedFiles = [];
        event.forEach(drop => {
            data.push(drop.fileEntry);
        });
        let count = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].isFile) {
                const fileEntry = data[i] as FileSystemFileEntry;
                fileEntry.file((f: File) => {
                    this.selectedFiles.push(f);
                    count++;
                });
            }
        }
    }

    public doImport(): void{
        if(this.address == undefined){
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.ADDRESS_LOAD_ERROR);
            return;
        }
        let counter = 0;

        this.spinnerService.open();
        this.selectedFiles.forEach(file => {
            this.amazonService.importFile(this.address, file).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                },
                next: (value: GenericResponse<string[]>) => {
                    let count = 0;
                    let errors = [];
                    for(let i = 0; i < value.body.length; i++){
                        if(value.body[i].length > 0){
                            errors.push(file.name + ': ' + value.body[i]);
                        }else{
                            count++;
                        }
                    }
                    errors.forEach(x => {
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.FILE_IMPORT_FAIL, 0, x);
                    });
                    if(count > 0){
                        const MESSSAGE = `Es wurden ${count} Lieferungen aus ${file.name} importiert.`;
                        this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.FILE_IMPORT_SUCCESS, 10000, MESSSAGE);
                    }
                },
                complete: () => {
                    counter++;
                    if(counter >= this.selectedFiles.length){
                        this.spinnerService.close();
                        this.dialogRef.close({
                            type: 'type--amazon',
                        });
                    }
                },
            });
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

}
