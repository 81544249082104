<div class="infoModalContent">
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline mt-4 mb-4"><b>Mr. Shipment Software</b></h2>
        </div>
        <div class="modalBody">
            <div class="row mb-3">
                <div class="col-12 d-flex align-items-center">
                    <nb-checkbox id="agbCheck" [(ngModel)]="agbOk"></nb-checkbox>
                    <label for="agbCheck" class="label mb-0">Ich bin mit Ihren <a href="https://mrshipment.de/wp-content/uploads/Allgemeine-Geschaeftsbedingungen-Mr-Shipment-Gipfelfreunde-UG.pdf" target="_blank">Allgemeinen Gesch&auml;ftsbedingungen</a> einverstanden. <span class="required">*</span></label>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 d-flex align-items-center">
                    <nb-checkbox id="securityCheck" [(ngModel)]="securityOk"></nb-checkbox>
                    <label for="securityCheck" class="label mb-0">Ihre <a href="https://mrshipment.de/datenschutzerklaerung/" target="_blank">Datenschutzerkl&auml;rung</a> habe ich zur Kenntnis genommen. <span class="required">*</span></label>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12"><span class="required">*</span><label class="label"> Pflichtfelder</label></div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-end">
                    <button nbButton (click)="submit()">Absenden</button>
                </div>
            </div>
        </div>
    </div>
</div>
