import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ngx-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {

    // tslint:disable-next-line:no-empty
    constructor() { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void {
    }

}
