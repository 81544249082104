<div class="infoModalContent" style="border-color: white;">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader" *ngIf="isEdit">
            <h2 class="modalHeadline text-center">Hier k&ouml;nnen Sie einen Marktplatz bearbeiten.</h2>
            <p class="text-center">Hierf&uuml;r ben&ouml;tigen Sie die Shop-URL sowie den API-Schl&uuml;ssel und das API-Secret.</p>
        </div>
        <div class="modalHeader" *ngIf="!isEdit">
            <h2 class="modalHeadline text-center">Hier k&ouml;nnen Sie einen Marktplatz hinzuf&uuml;gen.</h2>
            <p class="text-center">Hierf&uuml;r ben&ouml;tigen Sie die Shop-URL sowie den API-Schl&uuml;ssel und das API-Secret.</p>
        </div>
        <div class="modalBody">
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="type">Typ</label>
                <nb-select class="flex-grow-1" style="max-width: none" id="type" [(selected)]="market.marketplaceType" [disabled]="isEdit">
                    <nb-option *ngFor="let type of types" [value]="type.key">{{type.name}}</nb-option>
                </nb-select>
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="Name">Name</label>
                <input id="Name" nbInput fullWidth type="text" placeholder="Name" [(ngModel)]="market.marketplaceName">
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="url">API-URL</label>
                <input id="url" nbInput fullWidth type="text" placeholder="API-URL" [(ngModel)]="market.marketplaceUrl">
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="key">API-Schl&uuml;ssel</label>
                <input id="key" nbInput fullWidth type="text" placeholder="API-Schlüssel" [(ngModel)]="market.marketplaceConnectionKey">
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <label class="col-sm-2 label col-form-label" for="secret">API-Secret</label>
                <input id="secret" nbInput fullWidth type="text" placeholder="API-Secret" [(ngModel)]="market.marketplaceConnectionSecret">
            </div>
            <div class="justify-content-center d-flex">
                <button nbButton (click)="save()">Speichern</button>
                <button nbButton *ngIf="isEdit" (click)="delete()" status="danger" class="ml-2">Verbindung Aufheben</button>
            </div>
        </div>
    </div>
</div>
