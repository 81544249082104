<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">"Label erzeugen" einzelner Pakete und "Label erzeugen" in der Mehrfachauswahl:</h2>
        </div>
        <div class="modalBody">
            <p>1. In Ihrer "versandbereit Tabelle" k&ouml;nnen Sie Ihre zu versendenden Paket gerne nochmal bearbeiten.</p>
            <p>2. Sie haben Sie entschieden, dass eines oder mehrere Pakete nun verschickt werden k&ouml;nnen. Mit dem jetzt "Label erzeugen"- Truck k&ouml;nnen Sie direkt das gew&uuml;nschte Label zu dem Paket erstellen. In Ihrer Mehrfachauswahl haben Sie neben der "Auswahl l&ouml;schen" Funktion auch die M&ouml;glichkeit mehrere Labels gleichzeitig zu erstellen.</p>
            <p>Alle Sendungen haben nun Ihr eigenes Label und sind bereit zur Abholung.</p>
        </div>
        <br>
        <div class="modalHeader">
            <h2 class="modalHeadline">„Sendungen duplizieren“</h2>
        </div>
        <div class="modalBody">
            <p>&Uuml;ber die „Mehrfachauswahl“ haben Sie die M&ouml;glichkeit Sendungen zu duplizieren. Diese Funktion duplizieren erm&ouml;glicht Ihnen, exakt die gleichen Sendungsdaten einer Sendung zu duplizieren. Bitte beachten Sie, dass die „neu duplizierte“ Sendung immer in „importierte Auftr&auml;ge“ abgelegt wird. Dort haben Sie dann die M&ouml;glichkeit diese Sendung noch zu bearbeiten.</p>
        </div>
    </div>
</div>
