import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {ConfirmComponent} from "../confirm/confirm.component";
import {ShopType} from "../../models/ShopType.model";
import {ShopsService} from "../../services/shops/shops.service";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-woocommerce-popup',
    templateUrl: './woocommerce-popup.component.html',
    styleUrls: ['./woocommerce-popup.component.scss'],
})
export class WoocommercePopupComponent implements OnInit {

    @Input() apiKey: string = '';
    @Input() apiSecret: string = '';
    @Input() apiUrl: string = '';
    @Input() apiName: string = '';
    @Input() shopTypeKey: string;
    @Input() pluginKey: string = '';
    @Input() isEdit: boolean = false;

    public shopTypes: ShopType[];
    public shopType;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private modalController: NbDialogService,
        private shopService: ShopsService
    ) {}

    ngOnInit(): void {
        this.loadShopTypes();
    }

    private loadShopTypes(): void{
        this.shopTypes = [];
        this.shopService.getAllTypes().subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
            },
            next: value => {
                this.shopTypes = value.body as ShopType[];
                this.shopTypes.forEach((type: ShopType) => {
                    if(this.shopTypeKey == type.key){
                        this.shopType = type;
                    }
                });
            },
        });
    }

    public close(): void{
        this.dialogRef.close();
    }

    public save(): void{
        if(this.apiKey == undefined || this.apiKey.length < 1 ||
            this.apiUrl == undefined || this.apiUrl.length < 1 ||
            this.apiName == undefined || this.apiName.length < 1 ||
            this.apiSecret == undefined || this.apiSecret.length < 1 ||
            this.shopType == undefined ||
            (this.shopType == 'shop.type.woocommerce' && (this.pluginKey == undefined || this.pluginKey.length < 1))){
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.MISSING_MANDATORY_FIELD, 5000);
            return;
        }
        this.dialogRef.close({name: this.apiName, key: this.apiKey, secret: this.apiSecret, url: this.apiUrl, type: this.shopType, pluginKey: this.pluginKey});
    }

    public delete(): void{
        const modal = this.modalController.open(ConfirmComponent);
        modal.onClose.subscribe({
            next: async data => {
                if(data != undefined){
                    if(data.delete){
                        this.dialogRef.close({delete: true});
                    }
                }
            },
        });
    }

}
