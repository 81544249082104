import * as Routes from "../Routes";
import {DataService} from "../data/data.service";
import {GenericResponse} from "../../responses/GenericResponse";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Response} from "../../responses/Response";
import {Shipment} from "../../models/Shipment.model";

export class BarChartStatisticsItems{
    public week: number[];
    public month: number[];
    public year: number[];
}

export class BarChartStatistics{
    public parcel: BarChartStatisticsItems;
    public cost: BarChartStatisticsItems;
}

@Injectable({
    providedIn: 'root',
})
export class TrackingService {

    public static GLS_STATE_NEW = "GLS_STATE_NEW";
    public static GLS_STATE_SHIPPED = "GLS_STATE_SHIPPED";
    public static GLS_STATE_DATA_TRANSFERED = "GLS_STATE_DATA_TRANSFERED";
    public static GLS_STATE_IN_SERVICE = "GLS_STATE_IN_SERVICE";
    public static GLS_STATE_PARCEL_SHOP = "GLS_STATE_PARCEL_SHOP";
    public static GLS_STATE_CANCELED = "GLS_STATE_CANCELED";
    public static GLS_STATE_NOT_DELIVERED = "GLS_STATE_NOT_DELIVERED";
    public static GLS_STATE_MISSING_INFO = "GLS_STATE_MISSING_INFO";
    public static GLS_STATE_IN_WAREHOUSE = "GLS_STATE_IN_WAREHOUSE";
    public static GLS_STATE_IN_PICKUP = "GLS_STATE_IN_PICKUP";

    public static TRACKING_TYPE_SHIPPED = 'TRACKING_TYPE_SHIPPED';
    public static TRACKING_TYPE_SERVICE = 'TRACKING_TYPE_SERVICE';
    public static TRACKING_TYPE_SHOP = 'TRACKING_TYPE_SHOP';
    public static TRACKING_TYPE_MISSING = 'TRACKING_TYPE_MISSING';


    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }

    public getTrackingInfo(shipmentId: string): Observable<any>{
        return this.http.get<Response>(`${Routes.TRACKING_ROUTE}/${shipmentId}/`, this.dataService.getHttpOptions());
    }

    public getParcelShopTrackingInfo(shipmentId: string): Observable<any>{
        return this.http.get<Response>(`${Routes.TRACKING_ROUTE}/parcelShop/${shipmentId}/`, this.dataService.getHttpOptions());
    }

    public getTrackingStatistics(): Observable<any>{
        return this.http.get<Response>(`${Routes.TRACKING_ROUTE}/statistics/`, this.dataService.getHttpOptions());
    }

    public getTrackingSummary(shipmentId: string): Observable<any>{
        return this.http.get<Response>(`${Routes.TRACKING_ROUTE}/summary/${shipmentId}/`, this.dataService.getHttpFileDownloadOptions());
    }

    public getTimeCriticalOfGLSState(glsState: string): Observable<GenericResponse<Shipment[]>>{
        return this.http.get<Response>(`${Routes.TRACKING_ROUTE}/time-critical/${glsState}/`, this.dataService.getHttpOptions());
    }

    public getBarChartsStatistics(): Observable<GenericResponse<string>>{
        return this.http.get<GenericResponse<string>>(`${Routes.TRACKING_ROUTE}/bar-chart-stats/`, this.dataService.getHttpOptions());
    }

}
