import * as Routes from "../Routes";
import {Contact} from "../../models/Contact.model";
import {DataService} from "../data/data.service";
import {GetAllContactResponse} from "../../responses/contact/GetAllContactResponse";
import {GetContactResponse} from "../../responses/contact/GetContactResponse";
import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ContactService {

    constructor(
        private dataService: DataService,
        private http: HttpClient
        ) { }


    public getContact(id?: number | string): Observable<GetContactResponse> {
        if (id === undefined) {
            return this.http.get<GetContactResponse>(`${Routes.CONTACT_ROUTE}/`, this.dataService.getHttpOptions());
        }
        return this.http.get<GetContactResponse>(`${Routes.CONTACT_ROUTE}/${id}/`, this.dataService.getHttpOptions());
    }

    public createContact(contact: Contact): Observable<any> {
        return this.http.post(`${Routes.CONTACT_ROUTE}/`, contact, this.dataService.getHttpOptions());
    }

    public updateContact(id: number | string, contact: Contact): Observable<any> {
        return this.http.put(`${Routes.CONTACT_ROUTE}/${id}/`, contact, this.dataService.getHttpOptions());
    }

    public deleteContact(id: number | string): Observable<any> {
        return this.http.delete(`${Routes.CONTACT_ROUTE}/${id}/`, this.dataService.getHttpOptions());
    }

    public getAll(showOnlyActive: boolean = false): Observable<GetAllContactResponse>{
        return this.http.get<GetAllContactResponse>(`${Routes.CONTACT_ROUTE}/all/${showOnlyActive}`, this.dataService.getHttpOptions());
    }
}