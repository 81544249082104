import * as Routes from "../Routes";
import {DataService} from "../data/data.service";
import {GenericResponse} from "../../responses/GenericResponse";
import {GetOrderAddress} from "../../models/Address";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ShopDefinition} from "../../models/ShopDefinition.model";
import {ShopType} from "../../models/ShopType.model";
import {timeout} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class ShopsService {

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }

    public create(shopDef: ShopDefinition): Observable<GenericResponse<ShopDefinition>>{
        return this.http.post<GenericResponse<ShopDefinition>>(`${Routes.SHOP_DEFINITION_ROUTE}/`, shopDef, this.dataService.getHttpOptions());
    }

    public update(shopDef: ShopDefinition): Observable<GenericResponse<ShopDefinition>>{
        return this.http.put<GenericResponse<ShopDefinition>>(`${Routes.SHOP_DEFINITION_ROUTE}/${shopDef.shopDefinitionId}/`,
            shopDef,
            this.dataService.getHttpOptions()
        );
    }

    public delete(shopDefId: string): Observable<GenericResponse<string>>{
        return this.http.delete<GenericResponse<string>>(`${Routes.SHOP_DEFINITION_ROUTE}/${shopDefId}/`,
            this.dataService.getHttpOptions()
        );
    }

    public getAll(): Observable<GenericResponse<ShopDefinition[]>>{
        return this.http.get<GenericResponse<ShopDefinition[]>>(`${Routes.SHOP_DEFINITION_ROUTE}/all`,
            this.dataService.getHttpOptions()
        );
    }

    public get(shopDefId: string): Observable<GenericResponse<ShopDefinition>>{
        return this.http.get<GenericResponse<ShopDefinition>>(`${Routes.SHOP_DEFINITION_ROUTE}/${shopDefId}/`,
            this.dataService.getHttpOptions()
        );
    }

    public getAllTypes(): Observable<GenericResponse<ShopType[]>>{
        return this.http.get<GenericResponse<ShopType[]>>(`${Routes.SHOP_DEFINITION_ROUTE}/shopTypes`,
            this.dataService.getHttpOptions()
        );
    }

    public verifyConection(shopDefId: string): Observable<GenericResponse<boolean>>{
        return this.http.get<GenericResponse<boolean>>(`${Routes.SHOP_DEFINITION_ROUTE}/verify/${shopDefId}/`,
            this.dataService.getHttpOptions()
        );
    }

    public getOrders(selectedAddress: GetOrderAddress, shopId: string, shopType: string): Observable<GenericResponse<number>>{
        return this.http.post<GenericResponse<number>>(
            `${Routes.BASIC_ROUTE}${shopType}/orders/${shopId}/`,
            selectedAddress, this.dataService.getHttpOptions()).pipe(timeout(30000));
    }

    public reuploadWoocommerceInfos(shipmentId: string): Observable<GenericResponse<string>>{
        return this.http.post<GenericResponse<string>>(
            `${Routes.WOOCOMMERCE_ROUTE}/publish/${shipmentId}/`,
            {}, this.dataService.getHttpOptions());
    }

}
