<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">Absenderadresse hinzufügen - bitte beachten Sie folgende Tipps:</h2>
        </div>
        <div class="modalBody">
            <p>Sollen Pakete von einer anderen Abholadresse versendet werden, benötigten Sie eine zusätzliche auf die neue Abholadresse ausgestellte GLS Contact ID. Teilen Sie uns bitte hierzu die neue Abholadresse, die Abholtage und den Beginn der Abholung mit.</p>
            <p>Sie können aber auch „nur“ eine neue Absenderadresse hinzufügen und diese mit der gleichen Contact ID wie die Ihrer Hauptabholadresse verwenden. Das bedeutet, Ihre Pakete werden an der Hauptabholadresse abgeholt, auf dem Label steht allerdings ein anderer Versender. Dies ist zum Beispiel hilfreich, wenn Pakete von einer zusätzlichen Adresse an den Kunden geliefert werden soll, aber die Rechnung trotzdem über die Hauptfirma läuft.</p>
        </div>
    </div>
</div>
