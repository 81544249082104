import { Component, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `
    <span>{{ rowData.length }}cm x {{ rowData.width }}cm x {{ rowData.height }}cm</span>
  `,
})
export class MeasurementsTableColumnComponent implements ViewCell {
    @Input() value: number;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object
}