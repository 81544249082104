import { Component, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `
    <span>{{ value ? 'ja' : 'nein' }}</span>
  `,
})
export class BooleanTableColumnComponent implements ViewCell {
    @Input() value: string;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object

    ngAfterViewChecked(){
        // console.log(this.value);
        // console.log(this.rowData);
    }
}