import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { DataService } from 'app/services/data/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from 'app/responses/Response';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

    private session;
    private tokenCheckIntervalInMs = 15000;

    constructor(
        private router: Router,
        private authService: AuthService,
        private dataService: DataService
    ) { }

    public canActivate(): Observable<boolean> {
        const token = this.dataService.getClearToken();
        if (token === undefined || token === null || token === '') {
            this.router.navigate(['/auth/login']);
            this.stopLoginSession();
            return new Observable<boolean>((observer) => {
                observer.next(false);
            });
        }

        return new Observable<boolean>((observer) => {
            this.authService.checkTokenValidation().subscribe({
                error: (err: HttpErrorResponse) => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.router.navigate(['/auth/login']);
                    this.stopLoginSession();
                    observer.next(false);
                },
                next: (response: Response) => {
                    if (response.status === 200) {
                        this.dataService.setToken(response.token);
                        observer.next(true);
                    } else {
                        this.router.navigate(['/auth/login']);
                        this.stopLoginSession();
                        observer.next(false);
                    }
                },
                complete: () => {
                    observer.complete();
                },
            });
        });
    }

    public startLoginSession(): void{
        this.checkToken();
    }

    public stopLoginSession(): void{
        if(this.session != undefined){
            clearInterval(this.session);
        }
    }

    private checkToken(): void{
        this.session = setInterval(async () => {
            this.canActivate().subscribe();
        }, this.tokenCheckIntervalInMs);
    }
}
