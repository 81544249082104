import * as Routes from "../Routes";
import {Address, SenderAddress} from "../../models/Address";
import {DataService} from "../data/data.service";
import {FileTemplate} from "../../models/FileTemplate";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Response} from "../../responses/Response";


@Injectable({
    providedIn: 'root',
})
export class FileTemplateService{


    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }

    public create(template: FileTemplate): Observable<Response> {
        let additionalKeysObj = {};
        template.additionalKeysMap.forEach((value, key) => {
            additionalKeysObj[key] = value;
        });
        template.additionalKeys = additionalKeysObj;

        return this.http.post<Response>(`${Routes.FILE_TEMPLATE_ROUTE}/`, template, this.dataService.getHttpOptions());
    }
    public update(template: FileTemplate): Observable<Response> {
        let additionalKeysObj = {};
        template.additionalKeysMap.forEach((value, key) => {
            additionalKeysObj[key] = value;
        });
        template.additionalKeys = additionalKeysObj;
        return this.http.put<Response>(`${Routes.FILE_TEMPLATE_ROUTE}/${template.templateId}/`, template, this.dataService.getHttpOptions());
    }

    public delete(templateId: string): Observable<Response> {
        return this.http.delete<Response>(`${Routes.FILE_TEMPLATE_ROUTE}/${templateId}/`, this.dataService.getHttpOptions());
    }

    public get(templateId: string): Observable<Response> {
        return this.http.get<Response>(`${Routes.FILE_TEMPLATE_ROUTE}/${templateId}/`, this.dataService.getHttpOptions());
    }

    public getAll(): Observable<Response> {
        return this.http.get<Response>(`${Routes.FILE_TEMPLATE_ROUTE}/all/`, this.dataService.getHttpOptions());
    }

    public doImport(templateId: string, selectedAddress: SenderAddress, file): Observable<Response> {
        let options = this.dataService.getHttpOptions();

        options['headers']['name'] = '';
        if(selectedAddress.firstName != undefined && selectedAddress.firstName != ''){
            options['headers']['name'] = selectedAddress.firstName;
        }
        options['headers']['additionalName'] = '';
        if(selectedAddress.lastName != undefined && selectedAddress.lastName != ''){
            options['headers']['additionalName'] = selectedAddress.lastName;
        }
        options['headers']['street'] = selectedAddress.street;
        options['headers']['housenumber'] = selectedAddress.housenumber;
        options['headers']['zip'] = selectedAddress.zip;
        options['headers']['city'] = selectedAddress.city;
        options['headers']['glsContactId'] = selectedAddress.glsContactId;
        options['observe'] = 'response';

        const formData = new FormData();
        formData.append('file',file,file.name);


        return this.http.post<Response>(`${Routes.FILE_IMPORT_ROUTE}/${templateId}/upload/`, formData, options);
    }

    public download(templateId: string, ids: string[]): Observable<any> {
        // tslint:disable-next-line:ter-max-len
        return this.http.post<Response>(`${Routes.FILE_EXPORT_ROUTE}/${templateId}/download/`, {shipmentIds: ids}, this.dataService.getHttpFileExportDownloadOptions());
    }
}