export class MarketplaceDefinition{
    public marketplaceDefinitionId: string;
    public marketplaceType: string;
    public marketplaceName: string;
    public marketplaceUrl: string;
    public marketplaceConnectionKey: string;
    public marketplaceConnectionSecret: string;
    public marketplacePluginAuthKey: string;
    public additionalMarketplaceInfo: string;
    public createdAt: Date;
    public updatedAt: Date;
}
