import * as Routes from "../Routes";
import {DataService} from "../data/data.service";
import {GenericResponse} from "../../responses/GenericResponse";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Response} from "../../responses/Response";
import {SenderAddress} from "../../models/Address";

@Injectable({
    providedIn: 'root',
})
export class AmazonService{

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) {}

    public getExportUrl(): Observable<GenericResponse<string>>{
        return this.http.get<GenericResponse<string>>(`${Routes.AMAZON_ROUTE}/url/export/`, this.dataService.getHttpOptions());
    }
    public getImportUrl(): Observable<GenericResponse<string>>{
        return this.http.get<GenericResponse<string>>(`${Routes.AMAZON_ROUTE}/url/import/`, this.dataService.getHttpOptions());
    }
    public importFile(selectedAddress: SenderAddress, file): Observable<GenericResponse<string[]>>{
        let options = this.dataService.getHttpOptions();

        options['headers']['name'] = '';
        if(selectedAddress.firstName != undefined && selectedAddress.firstName != ''){
            options['headers']['name'] = selectedAddress.firstName;
        }
        options['headers']['additionalName'] = '';
        if(selectedAddress.lastName != undefined && selectedAddress.lastName != ''){
            options['headers']['additionalName'] = selectedAddress.lastName;
        }
        options['headers']['street'] = selectedAddress.street;
        options['headers']['housenumber'] = selectedAddress.housenumber;
        options['headers']['zip'] = selectedAddress.zip;
        options['headers']['city'] = selectedAddress.city;
        options['headers']['glsContactId'] = selectedAddress.glsContactId;
        options['observe'] = 'response';

        const formData = new FormData();
        formData.append('file',file,file.name);


        return this.http.post<Response>(`${Routes.AMAZON_ROUTE}/file/import/`, formData, options);
    }

    public getExportFile(ids: string[]): Observable<any> {
        return this.http.post<Response>(
            `${Routes.AMAZON_ROUTE}/file/export/`,
            {shipmentIds: ids},
            this.dataService.getHttpFileExportDownloadOptions()
        );
    }

}
