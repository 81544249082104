import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class IconsService{

    public TABLE_ICONS = {
        EDIT: '<i class="fas fa-pencil-alt edit"></i>',
        DELETE: '<i class="fas fa-trash-alt delete"></i>',
        DISABLE: '<i class="fas fa-ban deactivate"></i>',
        ADD_CONTACT: '<i class="fas fa-address-book addContact"></i>',
        REGISTER: '<i class="fas fa-truck readyToShip"></i>',
        CATEGORIZE: '<i class="fas fa-clipboard-check ready"></i>',
        PDF_DOWNLOAD: '<i class="fas fa-file-pdf getPdf"></i>',
    };

    // tslint:disable-next-line:no-empty
    constructor() {
    }

}