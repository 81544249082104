import { Component, Input } from '@angular/core';
import {Clipboard} from "@angular/cdk/clipboard";
import { NbDialogRef } from '@nebular/theme';
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-copy-password-dialog',
    templateUrl: 'dialog-copy-password.component.html',
    styleUrls: ['dialog-copy-password.component.scss'],
})
export class DialogCopyPasswordComponent {

    @Input() public password: string;

    constructor(
        protected ref: NbDialogRef<DialogCopyPasswordComponent>,
        private clipboard: Clipboard,
        private toastService: ToastService
    ) {}

    dismiss() {
        this.ref.close();
    }

    copy() {
        this.clipboard.copy(this.password);
        this.toastService.showToast(this.toastService.TOAST_STATUS.success,this.toastService.COPIED_TO_CLIPBOARD,10000);
    }
}