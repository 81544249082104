
export class Shipment {
    public shipmentId: string;
    public shipmentType: string;
    public shipmentState: string;
    public express: boolean;
    public senderFirstName: string;
    public senderLastName: string;
    public senderAdditionalName: string;
    public senderStreet: string;
    public senderHousenumber: string;
    public senderZip: string;
    public senderCity: string;
    public senderCountry: string;
    public senderContactId: string;
    public receiverNameLine1: string;
    public receiverNameLine2: string;
    public receiverAddressLine1: string;
    public receiverAddressLine2: string;
    public receiverZip: string;
    public receiverCity: string;
    public receiverCountry: string;
    public receiverEmail: string;
    public receiverPhone: string;
    public weight: number;
    public height: number;
    public width: number;
    public length: number;
    public notBandCapable: boolean;
    public island: boolean;
    public countryGroupKey: string;
    public categoryKey: string;
    public price: number;
    public pickupId: string;
    public internalBatchId: string;
    public createdAt: Date;
    public updatedAt: Date;
    public trackingId: string;
    public parcelNumber: string;
    public glsState: string;
    public importAdditionalRef: string;
    public useFlexDelivery: boolean = false;
    public additionalContactInformation1: string;
    public additionalContactInformation2: string;
}
