import {
    AddressTableColumnComponent,
    AddressTableColumnEditorComponent
} from './custom-table-cols/AddressTableColumn.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    NbActionsModule,
    NbAutocompleteComponent,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbSelectModule,
    NbSpinnerModule,
    NbStepperModule,
    NbTooltipModule,
} from '@nebular/theme';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { AgbComponent } from './info-popups/agb/agb.component';
import { AmazonImportComponent } from './amazon-import/amazon-import.component';
import {BooleanTableColumnComponent} from './custom-table-cols/BooleanTableColumn.component';
import {CategorySelectColumnComponent} from "./custom-table-cols/CategorySelectColumn.component";
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { CreateAddressInfoComponent } from './info-popups/create-address-info/create-address-info.component';
import { CreateLabelInfoComponent } from './info-popups/create-label-info/create-label-info.component';
import { CriticalTrackingDialog } from './critical-tracking-dialog/critical-tracking-dialog.component';
import {DateFilterComponent} from "./custom-table-cols/DateFilter.component";
import {DialogCopyPasswordComponent} from './dialog-copy-password/dialog-copy-password.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { DragNDropComponent } from './drag-n-drop/drag-n-drop.component';
import { EShopPluginsComponent } from './e-shop-plugins/e-shop-plugins.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { FinalReportInfoComponent } from './info-popups/final-report-info/final-report-info.component';
import { FinalReportReminderComponent } from './info-popups/final-report-reminder/final-report-reminder.component';
import { FinalReportWarnComponent } from './info-popups/final-report-warn/final-report-warn.component';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';
import { MarketplacePopupComponent } from './marketplace-popup/marketplace-popup.component';
import {MeasurementsTableColumnComponent} from './custom-table-cols/MeasurementsTableColumn.component';
import {MultiConfirmComponent} from './multiConfirm/multiConfirm.component';
import { NbMomentDateModule } from '@nebular/moment';
import { Ng2CompleterModule } from '@akveo/ng2-completer';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgModule } from '@angular/core';
import {NgxEchartsModule} from "ngx-echarts";
import {NgxFileDropModule} from "ngx-file-drop";
import {ParcelNumberTableColumnComponent} from "./custom-table-cols/ParcelNumberTableColumn.component";
import {PriceTableColumnComponent} from './custom-table-cols/PriceTableColumn.component';
import {ProfitChartComponent} from "./charts/d3/multibat-chart.component";
import { ShipmentEditComponent } from './shipment-edit/shipment-edit.component';
import { ShipmentStepperComponent } from './shipment-stepper/shipment-stepper.component';
import {ShipmentTypeColumnComponent} from './custom-table-cols/ShipmentTypeColumn.component';
import {SimpleTableFilterComponent} from "./custom-table-cols/SimpleTableFilter.component";
import {SimpleTableListFilterComponent} from "./custom-table-cols/SimpleTableListFilter.component";
import { SmartTableComponent } from './smart-table/smart-table.component';
import { SpinnerComponent } from './spinner/spinner.component';
import {StepperHeightTableColumnComponent} from "./custom-table-cols/StepperHeightTableColumn.component";
import {StepperLengthTableColumnComponent} from "./custom-table-cols/StepperLengthTableColumn.component";
import {StepperWeightTableColumnComponent} from "./custom-table-cols/StepperWeightTableColumn.component";
import {StepperWidthTableColumnComponent} from "./custom-table-cols/StepperWidthTableColumn.component";
import { SupportPopupComponent } from './support-popup/support-popup.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import {TrackingLinkTableColumnComponent} from "./custom-table-cols/TrackingLinkTableColumn.component";
import { TrackingModalComponent } from './tracking-modal/tracking-modal.component';
import { UploadPopupComponent } from './upload-popup/upload-popup.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { WeatherConfigComponent } from './weather-config/weather-config.component';
import { WoocommercePopupComponent } from './woocommerce-popup/woocommerce-popup.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        Ng2CompleterModule,
        Ng2SmartTableModule,
        NbCardModule,
        NbDatepickerModule,
        NbMomentDateModule,
        NbStepperModule,
        NbInputModule,
        NbButtonModule,
        NbAutocompleteModule,
        NbCardModule,
        NbSelectModule,
        ReactiveFormsModule,
        NbCheckboxModule,
        NbTooltipModule,
        NbSpinnerModule,
        DragDropModule,
        NbActionsModule,
        NbIconModule,
        NgxFileDropModule,
        NbLayoutModule,
        NgxEchartsModule,
    ],
    declarations: [
        SmartTableComponent,
        DialogCopyPasswordComponent,
        ShipmentStepperComponent,
        ShipmentTypeColumnComponent,
        ConfirmComponent,
        MultiConfirmComponent,
        PriceTableColumnComponent,
        AddressTableColumnComponent,
        BooleanTableColumnComponent,
        MeasurementsTableColumnComponent,
        AddressTableColumnEditorComponent,
        SimpleTableFilterComponent,
        SimpleTableListFilterComponent,
        TrackingLinkTableColumnComponent,
        ParcelNumberTableColumnComponent,
        StepperWeightTableColumnComponent,
        StepperHeightTableColumnComponent,
        StepperLengthTableColumnComponent,
        StepperWidthTableColumnComponent,
        CategorySelectColumnComponent,
        DateFilterComponent,
        UserModalComponent,
        ContactModalComponent,
        ShipmentEditComponent,
        AddressModalComponent,
        ImportDialogComponent,
        FinalReportInfoComponent,
        CreateLabelInfoComponent,
        SpinnerComponent,
        WeatherConfigComponent,
        DragNDropComponent,
        TemplateEditComponent,
        CreateAddressInfoComponent,
        ExportDialogComponent,
        AgbComponent,
        TrackingModalComponent,
        UploadPopupComponent,
        WoocommercePopupComponent,
        CriticalTrackingDialog,
        EShopPluginsComponent,
        FinalReportReminderComponent,
        FinalReportWarnComponent,
        MarketplacePopupComponent,
        ProfitChartComponent,
        AmazonImportComponent,
        SupportPopupComponent,
    ],
    exports: [
        SmartTableComponent,
        DialogCopyPasswordComponent,
        DragNDropComponent,
        ProfitChartComponent,
    ],
})
export class SharedModule { }
