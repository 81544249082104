import {Address, SenderAddress} from "../../models/Address";
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import {CategorySelectColumnComponent} from "../custom-table-cols/CategorySelectColumn.component";
import { Contact } from 'app/models/Contact.model';
import { ContactService } from 'app/services/contact/contact.service';
import { Country } from 'app/models/Country';
import { HttpErrorResponse } from '@angular/common/http';
import {IconsService} from "../../services/icons/icons.service";
import {IdService} from "../../services/ids/id.service";
import { LocalDataSource } from 'ng2-smart-table';
import { NbDialogRef } from '@nebular/theme';
import { ParcelCategory } from 'app/models/ParcelCategory';
import { ParcelCategoryService } from 'app/services/parcel-category/parcel-category.service';
import { PostalFileData } from "app/models/PostalFileData";
import { PostalService } from "app/services/postal/postal.service";
import { PriceClassification } from 'app/models/PriceClassification';
import { SettingsService } from 'app/services/settings/settings.service';
import { Shipment } from 'app/models/Shipment.model';
import { ShipmentService } from 'app/services/shipments/shipment.service';
import {StepperHeightTableColumnComponent} from "../custom-table-cols/StepperHeightTableColumn.component";
import {StepperLengthTableColumnComponent} from "../custom-table-cols/StepperLengthTableColumn.component";
import {StepperWeightTableColumnComponent} from "../custom-table-cols/StepperWeightTableColumn.component";
import {StepperWidthTableColumnComponent} from "../custom-table-cols/StepperWidthTableColumn.component";
import { ToastService } from 'app/services/toast/toast.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ngx-shipment-stepper',
    templateUrl: './shipment-stepper.component.html',
    styleUrls: ['./shipment-stepper.component.scss'],
})
export class ShipmentStepperComponent implements OnInit {

    public step: number;
    public contacts: Contact[];
    public filteredOptions: Observable<Contact[]>;
    public filteredOptionsAddresses: Observable<Address[]>;
    public filteredCountryOptions: Observable<Country[]>;

    public selectedContact: Contact;
    public selectedAddress: SenderAddress;
    public selectedCountry: Country;
    public addressSetupDone = false;

    public parcelCategories: ParcelCategory[];
    private displayCategories: string[];
    public validPrices: any[];
    private count = 0;
    private toastShown = false;
    public selectedAddressIndex = 0;

    public selectedPriceClassifications: PriceClassification[];
    private displayPrice: string[];
    public senderAddresses: SenderAddress[] = [];

    public tableData: any[];
    public dataSource: LocalDataSource;
    public loading: boolean;
    public settings = {
        actions: {
            columnTitle: 'Aktionen',
            custom: [
                {
                    name: 'copy',
                    title: '<i class="fas fa-copy duplicate"></i>',
                },
            ],
            edit: false,
            delete: true,
            add: false,
        },
        pager: {
            display: false,
        },
        noDataMessage: 'Keine Einträge vorhanden.',
        edit: {
            editButtonContent: this.icons.TABLE_ICONS.EDIT,
            saveButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
            confirmSave: true,
        },
        delete: {
            deleteButtonContent: this.icons.TABLE_ICONS.DELETE,
            confirmDelete: true,
        },
        rowClassFunction: (row) =>{
            if(!row.data.isValid){
                return 'invalid';
            }
            return '';
        },
        columns: {
            name: {
                title: 'Paketkategorie',
                editable: false,
                filter: false,
                type: 'custom',
                renderComponent: CategorySelectColumnComponent,
                onComponentInitFunction: (instance) => {
                    instance.cats = this.validPrices;
                },

                /*
                valuePrepareFunction: (cell, row) => {
                  console.log(this.validPrices);
                  return this.validPrices;
                },
                valuePrepareFunction: (data,row) => {
                  if(data.includes('Basis') && data.includes('National')){
                    return '<span class="category-color-default-national">' + data + '</span>';
                  }else if(data.includes('Express') && data.includes('National')){
                    return '<span class="category-color-express-national">' + data + '</span>';
                  }else{
                    return '<span class="category-color-default-international">' + data + '</span>';
                  }
                },*/
            },
            weight: {
                title: '*Gewicht (kg)',
                editable: true,
                filter: false,
                hide: false,
                type: 'custom',
                renderComponent: StepperWeightTableColumnComponent,
            },
            length: {
                title: '*Länge (cm)',
                editable: true,
                filter: false,
                hide: false,
                type: 'custom',
                renderComponent: StepperLengthTableColumnComponent,
            },
            width: {
                title: '*Breite (cm)',
                editable: true,
                filter: false,
                hide: false,
                type: 'custom',
                renderComponent: StepperWidthTableColumnComponent,
            },
            height: {
                title: '*Höhe (cm)',
                editable: true,
                filter: false,
                hide: false,
                type: 'custom',
                renderComponent: StepperHeightTableColumnComponent,
            },
            isValid: {
                hide: true,
                editor:{
                    type: 'checkbox',
                },
            },
        },
    };

    @ViewChild('autocomplete')
    public elementAutocomplete: ElementRef;
    @ViewChild('autocompleteAddresses')
    public elementAutocompleteAddresses: ElementRef;
    @ViewChild('autocompleteCountry')
    public elementAutocompleteCountry: ElementRef;

    @ViewChild('name')
    public elementNameLine1: ElementRef;
    @ViewChild('extra')
    public elementNameLine2: ElementRef;
    @ViewChild('address')
    public elementAddress: ElementRef;
    @ViewChild('postal')
    public elementZIP: ElementRef;
    @ViewChild('city')
    public elementCity: ElementRef;
    // @ViewChild('country')
    // public elementCountry: ElementRef;
    @ViewChild('mail')
    public elementMail: ElementRef;
    @ViewChild('phone')
    public elementPhone: ElementRef;

    public receiverNameLine1: string;
    public receiverNameLine2: string;
    public receiverAddressLine: string;
    public receiverAddressLine2: string;
    public additionalContactInformation1: string;
    public additionalContactInformation2: string;
    public receiverPostal: string;
    public receiverCity: string;
    public receiverCountry: string;
    public receiverEmail: string;
    public receiverPhone: string;

    public finalCategories: any[];
    public countries: Country[];

    public weighCustomer: boolean;
    public softwareCustomer: boolean;
    public weightCustomerStandard: {weight: number,length: number, width: number, height: number, price: 1.99};

    private postalFileData: Map<string, PostalFileData[]>[];
    private flexDefault: boolean = false;

    constructor(
        private contactService: ContactService,
        private toastService: ToastService,
        private parcelCategoryService: ParcelCategoryService,
        private shipmentService: ShipmentService,
        private settingsService: SettingsService,
        protected dialogRef: NbDialogRef<any>,
        private idService: IdService,
        private icons: IconsService,
        private postalService: PostalService
    ) { }

    ngOnInit(): void {
        this.initData();
        this.getSettings();
        this.getContacts();
        this.getCountries();
        this.getParcelCategories();
        this.getPostalFileData();
    }

    private initData(): void {
        this.flexDefault = false;
        this.step = 0;
        this.selectedAddressIndex = 0;
        this.contacts = [];
        this.finalCategories = [];
        this.parcelCategories = [];
        this.displayCategories = [];
        this.validPrices = [];
        this.countries = [];
        this.selectedContact = undefined;
        this.selectedAddress = undefined;
        this.senderAddresses = [];

        this.displayPrice = [];
        this.selectedPriceClassifications = [];

        this.tableData = [];
        this.dataSource = new LocalDataSource();
        this.loading = false;
        this.addressSetupDone = false;

        this.receiverNameLine1 = '';
        this.receiverNameLine2 = '';
        this.receiverAddressLine = '';
        this.receiverAddressLine2 = '';
        this.additionalContactInformation1 = '';
        this.additionalContactInformation2 = '';
        this.receiverPostal = '';
        this.receiverCity = '';
        this.receiverCountry = '';
        this.receiverEmail = '';
        this.receiverPhone = '';
    }

    private getSettings(): void {

        this.settingsService.getSetting(this.settingsService.CUSTOMER_SOFTWARE_STATUS).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.SETTINGS_LOAD_FAILED,10000,err);
            },
            next: value => {
                this.softwareCustomer = value.body.value === 'true';
            },
        });

        this.settingsService.getSetting(this.settingsService.KEY_CUSTOMER_WEIGH).subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
            },
            next: response => {
                if (response.body.value === 'true') {
                    this.weighCustomer = true;
                    this.settingsService.getSetting(this.settingsService.NO_WEIGH_CUSTOMER_SETTINGS_KEY).subscribe({
                        error: err => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                        },
                        next: value => {
                            this.weightCustomerStandard = JSON.parse(value.body.value).standard;
                        },
                    });
                } else {
                    this.weighCustomer = false;
                }
                this.updateTableSetting();
            },
        });
        this.settingsService.getSetting(this.settingsService.KEY_SENDER_ADDITIONAL_ADDRESSES).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: value => {
                this.senderAddresses = JSON.parse(value.body.value) as SenderAddress[];
                this.filteredOptionsAddresses = this.getFilteredOptionsAddresses('');
            },
            complete: () => {

                this.settingsService.getSetting(this.settingsService.KEY_SENDER_DATA).subscribe({
                    error: (err: HttpErrorResponse) => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                    },
                    next: response => {
                        if(this.senderAddresses == undefined){
                            this.senderAddresses = [];
                        }
                        let senderData = JSON.parse(response.body.value);
                        let newAddress = new SenderAddress();
                        newAddress.city = senderData.pickupAddress.city;
                        newAddress.country = senderData.pickupAddress.country;
                        newAddress.housenumber = senderData.pickupAddress.housenumber;
                        newAddress.firstName = senderData.pickupAddress.firstName;
                        newAddress.lastName = senderData.pickupAddress.lastName;
                        newAddress.street = senderData.pickupAddress.street;
                        newAddress.zip = senderData.pickupAddress.zip;
                        newAddress.glsContactId = senderData.GLSContactId;
                        this.senderAddresses.unshift(newAddress);
                        this.selectedAddress = this.senderAddresses[0];
                        this.addressSetupDone = true;
                    },
                });

            },
        });


        this.settingsService.getSetting(this.settingsService.KEY_DEFAULT_FLEX_DELIVERY).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.SETTINGS_LOAD_FAILED,10000,err);
            },
            next: value => {
                this.flexDefault = value.body.value == 'true';
            },
        });

    }

    public updateTableSetting(): void{
        let tempSettings = this.settings;
        if(this.weighCustomer){
            tempSettings.columns.weight.hide = false;
            tempSettings.columns.length.hide = true;
            tempSettings.columns.width.hide = true;
            tempSettings.columns.height.hide = true;

        }else{
            tempSettings.columns.weight.hide = false;
            tempSettings.columns.length.hide = false;
            tempSettings.columns.width.hide = false;
            tempSettings.columns.height.hide = false;
        }
        this.settings = Object.assign({}, tempSettings);
    }

    private getContacts(): void {
        this.contactService.getAll(true).subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
            },
            next: (response) => {
                // console.log(response);
                this.contacts = response.body;
                this.filteredOptions = this.getFilteredOptions('');
            },
        });
    }

    private getCountries(): void {
        this.parcelCategoryService.getActiveCountries().subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
            },
            next: response => {
                this.countries = response.body;
                this.countries.forEach(c => {
                    if(c.name.toLocaleLowerCase().includes('deutschland')){
                        this.receiverCountry = c.key;
                    }
                });
                this.filteredCountryOptions = this.getFilteredCountryOptions('');
            },
        });
    }

    private getParcelCategories(): void {
        this.parcelCategoryService.getAllActiveParcelCategories().subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
            },
            next: (response) => {
                this.parcelCategories = response.body;
            },
        });
    }

    private getPostalFileData(): void {
        this.postalFileData = this.postalService.getFileData();
    }

    public checkPostalData(showPostalError: boolean = true): void {
        const index = this.postalService.countryKeys.indexOf(this.receiverCountry);
        if (index == -1) return;
        const postalData = this.postalFileData[index];

        let showCityErr = true;
        if (this.receiverPostal != undefined && this.receiverPostal.trim().length > 4) {
            const data = postalData.get(this.receiverPostal.trim());
            if (data == undefined && showPostalError) {
                this.toastService.showToast(3, this.toastService.CHECK_POSTAL_INFORMATION, 5000);
            }
            if (this.receiverCity != undefined && this.receiverCity.length > 1) {
                for (let ort of data) {
                    if (ort.ort.toLowerCase().includes(this.receiverCity.toLowerCase().trim())
                        || this.receiverCity.toLowerCase().includes(ort.ort.toLowerCase().trim())) {
                        showCityErr = false;
                    }
                }
                if (showCityErr) {
                    this.toastService.showToast(3, this.toastService.CHECK_CITY_INFORMATION, 5000, this.receiverCity.trim());
                }
            }
        }
    }

    public nextStep(): void {
        this.step++;
        if (this.step === 1) {
            this.setDisplayParcelCategories();
        }
        if (this.step === 2) {

            let canGoOn = true;
            for(let data of this.tableData){
                if(!this.validateTableRow(data)){
                    canGoOn = false;
                }
            }
            if(!canGoOn){
                this.step--;
                return;
            }


            this.finalCategories = [];
            this.setFinalCategories();
            this.loading = true;
            // console.log(this.finalCategories);
            this.finalCategories.forEach(data => {
                let tmp = new Shipment();
                tmp.receiverCountry = this.receiverCountry;
                tmp.weight = data.weight;
                if(this.weighCustomer){
                    tmp.width = this.weightCustomerStandard.width;
                    tmp.height = this.weightCustomerStandard.height;
                    tmp.length = this.weightCustomerStandard.length;
                    // tmp.weight = this.weightCustomerStandard.weight;
                }else {
                    tmp.width = data.width;
                    tmp.height = data.height;
                    tmp.length = data.length;
                    // tmp.weight = data.weight;
                }
                tmp.express = data.category.categoryKey === "EXP";
                this.shipmentService.categorizeShipment(tmp).subscribe({
                    error: err => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
                    },
                    next: value => {
                        data.price = value.body.price;
                        data.categoryKey = value.body.categoryKey;
                    },
                    complete: () => this.loading = false,

                });
            });
            // console.log(this.finalCategories);

        }
    }

    public prepareCategoryName(final: any): string{
        let cat = '';
        this.parcelCategories.forEach(category => {
            if(final.categoryKey !== undefined && final.categoryKey.includes('EXP')){
                if(category.countryGroupKey == final.category.countryGroupKey && category.categoryKey == 'EXP'){
                    cat = category.categoryName;
                    return cat;
                }
            }else{
                if(category.countryGroupKey == final.category.countryGroupKey && category.categoryKey != 'EXP'){
                    cat = category.categoryName;
                    return cat;
                }
            }
        });

        return cat;
    }

    public previousStep(): void {
        this.step--;
    }

    public finalize(): void {
        let senderData;
        let batchId;
        this.settingsService.getSetting(this.settingsService.KEY_SENDER_DATA).subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
            },
            next: response => {
                senderData = JSON.parse(response.body.value);
            },
            complete: () => {
                this.shipmentService.getNextBatchId().subscribe({
                    error: (err: HttpErrorResponse) => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
                    },
                    next: response => {
                        const obj = JSON.parse(response.body);
                        batchId = obj.newInternalBatchId;
                    },
                    complete: () => {
                        // console.log(this.finalCategories);
                        let size = this.finalCategories.length;
                        this.finalCategories.forEach(data => {
                            if(this.weighCustomer){
                                for(let i = 0; i < data.amount; i++){
                                    // console.log(data);
                                    this.shipmentSetupNormal(data, senderData, batchId, size);
                                }
                            }else{
                                this.shipmentSetupNormal(data, senderData, batchId, size);
                            }
                        });
                    },
                });
            },
        });
    }

    private shipmentSetupNormal(data, senderData, batchId, size): void{

        const shipment = new Shipment();
        if (data.category.categoryKey === 'EXP') {
            shipment.express = true;
        }
        shipment.internalBatchId = batchId;
        shipment.shipmentType = 'MANUAL';
        shipment.receiverNameLine2 = this.receiverNameLine2;
        shipment.receiverCity = this.receiverCity;
        shipment.receiverCountry = this.receiverCountry;
        shipment.receiverNameLine1 = this.receiverNameLine1;
        shipment.receiverAddressLine1 = this.receiverAddressLine;
        shipment.receiverAddressLine2 = this.receiverAddressLine2;
        shipment.additionalContactInformation1 = this.additionalContactInformation1;
        shipment.additionalContactInformation2 = this.additionalContactInformation2;
        shipment.receiverZip = this.receiverPostal;
        shipment.receiverEmail = this.receiverEmail;
        shipment.receiverPhone = this.receiverPhone;

        if(this.selectedAddressIndex == undefined){
            shipment.senderCity = senderData.pickupAddress.city;
            shipment.senderCountry = senderData.pickupAddress.country;
            shipment.senderHousenumber = senderData.pickupAddress.housenumber;
            shipment.senderFirstName = senderData.pickupAddress.firstName;
            shipment.senderLastName = senderData.pickupAddress.lastName;
            shipment.senderStreet = senderData.pickupAddress.street;
            shipment.senderZip = senderData.pickupAddress.zip;
            shipment.senderContactId = senderData.glsContactId;
        }else{
            if(this.senderAddresses[this.selectedAddressIndex] != undefined){
                this.selectedAddress = this.senderAddresses[this.selectedAddressIndex];
                shipment.senderCity = this.selectedAddress.city;
                shipment.senderCountry = this.selectedAddress.country;
                shipment.senderHousenumber = this.selectedAddress.housenumber;
                shipment.senderFirstName = this.selectedAddress.firstName;
                shipment.senderLastName = this.selectedAddress.lastName;
                shipment.senderStreet = this.selectedAddress.street;
                shipment.senderZip = this.selectedAddress.zip;
                shipment.senderContactId = this.selectedAddress.glsContactId;
            }else{
                shipment.senderCity = senderData.pickupAddress.city;
                shipment.senderCountry = senderData.pickupAddress.country;
                shipment.senderHousenumber = senderData.pickupAddress.housenumber;
                shipment.senderFirstName = senderData.pickupAddress.firstName;
                shipment.senderLastName = senderData.pickupAddress.lastName;
                shipment.senderStreet = senderData.pickupAddress.street;
                shipment.senderZip = senderData.pickupAddress.zip;
                shipment.senderContactId = senderData.glsContactId;
            }
        }

        shipment.weight = Number(data.weight);
        if(this.weighCustomer){
            shipment.price = this.weightCustomerStandard.price;
            // shipment.weight = Number(this.weightCustomerStandard.weight);
            shipment.height = Number(this.weightCustomerStandard.height);
            shipment.length = Number(this.weightCustomerStandard.width);
            shipment.width = Number(this.weightCustomerStandard.length);
        }else{
            shipment.price = data.price.price;
            // shipment.weight = Number(data.weight);
            shipment.height = Number(data.height);
            shipment.length = Number(data.length);
            shipment.width = Number(data.width);
        }
        this.shipmentService.getIsIsland(shipment.receiverCountry, shipment.receiverZip).subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
            },
            next: response => {
                const obj = JSON.parse(response.body);
                shipment.island = obj.isIsland;
                if (obj.supplement !== undefined) {
                    shipment.price += Number(shipment.price) + Number(obj.supplement);
                }
            },
            complete: () => {
                this.shipmentService.getIsNB(data.category.categoryKey, shipment.length, shipment.width, shipment.height).subscribe({
                    error: (err: HttpErrorResponse) => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED,10000,err);
                    },
                    next: response => {
                        const obj = JSON.parse(response.body);
                        if (obj.supplement !== undefined) {
                            shipment.price += Number(shipment.price) + Number(obj.supplement);
                            shipment.notBandCapable = true;
                        } else {
                            shipment.notBandCapable = false;
                        }
                    },
                    complete: () => {
                        this.createShipment(shipment, size);
                    },
                });
            },
        });
    }

    private createShipment(shipment: Shipment, size: number): void {
        // console.log(shipment)
        let toCategorize: Shipment;
        shipment.useFlexDelivery = false;
        if(!shipment.express){
            shipment.useFlexDelivery = this.flexDefault;
        }
        this.shipmentService.createShipment(shipment).subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.SHIPMENT_CREATE_FAILED, 10000, err);
                this.count++;
            },
            next: res => {
                // console.log(res);
                if (res.status === 200) {
                    if(!this.toastShown){
                        this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.SHIPMENT_CREATE_SUCCESS, 2000);
                        this.toastShown = true;
                    }
                    toCategorize = res.body;
                }
                this.count++;
            },
            complete: () => {
                if (toCategorize !== undefined) {
                    this.shipmentService.categorizeShipment(toCategorize).subscribe({
                        error: (err: HttpErrorResponse) => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.SHIPMENT_CREATE_FAILED,10000,err);
                            if(this.count >= size){
                                this.dialogRef.close({reload: true});
                            }
                        },
                        next: response => {
                            // console.log(response);
                        },
                        complete: () => {
                            if(this.count >= size){
                                this.dialogRef.close({reload: true});
                            }
                        },
                    });
                }
            },
        });
    }

    private setFinalCategories(): void {
        if(this.weighCustomer){
            let finalCat = [];
            this.tableData.forEach(data => {
                const categoryId = data.categoryId;
                const res = finalCat.find(x => x.category.categoryId == categoryId);
                if(res != undefined){
                    res.amount++;
                }else{
                    let cat = this.parcelCategories.find(x => x.categoryId == categoryId);
                    if(cat == undefined){
                        this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_STEPPER_CATEGORY_NOT_FOUND, 10000);
                    }else{
                        finalCat.push({category: cat, amount: 1, categoryKey: cat.categoryKey, weight: data.weight});
                    }
                }

            });
            this.finalCategories = finalCat;
        }else{

            this.tableData.forEach(data => {
                const categoryId = data.categoryId;
                const priceId = data.priceId;
                this.parcelCategories.forEach(category => {
                    if (category.categoryId === categoryId) {
                        this.finalCategories.push({
                            category,
                            // price,
                            width: data.width,
                            height: data.height,
                            length: data.length,
                            weight: data.weight,
                        });
                        /*
                        category.priceClassifications.forEach(price => {
                          if (price.classificationId === priceId) {
                          }
                        });
                        */
                    }
                });
            });

        }
    }

    private setDisplayParcelCategories(): void {
        this.validPrices = [];
        this.parcelCategories.forEach(category => {
            if(category.active){
                category.countryList.forEach((country: Country) => {
                    if (country.key.toLocaleLowerCase() === this.receiverCountry.toLocaleLowerCase()) {
                        this.displayCategories.push(category.categoryId);
                        this.validPrices.push(category);
                        /*
                        category.priceClassifications.forEach(price => {
                          this.validPrices.push({price, category});
                        });
                         */
                    }
                });
            }
        });
        let newTableData = [];
        this.tableData.forEach(data => {
            if(this.validPrices.find(x => x.categoryId == data.categoryId) != undefined){
                newTableData.push(data);
            }
        });
        this.tableData = newTableData;
        this.dataSource.load(this.tableData);
    }

    public shouldDisplayCategory(category: ParcelCategory): boolean {
        if (this.displayCategories.find(x => x === category.categoryId) !== undefined) {
            return true;
        }
        return false;
    }

    public addPriceToList(category: ParcelCategory): void {
        // this.displayPrice.push(price.classificationId);
        let data = {
            id: this.idService.generate(),
            weight: null,
            height: null,
            width: null,
            length: null,
            name: category.categoryName,
            categoryId: category.categoryId,
            isValid: true,
        };
        if(this.weighCustomer){
            // data.weight = this.weightCustomerStandard.weight;
            data.height = this.weightCustomerStandard.height;
            data.width = this.weightCustomerStandard.width;
            data.length = this.weightCustomerStandard.length;
        }
        this.tableData.push(data);
        this.dataSource.load(this.tableData);
    }

    private removeFromPricelist(data: any): void {
        let temp = [];
        this.tableData.forEach(entry => {
            if (data.id !== entry.id) {
                temp.push(entry);
            }
        });
        this.tableData = temp;
        temp = [];
        this.displayPrice.forEach(entry => {
            if (entry !== data.priceId) {
                temp.push(entry);
            }
        });
        this.displayPrice = temp;
    }

    public shouldDisplayPrice(price: PriceClassification): boolean {
        if (this.displayPrice.find(x => x === price.classificationId) !== undefined) {
            return false;
        }
        return true;
    }

    public shipmentHasHousenumber(): boolean{
        if(this.receiverAddressLine.length > 0){
            return /^(.*[0-9].*)$/.test(this.receiverAddressLine);
        }else{
            return true;
        }
    }

    public shouldGoToStep2(): boolean {
        if (
            this.receiverNameLine1 === undefined || this.receiverNameLine1 === '' ||
            this.receiverAddressLine === undefined || this.receiverAddressLine === '' ||
            this.receiverPostal === undefined || this.receiverPostal === '' ||
            this.receiverCity === undefined || this.receiverCity === '' ||
            this.receiverCountry === undefined || this.receiverCountry === '' || !/^(.*[0-9].*)$/.test(this.receiverAddressLine)
        ) {
            return false;
        }
        return true;
    }

    public shouldGoToStep3(): boolean {
        if (this.tableData.length === 0) {
            return false;
        } else {
            for (let data of this.tableData) {
                if (data.weight == 0 || data.weight == null) {
                    return false;
                } else if (data.height == 0 || data.height == null) {
                    return false;
                } else if (data.length == 0 || data.length == null) {
                    return false;
                } else if (data.width == 0 || data.width == null) {
                    return false;
                }
            }
        }
        return true;
    }

    public formatPrice(price: number): string {
        let result = String(price).split('.')[0];
        const part = Number(String(price).split('.')[1]);
        if (part < 10) {
            result += `.${part}0`;
        }
        result += ' €';
        return result;
    }

    public onTableEdit(event: any): void {
        const data = event.newData as {
            categoryId: string,
            name: string,
            weight: string | number,
            height: string | number,
            width: string | number,
            length: string | number,
        };

        if (Number.isNaN(Number(data.weight))
            || Number.isNaN(Number(data.height))
            || Number.isNaN(Number(data.width))
            || Number.isNaN(Number(data.length))
        ) {
            event.confirm.reject();
            return;
        }

        const category = this.parcelCategories.find(x => x.categoryId === data.categoryId);
        const girth = this.calculateGirth(Number(data.length),Number(data.width),Number(data.height));
        if(category.categoryName.includes("Express")){
            if(girth > 500){
                this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_GIRTH_EXCEEDED_500, 10000);
                event.confirm.reject();
                return;
            }
        }else{
            if (this.receiverCountry !== 'DE') {
                if(girth > 300){
                    this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_GIRTH_EXCEEDED_300, 10000);
                    event.confirm.reject();
                    return;
                }
            } else {
                if (girth > 300) {
                    const express = this.parcelCategories.find(x => x.categoryKey === 'EXP');
                    data.categoryId = express.categoryId;
                    data.name = express.categoryName;
                }
            }
        }


        data.weight = Number(data.weight);
        data.height = Number(data.height);
        data.width = Number(data.width);
        data.length = Number(data.length);
        this.dataSource.update(event.data, data);
        event.confirm.resolve();
    }

    public validateTableRow(event: any, showToasts = true): boolean {
        const data = event as {
            id: string,
            categoryId: string,
            name: string,
            weight: string | number,
            height: string | number,
            width: string | number,
            length: string | number,
            isValid: boolean,
        };

        if (Number.isNaN(Number(data.weight))
            || Number.isNaN(Number(data.height))
            || Number.isNaN(Number(data.width))
            || Number.isNaN(Number(data.length))
        ) {
            if(showToasts){
                this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_STEPPER_NUMBER_ONLY, 10000);
            }
            data.isValid = false;
            this.dataSource.update(event, data);
            return false;
        }

        if(data.weight > 40){
            if(showToasts) {
                this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_WEIGHT_EXCEEDED, 10000);
            }
            data.isValid = false;
            this.dataSource.update(event, data);
            return false;
        }

        const category = this.parcelCategories.find(x => x.categoryId === data.categoryId);
        const girth = this.calculateGirth(Number(data.length),Number(data.width),Number(data.height));
        if(category.categoryName.includes("Express")){
            if(girth > 500){

                if(showToasts) {
                    this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_GIRTH_EXCEEDED_500, 10000);
                }
                data.isValid = false;
                this.dataSource.update(event, data);
                return false;
            }
        }else{
            if (this.receiverCountry !== 'DE') {
                if(girth > 300){

                    if(showToasts) {
                        this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_GIRTH_EXCEEDED_300, 10000);
                    }
                    data.isValid = false;
                    this.dataSource.update(event, data);
                    return false;
                }
            } else {
                if (girth > 300) {
                    const express = this.parcelCategories.find(x => x.categoryKey === 'EXP');
                    data.categoryId = express.categoryId;
                    data.name = express.categoryName;
                }
                if(girth > 500){

                    if(showToasts) {
                        this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.SHIPMENT_GIRTH_EXCEEDED_500, 10000);
                    }
                    data.isValid = false;
                    this.dataSource.update(event, data);
                    return false;
                }
            }
        }

        data.weight = Number(data.weight);
        data.height = Number(data.height);
        data.width = Number(data.width);
        data.length = Number(data.length);
        data.isValid = true;
        this.dataSource.update(event, data);

        return true;
    }

    private calculateGirth(length: number, width: number, height: number): number{

        if(width >= length && width >= height){
            return width + 2 * height + 2 * length;
        }

        if(height >= length && height >= width){
            return height + 2 * width + 2 * length;
        }

        return length + 2 * width + 2 * height;
    }

    public onTableDelete(event: any): void {
        this.removeFromPricelist(event.data);
        event.confirm.resolve();
    }

    public onSelectionChange(event: Contact): void {
        this.selectedContact = event;
        const contactDisplay = `${event.nameLine1} ${event.nameLine2 !== null && event.nameLine2 !== undefined ? event.nameLine2 : ''}`;
        this.filteredOptions = this.getFilteredOptions(contactDisplay);
        this.elementAutocomplete.nativeElement.value = contactDisplay;


        // tslint:disable-next-line:no-shadowed-variable
        let map = new Map(Object.entries(event));
        for(let key of map.keys()){
            let property = key.charAt(0).toUpperCase();
            property += key.slice(1);
            property = `receiver${property}`;
            this.set(property, map.get(key), true);
        }
    }

    public onSelectionChangeAddresses(event: SenderAddress): void {
        if(event != undefined){
            this.selectedAddress = event;
            const contactDisplay = `${ event.firstName !== undefined && event.firstName !== null ? event.firstName : '' } ${ event.lastName !== undefined && event.lastName !== null ? event.lastName : '' } ${event.street} ${event.housenumber}, ${event.zip} ${event.city}`;
            this.filteredOptionsAddresses = this.getFilteredOptionsAddresses(contactDisplay);
            this.elementAutocompleteAddresses.nativeElement.value = contactDisplay;
        }
    }

    public onSelectionChangeCountry(event: Country): void {
        this.selectedCountry = event;
        const countryDisplay = `${event.name} `;
        this.filteredCountryOptions = this.getFilteredCountryOptions(countryDisplay);
        this.elementAutocompleteCountry.nativeElement.value = countryDisplay;
        this.set('receiverCountry', event.key);
    }

    public clearAddressSelection(): void{
        // this.selectedAddress = undefined;
        const contactDisplay = ``;
        this.filteredOptionsAddresses = this.getFilteredOptionsAddresses(contactDisplay);
        this.elementAutocompleteAddresses.nativeElement.value = contactDisplay;
    }

    public onChange(): void {
        this.filteredOptions = this.getFilteredOptions(this.elementAutocomplete.nativeElement.value);
    }
    public onChangeAddresses(): void {
        this.filteredOptionsAddresses = this.getFilteredOptionsAddresses(this.elementAutocompleteAddresses.nativeElement.value);
    }
    public onChangeCountry(): void {
        this.filteredCountryOptions = this.getFilteredCountryOptions(this.elementAutocompleteCountry.nativeElement.value);
    }

    private getFilteredOptions(value: string): Observable<Contact[]> {
        return of(value).pipe(
            map(filterString => this.filter(filterString))
        );
    }
    private getFilteredOptionsAddresses(value: string): Observable<Address[]> {
        return of(value).pipe(
            map(filterString => this.filterAddresses(filterString))
        );
    }
    private getFilteredCountryOptions(value: string): Observable<Country[]> {
        return of(value).pipe(
            map(filterString => this.filterCountry(filterString))
        );
    }

    private filter(value: string): Contact[] {
        const filterString = value.toLowerCase();

        return this.contacts.filter(x => {
            if ((x.nameLine1 != null && x.nameLine1.toLocaleLowerCase().includes(filterString))
                || (x.nameLine2 != null && x.nameLine2.toLocaleLowerCase().includes(filterString))
            ) {
                return true;
            }
        });
    }

    private filterAddresses(value: string): Address[] {
        const filterString = value.toLowerCase();

        return this.senderAddresses.filter(x => {
            if ((x.firstName != null && x.firstName.toLocaleLowerCase().includes(filterString))
                || (x.lastName != null && x.lastName.toLocaleLowerCase().includes(filterString))
                || (x.street != null && x.street.toLocaleLowerCase().includes(filterString))
                || (x.housenumber != null && x.housenumber.toLocaleLowerCase().includes(filterString))
                || (x.zip != null && x.zip.toLocaleLowerCase().includes(filterString))
                || (x.city != null && x.city.toLocaleLowerCase().includes(filterString))
            ) {
                return true;
            }
        });
    }

    private filterCountry(value: string): Country[] {
        const filterString = value.toLowerCase();

        return this.countries.filter(x => {
            if ((x.name != null && x.name.toLocaleLowerCase().includes(filterString))
                || (x.key != null && x.key.toLocaleLowerCase().includes(filterString))
            ) {
                return true;
            }
        });
    }

    public onKeyup(event: any, property: string, value: string): void {
        this.set(property, value);
    }

    private set(property: string, value: string, inputInRef?: boolean): void {
        switch(property) {
        case 'receiverNameLine1':
            this.receiverNameLine1 = value;
            break;
        case 'receiverNameLine2':
            this.receiverNameLine2 = value;
            break;
        case 'receiverAddressLine':
            this.receiverAddressLine = value;
            break;
        case 'receiverAddressLine2':
            this.receiverAddressLine2 = value;
            break;
        case 'receiverAdditionalContactInformation1':
            this.additionalContactInformation1 = value;
            break;
        case 'receiverAdditionalContactInformation2':
            this.additionalContactInformation2 = value;
            break;
        case 'receiverPostal':
            this.receiverPostal = value;
            break;
        case 'receiverCity':
            this.receiverCity = value;
            break;
        case 'receiverEmail':
            this.receiverEmail = value;
            break;
        case 'receiverPhone':
            this.receiverPhone = value;
            break;
        case 'receiverCountry':
            this.countries.forEach(country => {
                if (country.key.toLocaleLowerCase() === value.toLocaleLowerCase() || country.name.toLocaleLowerCase() === value.toLocaleLowerCase()) {
                    this.receiverCountry = country.key;
                }
            });
            break;
        default:
            break;
        }
    }

    public onCustomAction(event: any): void {
        let data = {
            categoryId: event.data.categoryId,
            name: event.data.name,
            weight: event.data.weight,
            height: event.data.height,
            width: event.data.width,
            length: event.data.length,
            isValid: event.data.isValid,
        };
        switch (event.action) {
        case 'copy':
            this.tableData.push(data);
            this.dataSource.load(this.tableData);
            break;
        default:
            break;
        }
    }

    public getSum(): number{
        let sum = 0;
        this.finalCategories.forEach(cat => {
            sum += cat.price;
        });
        return sum;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

}
