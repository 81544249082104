<div class="">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-sm-12">
                <h5 *ngIf="!update">Kontakt hinzuf&uuml;gen</h5>
                <h5 *ngIf="update">Kontakt bearbeiten</h5>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-12 mandatoryCol">
                <span class="mandatory">*</span> Pflichtfeld
            </div>
        </div>
        <div class="row">
            <label class="col-sm-2 label" for="name">Name <span class="mandatory">*</span></label>
            <div class="col-sm-4">
                <input #name id="name" name="nameLine1" placeholder="Name" maxlength="40" nbInput fullWidth [(ngModel)]="contact.nameLine1">
            </div>
            <label class="col-sm-2 label" for="additionalName">Firma</label>
            <div class="col-sm-4">
                <input #additionalName name="nameLine2" id="additionalName" placeholder="Zusatz" maxlength="40" nbInput fullWidth [(ngModel)]="contact.nameLine2" >
            </div>
        </div>
        <div class="row">
            <label class="col-sm-2 label" for="address">Stra&szlig;e/ Hr. <span class="mandatory">*</span></label>
            <div class="col-sm-4">
                <input #address id="address" name="addressLine" placeholder="Adresse" maxlength="40" nbInput fullWidth [(ngModel)]="contact.addressLine" >
            </div>
            <label class="col-sm-2 label" for="address2">Adresse 2</label>
            <div class="col-sm-4">
                <input #address2 id="address2" name="addressLine" placeholder="Adresse 2" maxlength="40" nbInput fullWidth [(ngModel)]="contact.addressLine2" >
            </div>
        </div>
        <div class="row">
            <label class="col-sm-2 label" for="zip">PLZ <span class="mandatory">*</span></label>
            <div class="col-sm-4">
                <input #zip id="zip" placeholder="PLZ" name="postal" maxlength="10" nbInput fullWidth [(ngModel)]="contact.postal">
            </div>
            <label class="col-sm-2 label" for="city">Stadt <span class="mandatory">*</span></label>
            <div class="col-sm-4">
                <input #city id="city" placeholder="Stadt" maxlength="40" nbInput fullWidth [(ngModel)]="contact.city">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2 label col-form-label">
                <label class="label col-form-label" for="autocompleteCountry">Land</label>
            </div>
            <div class="col-sm-4" style="align-self: center;">
                <input
                    #autocompleteCountry
                    id="autocompleteCountry"
                    nbInput
                    [nbAutocomplete]="autoCountry"
                    placeholder="Land ausw&auml;hlen..."
                    (input)="onChangeCountry()"
                    type="text"
                    autocomplete="off"
                    style="max-width: 100%; width: 100%;"
                >
                <nb-autocomplete #autoCountry (selectedChange)="onSelectionChangeCountry($event)">
                    <nb-option *ngFor="let option of filteredCountryOptions | async" [value]="option">
                        {{ option.name }}
                    </nb-option>
                </nb-autocomplete>
            </div>
        </div>
        <div class="row">
            <label class="col-sm-2 label" for="mail">E-Mail</label>
            <div class="col-sm-4">
                <input #mail id="mail" placeholder="E-Mail" name="email" maxlength="80" nbInput fullWidth [(ngModel)]="contact.email" >
            </div>
            <label class="col-sm-2 label" for="phone">Telefon</label>
            <div class="col-sm-4">
                <input #phone id="phone" placeholder="Telefon" name="phone" maxlength="40" nbInput fullWidth [(ngModel)]="contact.phone">
            </div>
        </div>
        <div class="row">
            <label class="col-sm-2 label" for="additionalContactInformation1">Kontakt</label>
            <div class="col-sm-4">
                <input #zip id="additionalContactInformation1" placeholder="Kontakt" name="Kontakt" maxlength="40" nbInput fullWidth [(ngModel)]="contact.additionalContactInformation1">
            </div>
            <label class="col-sm-2 label" for="additionalContactInformation2">Zusatz</label>
            <div class="col-sm-4">
                <input #city id="additionalContactInformation2" placeholder="Zusatz" maxlength="40" nbInput fullWidth [(ngModel)]="contact.additionalContactInformation2">
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row btnRow">
            <div class="col-sm-12">
                <button nbButton fullWidth [disabled]="!dataIsValid(false)" (click)="submit()">Speichern</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>