import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DefaultFilter } from 'ng2-smart-table';
import {FormControl} from '@angular/forms';


@Component({
    template: `
        <input class="form-control" placeholder="{{column.title}}"
               style="padding-right: 1.25em;"
               [formControl]="inputControl"
               [ngClass]="{'hasValue': inputControl.value != undefined}"
               [nbDatepicker]="rangepicker">
        <nb-rangepicker #rangepicker id="tableRangepicker" format="DD.MM"></nb-rangepicker>
        <i class="fas fa-times clearFilterValue" (click)="clear()" *ngIf="inputControl.value != undefined"></i>
    `,
})
export class DateFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    inputControl = new FormControl();

    constructor() {
        super();
    }

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(this.delay)
            )
            .subscribe((value: string) => {
                if(this.inputControl.status === 'VALID' && this.inputControl.value != undefined && this.inputControl.value.start != undefined && this.inputControl.value.end != undefined)
                {
                    this.query = value !== null ? JSON.stringify(this.inputControl.value) : "";
                    this.setFilter();
                }
            });
        let list = document.querySelectorAll('nb-layout.with-scroll > .scrollable-container > .layout > .layout-container > .content');
        if(list.length > 0 && list[0].getAttribute('listener') != 'true'){
            list[0].addEventListener('scroll', () => {
                let elements = document.querySelectorAll('.cdk-overlay-container > .cdk-overlay-connected-position-bounding-box > .cdk-overlay-pane');
                let containerList = document.getElementsByClassName('cdk-overlay-container');
                if(containerList != undefined && containerList.length > 0){
                    (containerList[0] as HTMLElement).style.zIndex = '1039';
                }
                let picker = document.getElementById('tableRangepicker');
                if(elements != undefined && elements.length > 0 && elements[0] != undefined && elements[0].getElementsByClassName('calendar-navigation').length > 0){
                    let el = elements[0];
                    if(el != undefined){
                        (el as HTMLElement).style.top = picker.getBoundingClientRect().top + 'px';
                    }
                }else{
                    if(containerList != undefined && containerList.length > 0){
                        (containerList[0] as HTMLElement).style.zIndex = '1040';
                    }
                }
            });
            list[0].setAttribute('listener', 'true');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            // this.inputControl.setValue(this.inputControl.value);
        }
    }

    public clear(): void{
        this.inputControl.setValue(undefined);
        this.query = '';
        this.setFilter();
    }

}
