import { Component, Input, OnInit } from '@angular/core';
import { AdminPermissionsGuardService } from 'app/guards/permissions/admin/admin-permissions-guard.service';
import { GetUserResponse } from 'app/responses/user/GetUserResponse';
import { NbDialogRef } from '@nebular/theme';
import { ToastService } from 'app/services/toast/toast.service';
import { User } from 'app/models/User.model';
import { UserService } from 'app/services/user/user.service';

@Component({
    selector: 'ngx-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnInit {

    @Input()
    public user: User;
    @Input()
    public update: boolean;
    @Input()
    public edit: boolean;

    public userIsAdmin: boolean;
    public userIsSelf: boolean;

    public newPassword: string = '';
    public newPasswordConfirm: string = '';

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private adminGuard: AdminPermissionsGuardService,
        private userService: UserService
    ) { }

    ngOnInit(): void {
        // Set null Value to String 'null' for pre-select
        if (this.user.salutation == null) {
            this.user.salutation = 'null';
        }

        this.adminGuard.canActivate().subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: res => {
                this.userIsAdmin = res;
            },
        });

        this.userService.getUser().subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: (res: GetUserResponse) => {
                if (res.status == 200) {
                    if (this.user.userId == res.body.userId) {
                        this.userIsSelf = true;
                    } else {
                        this.userIsSelf = false;
                    }
                }
            },
        });
    }

    public submit(): void {
        // console.log(this.user);
        if (!this.dataIsValid()) {
            return;
        }
        if (this.newPassword != '') {
            this.user.password = this.newPassword;
        }
        this.dialogRef.close({
            save: this.update === undefined || this.update === false ? true : false,
            update: this.update,
            user: this.user,
        });
    }

    private dataIsValid(): boolean {
        if (
            this.user.userName === '' ||
            this.user.email === ''
        ) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD, 4000);
            return false;
        } else if ((this.newPassword != '' || this.newPasswordConfirm != '') && this.newPassword !== this.newPasswordConfirm) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.PASSWORDS_DONT_MATCH, 4000);
            return false;
        }

        return true;
    }

    public set(property: string, value: any) {
        this.user[property] = value;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

}
