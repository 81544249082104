<div class="tracking-content content">
    <div>
        <div class="col-12 d-flex justify-content-center">
            <button nbButton (click)="close()" class="close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row mb-5">
            <div class="col-12 headline headline-bt">
                <h3>Paketstatus</h3>
            </div>
        </div>
        <div class="trackingImages row mb-5 pl-md-3 pr-md-3" *ngIf="shipment != undefined">
            <div class="col-4 text-center">
                <img src="/assets/images/tracking/paketdaten-uebermittel-aktiv.png" alt="">
                <h5>Paketdaten &uuml;bermittelt</h5>
            </div>
            <div class="col-4 text-center">
                <img src="/assets/images/tracking/paket-unterwegs-aktiv.png" alt="" *ngIf="trackingStatus > 0">
                <img src="/assets/images/tracking/paket-unterwegs-inaktiv.png" alt="" *ngIf="trackingStatus < 1">
                <h5 >Paket unterwegs</h5>
            </div>
            <div class="col-4 text-center">
                <img src="/assets/images/tracking/zugestellt-aktiv.png" alt="" *ngIf="trackingStatus == 2">
                <img src="/assets/images/tracking/zugestellt-inaktiv.png" alt="" *ngIf="trackingStatus < 2">
                <img src="/assets/images/tracking/zugestellt-gls-paketshop-aktiv.png" alt=""  *ngIf="trackingStatus == 3">
                <img src="/assets/images/tracking/klaerung-notwendig.png" alt=""  *ngIf="trackingStatus == 4">
                <img src="/assets/images/tracking/nicht-zugestellt.png" alt=""  *ngIf="trackingStatus == 5">
                <h5 *ngIf="trackingStatus <= 1 ">Zugestellt oder im GLS Paketshop</h5>
                <h5 *ngIf="trackingStatus == 2">Zugestellt </h5>
                <h5 *ngIf="trackingStatus == 3">Zugestellt im GLS Paketshop</h5>
                <h5 *ngIf="trackingStatus == 4">Kl&auml;rung notwendig</h5>
                <h5 *ngIf="trackingStatus == 5">Nicht zugestellt</h5>
                <!--
                <img style="width: 75px; height: 75px;" src="/assets/images/tracking/zugestellt-gls-paketshop-inaktiv.png" alt="" *ngIf="trackingStatus < 2">
                -->
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12 headline">
                <h3>Sendungsinformationen</h3>
            </div>
        </div>
        <div class="row mb-4 pl-md-5 pr-md-5">
            <div class="col-12">
                <h4><b>Adressen</b></h4>
            </div>
            <div class="col-12 col-md-6">
                <!--
                <div class="row mb-2">
                    <div class="col-12">
                        <div class="signature mb-2">Unterschrift: Behrendt(2760905392)</div>
                    </div>
                </div>
                -->
                <div class="row mb-3" id="importedParcelContent" *ngIf="isParcelShop">
                    <div class="spinner" *ngIf="isParcelShopLoading !== undefined && isParcelShopLoading" [nbSpinner]="true"></div>

                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <p class="mb-0"><b>Empf&auml;nger:</b></p>
                        <p class="mb-0">{{shipment.receiverNameLine1}} {{shipment.receiverNameLine2}}</p>
                        <p class="mb-0">{{shipment.receiverPhone}}</p>
                        <p class="mb-0">{{shipment.receiverEmail}}</p>
                        <p class="mb-0">{{shipment.receiverAddressLine1}} {{shipment.receiverAddressLine2}}</p>
                        <p class="mb-0">{{shipment.receiverCountry}}, {{shipment.receiverZip}} {{shipment.receiverCity}}</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <p class="mb-0"><b>Versender:</b></p>
                        <p class="mb-0">{{shipment.senderFirstName}} {{shipment.senderLastName}}</p>
                        <p class="mb-0">{{shipment.senderAdditionalName}}</p>
                        <p class="mb-0">{{shipment.senderStreet}} {{shipment.senderHousenumber}}</p>
                        <p class="mb-0">{{shipment.senderCountry}}, {{shipment.senderZip}} {{shipment.senderCity}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row mb-3">
                    <div class="col-12">
                        <p class="mb-0"><b>Referenz-Nr.</b></p>
                        <p class="mb-0">Paketnummer: {{shipment.parcelNumber}}</p>
                        <p class="mb-0">Track ID: {{shipment.trackingId}}</p>
                        <!--
                        <p class="mb-0">Nationale Kundenreferenz: 910047903</p>
                        -->
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <p class="mb-0"><b>Paketdetails</b></p>
                        <p class="mb-0" *ngIf="!weighCustomer">Gewicht: {{shipment.weight}} kg</p>
                        <p class="mb-0" *ngIf="!shipment.express">Produkt: Basic</p>
                        <p class="mb-0" *ngIf="shipment.express">Produkt: Express</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="mb-0"><b>Datum</b></p>
                        <p class="mb-0">Versanddatum: {{getShipmentDate()}}</p>
                        <p class="mb-0" >Zustelldatum: {{getDeliveredDate()}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 headline bt-spacer">
                <h3>Pakethistorie</h3>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12" style="min-height: 50px; overflow-x: auto">
                <div class="spinner" *ngIf="trackingLoading !== undefined && trackingLoading" [nbSpinner]="true"></div>
                <div class="w-100 text-center" *ngIf="!trackingLoading && trackingData.length < 1">Keine Tracking-Daten gefunden</div>
                <table class="table table-striped" *ngIf="!trackingLoading && trackingData.length > 0">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Paketstatus</th>
                            <th>GLS Standort</th>
                            <th>Land</th>
                            <th>Event Nr.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of trackingData">
                            <td style="white-space: nowrap; word-break: keep-all">{{formatDateTime(data.glsEventCreationTime)}}</td>
                            <td>{{data.glsEventDescription}}</td>
                            <td>{{data.glsEventLocation}}</td>
                            <td>{{data.glsEventCountry}}</td>
                            <td>{{data.glsEventCode}}</td>
                        </tr>
                        <!--
                        <tr>
                            <td>08.06.2021</td>
                            <td>09:37:19</td>
                            <td>Das Paket ist im GLS PaketShop eingetroffen</td>
                            <td>Deutschland Kuernach</td>
                            <td>2.124</td>
                        </tr>
                        <tr>
                            <td>08.06.2021</td>
                            <td>09:28:24</td>
                            <td>Das Paket konnte nicht zugestellt werden, da der Empf&auml;nger nicht angetroffen wurde.</td>
                            <td>Deutschland Kuernach</td>
                            <td>4.40</td>
                        </tr>
                        <tr>
                            <td>08.06.2021</td>
                            <td>05:47:16</td>
                            <td>Das Paket wird voraussichtlich im Laufe des Tages zugestellt.</td>
                            <td>Deutschland Kuernach</td>
                            <td>11.0</td>
                        </tr>
                        -->
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <!--
            <div class="col-3">
                <button nbButton>Ablieferbeleg</button>
            </div>
            -->
            <div class="col-3">
                <button nbButton (click)="export()">Exportieren</button>
            </div>
            <div class="col-3">
                <button nbButton (click)="manuallyAbortTracking()" *ngIf="canManuallyAbort()">als zugestellt markieren</button>
            </div>
            <!--<div class="col-3 offset-3">
                <button nbButton (click)="support()">Support</button>
            </div>-->
        </div>
    </div>
</div>
