import * as Routes from '../Routes';
import { DataService } from '../data/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from 'app/responses/Response';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(
        private dataService: DataService,
        private http: HttpClient,
        private router: Router
    ) {}

    public login(userName: string, password: string): Observable<Response> {
        return this.http.post<Response>(Routes.LOGIN_ROUTE, {userName, password}, this.dataService.getHttpOptions());
    }

    public logout(): void {
        this.dataService.clearToken();
        this.router.navigate(['/auth/login']);
    }

    public checkTokenValidation(): Observable<Response> {
        return this.http.post<Response>(Routes.TOKEN_VALIDATION_ROUTE, {token: this.dataService.getClearToken()}, this.dataService.getHttpOptions());
    }

}
