<div class="infoModalContent">
    <div class="closeButton">
        <i (click)="close()" class="fas fa-times fa-2x"></i>
    </div>
    <div class="infoModalWrapper">
        <div class="modalHeader">
            <h2 class="modalHeadline">Supportanfrage</h2>
        </div>
        <div class="modalBody">
            <p>Sie haben Probleme mit einem Paket? Hier haben Sie die M&ouml;glichkeit Ihre Anfrage sofort an den GLS Support zu senden.</p>
            <p><button nbButton (click)="close(true)">Absenden</button></p>
        </div>
    </div>
</div>
