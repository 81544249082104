import * as Routes from "../Routes";
import {EventEmitter, Injectable} from '@angular/core';
import {CustomReturnLabel} from "../../models/CustomReturnLabel.model";
import { DataService } from '../data/data.service';
import {GenericResponse} from "../../responses/GenericResponse";
import { GetAllShipmentResponse } from 'app/responses/shipments/GetAllShipmentsResponse';
import { GetShipmentResponse } from 'app/responses/shipments/GetShipmentResponse';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Shipment } from 'app/models/Shipment.model';


@Injectable({
    providedIn: 'root',
})
export class ShipmentService {

    public tableReloadEvent: EventEmitter<any> = new EventEmitter<any>();
    public finalReportCalledEvent: EventEmitter<any> = new EventEmitter<any>();

    public static SHIPMENT_TYPES: { value: string, title: string }[] = [
        {value: 'MANUAL', title: 'Manuell'},
        {value: 'Amazon', title: 'Amazon'},
        {value: 'EBAY', title: 'Ebay'},
        {value: 'CSV/TXT', title: 'CSV/TXT'},
        {value: 'WOOCOMMERCE', title: 'Woocommerce'},
        {value: 'SHOPWARE_V5', title: 'Shopware 5'},
        {value: 'SHOPWARE', title: 'Shopware 6'},
        {value: 'SHOPIFY', title: 'Shopify'},
    ];

    public ALLOWED_RETURN_COUNTRIES = [
        "DE",
        "BE",
        "DK",
        "IT",
        "LU",
        "NL",
        "AT",
        "PL",
        "SK",
        "SI",
        "ES",
        "CZ",
    ];

    public SHIPMENTSTATES = [
        "NEW_UNCATEGORIZED",
        "CATEGORIZED",
        "REGISTERED",
        "SHIPPED",
        "CANCELED",
    ];


    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }


    public getAllShipments(state: string = this.SHIPMENTSTATES[0], start: number = null, end: number = null): Observable<GetAllShipmentResponse> {
        if(start == null && end == null) {
            return this.http.get<GetAllShipmentResponse>(`${Routes.SHIPMENT_ROUTE}/all/all?state=${state}`, this.dataService.getHttpOptions());
        }else{
            if(start == null){
                // tslint:disable-next-line:ter-max-len
                return this.http.get<GetAllShipmentResponse>(`${Routes.SHIPMENT_ROUTE}/all/all?state=${state}&rangeEnd=${end}`, this.dataService.getHttpOptions());
            }
            if(end == null){
                // tslint:disable-next-line:ter-max-len
                return this.http.get<GetAllShipmentResponse>(`${Routes.SHIPMENT_ROUTE}/all/all?state=${state}&rangeStart=${start}`, this.dataService.getHttpOptions());
            }
        }
        // tslint:disable-next-line:ter-max-len
        return this.http.get<GetAllShipmentResponse>(`${Routes.SHIPMENT_ROUTE}/all/all?state=${state}&rangeStart=${start}&rangeEnd=${end}`, this.dataService.getHttpOptions());
    }

    public getShipmentByShipmentId(shipmentId: string): Observable<GetShipmentResponse> {
        return this.http.get<GetShipmentResponse>(`${Routes.SHIPMENT_ROUTE}/${shipmentId}/`, this.dataService.getHttpOptions());
    }

    public deleteShipment(shipmentId: string): Observable<any> {
        return this.http.delete(`${Routes.SHIPMENT_ROUTE}/${shipmentId}/`, this.dataService.getHttpOptions());
    }

    public updateShipment(shipmentId: string, shipment: Shipment): Observable<any> {
        return this.http.put(`${Routes.SHIPMENT_ROUTE}/${shipmentId}/`, shipment, this.dataService.getHttpOptions());
    }

    public createShipment(shipment: Shipment): Observable<any> {
        return this.http.post(`${Routes.SHIPMENT_ROUTE}/`, shipment, this.dataService.getHttpOptions());
    }

    public abortTrackinManually(shipmentId: String): Observable<any> {
        return this.http.post(`${Routes.SHIPMENT_ROUTE}/gls-delivered/${shipmentId}/`, '', this.dataService.getHttpOptions());
    }

    public getIsIsland(countryKey: string, zip: string): Observable<any> {
        return this.http.get(`${Routes.SHIPMENT_ROUTE}/isIsland/${countryKey}/${zip}/`, this.dataService.getHttpOptions());
    }

    public getIsNB(parcelCategoryKey: string, length: string | number, width: string | number, height: string | number): Observable<any> {
        // tslint:disable-next-line:ter-max-len
        return this.http.get(`${Routes.SHIPMENT_ROUTE}/girth/${parcelCategoryKey}?length=${length}&width=${width}&height=${height}`, this.dataService.getHttpOptions());
    }

    public getNextBatchId(): Observable<any> {
        return this.http.get(`${Routes.SHIPMENT_ROUTE}/nextInternalBatchId/`, this.dataService.getHttpOptions());
    }

    public categorizeShipment(shipment: Shipment): Observable<any> {
        if(shipment.shipmentId != undefined && shipment.shipmentId.length > 0){
            return this.http.post(`${Routes.SHIPMENT_ROUTE}/categorize/${shipment.shipmentId}/`, shipment, this.dataService.getHttpOptions());
        }else{
            return this.http.post(`${Routes.SHIPMENT_ROUTE}/categorize/`, shipment, this.dataService.getHttpOptions());
        }
    }

    public getLabel(shipmentId: number | string): Observable<any> {
        return this.http.get(`${Routes.PARCEL_LABEL_ROUTE}/${shipmentId}/`, this.dataService.getHttpFileDownloadOptions());
    }

    public getLabelBatch(ids: string[]): Observable<any> {
        return this.http.post(`${Routes.PARCEL_LABEL_ROUTE}/batch/`, {shipmentIds: ids}, this.dataService.getHttpFileDownloadOptions());
    }

    public getLabelBatchA4(ids: string[]): Observable<any> {
        return this.http.post(`${Routes.PARCEL_LABEL_ROUTE}/batch-A4/`, {shipmentIds: ids}, this.dataService.getHttpFileDownloadOptions());
    }
    public getReturnLabelBatch(ids: string[]): Observable<any> {
        return this.http.post(`${Routes.PARCEL_LABEL_ROUTE}/return/batch/`, {shipmentIds: ids}, this.dataService.getHttpFileDownloadOptions());
    }
    public createGLSLabel(shipmentId: string): Observable<any> {
        return this.http.post(`${Routes.GLS_ROUTE}/${shipmentId}/`,{}, this.dataService.getHttpOptions());
    }
    public createGLSLabelBatch(shipmentIDs: string[]): Observable<any> {
        return this.http.post(`${Routes.GLS_ROUTE}/batch/`,{shipmentIds: shipmentIDs} , this.dataService.getHttpOptions());
    }
    public createGLSReturnLabelBatch(shipmentIDs: string[]): Observable<any> {
        return this.http.post(`${Routes.GLS_ROUTE}/returnLabel/batch/`,{shipmentIds: shipmentIDs} , this.dataService.getHttpOptions());
    }
    public finalize(shipmentIDs: string[], newPickupId: boolean = true): Observable<any> {
        return this.http.post(`${Routes.SHIPMENT_ROUTE}/finalize/${newPickupId}/`,{shipmentIds: shipmentIDs} , this.dataService.getHttpFileDownloadOptions());
    }

    public getShipmentCountLastMonth(): Observable<any> {
        return this.http.get(`${Routes.SHIPMENT_ROUTE}/count/lastMonth/`, this.dataService.getHttpOptions());
    }

    public getShipmentCostLastMonth(): Observable<any> {
        return this.http.get(`${Routes.SHIPMENT_ROUTE}/cost/lastMonth/`, this.dataService.getHttpOptions());
    }
    public getCountryGroupStatisticsByDays(days: number): Observable<any> {
        return this.http.get(`${Routes.SHIPMENT_ROUTE}/countryGroupStats/days/${days}/`, this.dataService.getHttpOptions());
    }
    public getCategoryStatisticsByDays(days: number): Observable<any> {
        return this.http.get(`${Routes.SHIPMENT_ROUTE}/categoryStats/days/${days}/`, this.dataService.getHttpOptions());
    }
    public glsCancelBatch(shipmentIDs: string[]): Observable<any> {
        return this.http.post(`${Routes.GLS_ROUTE}/cancel/batch/`,{shipmentIds: shipmentIDs} , this.dataService.getHttpOptions());
    }
    public getAlreadyShippedButNotShippedState(): Observable<GenericResponse<Shipment[]>>{
        return this.http.get<GenericResponse<Shipment[]>>(
            `${Routes.SHIPMENT_ROUTE}/report-remaining/`,
            this.dataService.getHttpOptions()
        );
    }

    public sendSupport(shipmentId: string): Observable<GenericResponse<string>>{
        return this.http.post<GenericResponse<string>>(
            `${Routes.SHIPMENT_ROUTE}/support-request/${shipmentId}/`,
            {},
            this.dataService.getHttpOptions()
        );
    }

    public createCustomReturn(returnLabel: CustomReturnLabel): Observable<GenericResponse<string>>{
        return this.http.post<GenericResponse<string>>(
            `${Routes.GLS_ROUTE}/customReturnLabel/`,
            returnLabel,
            this.dataService.getHttpOptions()
        );
    }

}
