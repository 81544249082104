<div class="spinner" *ngIf="loading !== undefined && loading" [nbSpinner]="true">
</div>
<ng2-smart-table
    [style.display]="loading != undefined ? (loading ? 'none': '') : 'none'"
    #table
    [settings]="settings"
    [source]="data"
    (custom)="onCustomAction($event)"
    (deleteConfirm)="onDeleteConfirm($event)"
    (createConfirm)="onAdd($event)"
    (editConfirm)="onEdit($event)"
    (userRowSelect)="onUserRowSelect($event)"
    (rowSelect)="onAnyRowSelect($event)"
></ng2-smart-table>
