import * as Routes from '../Routes';
import { DataService } from '../data/data.service';
import { GetAllParcelCategoryResponse } from 'app/responses/parcelCategories/GetAllParcelCategoriesResponse';
import { GetParcelCategoryResponse } from 'app/responses/parcelCategories/GetParcelCategoryResponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParcelCategory } from 'app/models/ParcelCategory';


@Injectable({
    providedIn: 'root',
})
export class ParcelCategoryService {

    public categories: ParcelCategory[];

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) {
        this.refreshCategories();
        setTimeout(() => {
            this.refreshCategories();
        }, 30000);
    }

    public getAllParcelCategories(): Observable<GetAllParcelCategoryResponse> {
        return this.http.get<GetAllParcelCategoryResponse>(`${Routes.PARCEL_CATEGORY_ROUTE}/all/all`, this.dataService.getHttpOptions());
    }
    public getAllActiveParcelCategories(): Observable<GetAllParcelCategoryResponse> {
        return this.http.get<GetAllParcelCategoryResponse>(`${Routes.PARCEL_CATEGORY_ROUTE}/all/active`, this.dataService.getHttpOptions());
    }

    public updateParcelCategory(category: ParcelCategory): Observable<GetParcelCategoryResponse> {
        return this.http.put<GetParcelCategoryResponse>(`${Routes.PARCEL_CATEGORY_ROUTE}/${category.categoryId}/`, category, this.dataService.getHttpOptions());
    }

    public getActiveCountries(): Observable<any> {
        return this.http.get<any>(`${Routes.PARCEL_CATEGORY_ROUTE}/countries/all`, this.dataService.getHttpOptions());
    }

    public async refreshCategories(): Promise<void>{
        await this.getAllActiveParcelCategories().toPromise()
            .catch(err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            })
            .then((res: GetAllParcelCategoryResponse) => {
                this.categories = res.body;
            });
    }
}
