import {Component, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {DefaultFilter} from "ng2-smart-table";
import {FormControl} from "@angular/forms";


@Component({
    template: `
        <select class="form-control"
                [ngClass]="{'hasValue': inputControl.value != undefined && inputControl.value.length > 0}"
                [formControl]="inputControl">
            <option value=""></option>
            <option *ngFor="let el of column.filter.config.list" [value]="el.value">{{el.title}}</option>
        </select>
    `,
})
export class SimpleTableListFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    inputControl = new FormControl();

    constructor() {
        super();
    }

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(this.delay)
            )
            .subscribe((value: string) => {
                if(this.inputControl.status === 'VALID' && this.inputControl.value != undefined)
                {
                    this.query = value !== null ? value : "";
                    this.setFilter();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            // this.inputControl.setValue(this.inputControl.value);
        }
    }
}