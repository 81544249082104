import {Component, Input, OnInit} from '@angular/core';
import {FileTemplate} from "../../models/FileTemplate";
import {FileTemplateService} from "../../services/fileTemplate/fileTemplate.service";
import {NbDialogRef} from "@nebular/theme";
import {ToastService} from "../../services/toast/toast.service";

@Component({
    selector: 'ngx-export-dialog',
    templateUrl: './export-dialog.component.html',
    styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {

    @Input()
    public shipmentIds: string[];

    public templates: FileTemplate[];
    public selectedTemplate: FileTemplate;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private templateService: FileTemplateService,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.templateService.getAll().subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.API_TEMPLATE_LOAD_FAILED, 10000, err);
            },
            next: value => {
                this.templates = [];
                value.body.forEach((v: FileTemplate) => {
                    if(v.templateType == 'export'){
                        this.templates.push(v);
                    }
                });
                if(this.templates.length < 1){
                    this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.API_TEMPLATE_NONE_CREATED, 10000);
                }
            },
        });
    }

    public templateExport(): void {
        if (this.selectedTemplate == undefined) {
            return;
        }
        if(this.selectedTemplate.oneFileExport){
            this.initiateExport(this.shipmentIds);
        }else{
            for(let id of this.shipmentIds) {
                this.initiateExport([id]);
            }
        }
    }

    private initiateExport(ids: any[]): void{
        this.templateService.download(this.selectedTemplate.templateId, ids).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.FILE_EXPORT_FAIL, 10000, err);
            },
            next: value => {
                this.downLoadFile(value.body, value.headers.get('correct-filename'));
            },
        });
    }

    private downLoadFile(data: any, name: string): void{
        let blob = new Blob([data], { type: 'application/octet-stream'});
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.setAttribute('type','hidden');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.closeDialogue();
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

}
