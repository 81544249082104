import * as Routes from '../Routes';
import { DataService } from '../data/data.service';
import {GetAllUserResponse} from '../../responses/user/GetAllUserResponse';
import { GetUserResponse } from 'app/responses/user/GetUserResponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }

    public getUser(id?: number | string): Observable<GetUserResponse> {
        if (id === undefined) {
            return this.http.get<GetUserResponse>(`${Routes.USER_ROUTE}/`, this.dataService.getHttpOptions());
        }
        return this.http.get<GetUserResponse>(`${Routes.USER_ROUTE}/${id}/`, this.dataService.getHttpOptions());
    }

    public createUser(user: any): Observable<any> {
        return this.http.post(`${Routes.USER_ROUTE}/`, user, this.dataService.getHttpOptions());
    }

    public updateUser(id: number | string, user: any): Observable<any> {
        return this.http.put(`${Routes.USER_ROUTE}/${id}/`, user, this.dataService.getHttpOptions());
    }

    public deleteUser(id: number | string): Observable<any> {
        return this.http.delete(`${Routes.USER_ROUTE}/${id}/`, this.dataService.getHttpOptions());
    }

    public getAll(showOnlyActive: boolean = false){
        return this.http.get<GetAllUserResponse>(`${Routes.USER_ROUTE}/all/${showOnlyActive}`, this.dataService.getHttpOptions());
    }

    public generatePassword(): string {
        let passwordLength = 12;

        let lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        let upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let symbols = ['!', '?', '@'];

        let getRandom = function (array) {
            return array[Math.floor(Math.random() * array.length)];
        };
        let finalCharacters = "";

        finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
        finalCharacters = finalCharacters.concat(getRandom(numbers));
        finalCharacters = finalCharacters.concat(getRandom(symbols));

        for (let i = 1; i < passwordLength - 3; i++) {
            finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
        }

        // shuffle!
        return finalCharacters.split('').sort(function () {
            return 0.5 - Math.random();
        }).join('');
    }

}
